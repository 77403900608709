//http://localhost:3000/C.
import { useState, useEffect } from 'react';
import CardTable from "components/Cards/CardTable.js";
//import { createContext } from 'react';
//import createBrowserHistory from 'history/createBrowserHistory';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
//  import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import { DialogContent } from '@mui/material';
import Grid from '@mui/material/Grid';
//import GridList from "@material-ui/core/GridList";
//import Filter1Icon from '@mui/icons-material/Filter1';
//import RepeatIcon from '@mui/icons-material/Repeat';
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
//import Modal from '@mui/material/Modal';
//import ListItemText from '@mui/material/ListItemText';
//import ListItemButton from '@mui/material/ListItemButton';
//import List from '@mui/material/List';
import Link from '@mui/material/Link';
//import Stepper from '@mui/material/Stepper';
//import Step from '@mui/material/Step';
//import StepLabel from '@mui/material/StepLabel';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import PropTypes from "prop-types";
 
//import Divider from '@mui/material/Divider';
//import AppBar from '@mui/material/AppBar';
//import Toolbar from '@mui/material/Toolbar';
//import IconButton from '@mui/material/IconButton';
//import CloseIcon from '@mui/icons-material/Close';
//import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
//import {
//  Card,
//  CardActionArea,
//  CardContent,
//  CardMedia
//} from "@material-ui/core";


//import FormControl from '@mui/material/FormControl';
import { useDisclosure } from '@mantine/hooks';
 import {
  Anchor,
  Button,
  CopyButton,
//  TextInput,
  Modal,
} from '@mantine/core';
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import moment from 'moment';
import "./comp.css";
import PublicGoogleSheetsParser from 'public-google-sheets-parser';
import axios from 'axios';
//import IndexNavbar from "components/Navbars/IndexNavbar.js";
//import Navbar from "components/Navbars/AuthNavbar.js";
//import { small } from 'react-dom-factories';
import { DoneAll, Filter1, Filter2Rounded, Filter3, Filter4, Filter5, Filter6 } from '@mui/icons-material';
import { Filter6Outlined } from '@material-ui/icons';
import { b } from 'react-dom-factories';
//import Success from './Success';



/* const User = [
    {
    id: '9s41rp',
    firstName: 'Kelvin',
    lastName: 'Langosh',
    dob:'1969/08/07',
    pan:'AAAAA5869B',
    mobile:'9876543210',
    email: 'Jerod14@hotmail.com',
    director:'Yes',
    authorized:'Yes',
    authlimit:125896,
    shareper:30,
    addline1:'Dire Address Line 1',
    address:'Director Address',
    city:'Vijaywada',
    state: 'Telangana',
    pin:356892,
  },
{
    id: '08m6rx',
    firstName: 'Molly',
    lastName: 'Purdy',
    dob:'1971/05/08',
    pan:'BBBBB5897C',
    mobile:'3456987856',
    email: 'Hugh.Dach79@hotmail.com',
    director:'No',
    authorized:'Yes',
    authlimit:60000,
    shareper:50,
    addline1:'Auth Address Line1',
    address:'Auth Address',
    city:'Madgaon',
    state: 'Goa',
    pin:458969,
  },
  {
    id: '5ymtrc',
    firstName: 'Henry',
    lastName: 'Lynch',
    dob:'',
    pan:'',
    mobile:'',
    email: 'Camden.Macejkovic@yahoo.com',
    director:'Yes',
    authorized:'Yes',
    authlimit:0,
    shareper:0,
    addline1:'',
    address:'',
    city:'',
    state: 'Gujarat',
    pin:0,
  },
  {
    id: 'ek5b97',
    firstName: 'Glenda',
    lastName: 'Douglas',
    dob:'',
    pan:'',
    mobile:'',
    email: 'Eric0@yahoo.com',
    director:'Yes',
    authorized:'No',
    authlimit:0,
    shareper:0,
    addline1:'',
    address:'',
    city:'',
    state: 'Maharashtra',
    pin:0,
  },
  {
    id: 'xxtydd',
    firstName: 'Leone',
    lastName: 'Williamson',
    dob:'',
    pan:'',
    mobile:'',
    email: 'Ericka_Mueller52@yahoo.com',
    director:'No',
    authorized:'Yes',
    authlimit:0,
    shareper:0,
    addline1:'',
    address:'',
    city:'',
    state: 'Kerala',
    pin:0,
  },
  {
    id: 'wzxj9m',
    firstName: 'Mckenna',
    lastName: 'Friesen',
    dob:'',
    pan:'',
    mobile:'',
    email: 'Veda_Feeney@yahoo.com',
    director:'Yes',
    authorized:'No',
    authlimit:0,
    shareper:0,
    addline1:'',
    address:'',
    city:'',
    state: 'Andhra Pradesh',
    pin:0,
  },
  {
    id: '21dwtz',
    firstName: 'Wyman',
    lastName: 'Jast',
    dob:'',
    pan:'',
    mobile:'',
    email: 'Melvin.Pacocha@yahoo.com',
    director:'Yes',
    authorized:'No',
    authlimit:0,
    shareper:0,
    addline1:'',
    address:'',
    city:'',
    state: 'Karnataka',
    pin:0,
  },
  {
    id: 'o8oe4k',
    firstName: 'Janick',
    lastName: 'Willms',
    dob:'',
    pan:'',
    mobile:'',
    email: 'Delfina12@gmail.com',
    director:'Yes',
    authorized:'No',
    authlimit:0,
    shareper:0,
    addline1:'',
    address:'',
    city:'',
    state: 'Uttar Pradesh',
    pin:0,
  },
]; */

// const ynselect = [
//   'Yes',
//   'No',
// ];


/* const InStates = [
'Andhra Pradesh',
'Arunachal Pradesh',
'Assam',
'Bihar',
'Chhattisgarh',
'Goa',
'Gujarat',
'Haryana',
'Himachal Pradesh',
'Jharkhand',
'Karnataka',
'Kerala',
'Madhya Pradesh',
'Maharashtra',
'Manipur',
'Meghalaya',
'Mizoram',
'Nagaland',
'Odisha',
'Punjab',
'Rajasthan',
'Sikkim',
'Tamil Nadu',
'Telangana',
'Tripura',
'Uttar Pradesh',
'Uttarakhand',
'West Bengal',
]; */

/* const useStyles = createStyles((theme) => ({
  button: {
    position: 'relative',
    transition: 'background-color 150ms ease',
  },

  progress: {
    ...theme.fn.cover(-1),
    height: 'auto',
    backgroundColor: 'transparent',
    zIndex: 0,
  },

  label: {
    zIndex: 1,
  },
})); */


// Get geter values
const queryParameters = new URLSearchParams(window.location.search)
//console.log(queryParameters);
//const buyerid = parseInt(queryParameters.get("customer"));
let buyerid = (queryParameters.get("customer"));
let qaenv = false;


//let isuat = false;

//alert(queryParameters);
//alert(buyerid);
//Check buyer id is blank or null
if (buyerid == undefined || buyerid == null) {

  //alert("Something went wrong, please try again");
  //break;
  //exit();
} else {
  //alert("looks good, go ahead");
  //exit();
  qaenv = ((queryParameters.get("customer")).includes("-uat"));

}
//alert("BB");


//alert(qaenv);
//alert("VV");
//console.log("AA");
//console.log(buyerid);
//console.log("BB");
buyerid = parseInt(queryParameters.get("customer"))
//alert(buyerid);

// if (buyerid.includes("-")){
//   buyerid = parseInt(queryParameters.get("customer"))
//   isuat = true;
// }

//console.log("AAA");
//console.log(buyerid);
//console.log("BAB");
CardTable.defaultProps = {
  color: "light",
};

CardTable.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};

const CompanDetails = () => {
//  const { classes, theme } = useStyles();
  // const [progress, setProgress] = useState(0);
  // const [loaded, setLoaded] = useState(false);
  // const [uploadFile, setUploadFile] = useState();
  // const [dir2Cities, setdir2Cities] =useState([]);
  const [dir1Cities, setdir1Cities] = useState([]);
  const [navbarOpen, setNavbarOpen] = useState(false);
  // const [responseArray, setresponseArray] = useState();
  //const [apibaseurl, setapibaseurl]=useState('https://yarnbizqa.4fin.in/apigateway/statusapi/yarnbiz/');
  const [apibaseurl, setapibaseurl]=useState('https://los.statuscapitals.com/apigateway/statusapi/yarnbiz/');
  const [apiqaurl, setapiqaurl]=useState('https://los-preprod.statuscapitals.com/apigateway/statusuatapi/yarnbiz/');
  
  const [apiactivity, setapiactivity]=useState('https://los.statuscapitals.com/apigateway/statusapi/activitydashboard/activitydetails?buyerId=');
  const [panurl, setpanurl] = useState('http://api-preproduction.signzy.app/api/v3/panextensive');
  // const [validationErrors, setValidationErrors] = useState({});
  const [bFirstName, setbFirstName] = useState('');
  const [bLastName, setbLastName] = useState('');
  const [bEmail, setbEmail] = useState('');
  const [SelectUserType, setSelectUserType] = useState('');
  const [SelectBorrowerType, setSelectBorrowerType] = useState();
  const [bGSTN, setbGSTN] = useState('');
  const [bGSTLegalName, setbGSTLegalName]=useState('');
  const [bGSTOrgType, setbGSTOrgType] = useState('');
  const [bGSTRegDate, setbGSTRegDate] = useState('');
  const [bGSTStatus, setGSTStatus] = useState('');
  const [bGSTSale, setbGSTSale] = useState('')

  const [bPAN, setbPAN] = useState('');
  const [bBank, setbBank] = useState('');
  const [bBName, setbBName] = useState('');
  const [bAccount, setbAccount] = useState('');
  //const [bAccountType, setbAccountType] = useState('');
  const [bStatPassword, setbStatPassword] = useState('');
  const [bStatement, setbStatement] = useState('');
  const [uploadedfile, setuploadedfile] = useState(null);
  const [bIFSC, setbIFSC] = useState('');
  // const [bMobile, setbMobile] = useState('');
  const [bShipAddress, setbShipAddress] = useState('');
  const [bShipLine1, setbShipLine1] =useState('');
  const [bShipLine2, setbShipLine2] =useState('');
  const [bShipCity, setbShipCity] = useState('');
  const [bShipState, setbShipState] = useState('');
  const [bShipPin, setbShipPin] = useState('');
  // const [bShipCountry, setbShipCountry] = useState('');
  const [bBillMobile, setbBillMobile] = useState('');
  const [bBillAddress, setbBillAddress] = useState('');
  const [bBillLine1, setbBillLine1] =useState('');
  const [bBillLine2, setbBillLine2] =useState('');
  const [bBillCity, setbBillCity] = useState('');
  const [bBillState, setbBillState] = useState('');
  const [bBillPin, setbBillPin] = useState('');
  // const [bBillCountry, setbBillCountry] = useState('');
  const [bOrgName, setbOrgName] = useState('');
  // const [bIncorpoDate, setbIncorpoDate] = useState('');
  const [bOrgMobile, setbOrgMobile] = useState('');
  const [bOrgAddress, setbOrgAddress] = useState('');
  const [bOrgLine1, setbOrgLine1] =useState('');
  const [bOrgLine2, setbOrgLine2] =useState('');
  const [bOrgCity, setbOrgCity] = useState('');
  const [bOrgState, setbOrgState] = useState('');
  const [bOrgPin, setbOrgPin] = useState('');
  // const [bOrgCountry, setbOrgCountry] = useState('');
  const [bOrgType, setbOrgType] = useState('');
  const [bDirectors, setbDirectors] = useState('');
  const [DirectorNames, setDirectorNames] = useState([]);
  // const [bResFile, setbResFile] = useState('');
  // const [bRes, setbRes]=useState('');
  const [isRender, setisRender] = useState(false);
  const [isOTP, setisOTP] = useState(false);
  // const [savdetails, setsavdetails] = useState(true);
  const [limitAppNo, setlimitAppNo] = useState('');
  // const [userAdded, setuserAdded] = useState(true);
  // const [dirFirstName, setdirFirstName] = useState('');
  const [rmName, setrmName] = useState('');
  const [rmMobile, setrmMobile] = useState('');
  const [rmMail, setrmMail] = useState('');
  const [bnkstatRequired, setbnkstatRequired] = useState('N');
  const [SelectedCity1, setSelectedCity1] = useState('');
  // const [SelectedCity2, setSelectedCity2] = useState('');
  const [SelectedGender1, setSelectedGender1] = useState('M');
  // const [SelectedGender2, setSelectedGender2] = useState('M');

  const [Selectedorgtype,setSelectedorgtype] = useState('0');
  //const vardirname ='';
  const [isLoad, setisLoad] = useState(true);
  const [allLoad, setallLoad] = useState(true);
  const [opened, { open, close }] = useDisclosure(false);
  const [wrongcomptype,setwrongcomptype] = useState('Y');
  // function handleCitySelect2(e) {
  //   //console.log("Selected city", e.target.value);
  //   const citiesSel = e.target.value;
  //   setSelectedCity2(citiesSel);
  // }
  const [directorPan, setdirectorPan] = useState("");
  const [directorName, setdirectorName] = useState("");
  const [directorGender, setdirectorGender] = useState("");
  const [directoraadhar, setdirectoraadhar] = useState('');
  const [valid, setValid] = useState(false);
  const [selecteddirector, setselecteddirector] = useState("");
  const [validdirector, setvaliddirector] = useState(false);
  const [selectedGender, setselectedGender] = useState('M');
  const [validGender, setvalidGender] = useState(false);
  const [directorMobile, setdirectorMobile] = useState("");
  const [directorAadhaar, setdirectorAadhaar] = useState("");
  const [directorDOB, setdirectorDOB] = useState('');
  //const [directorDOB, setdirectorDOB] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const [directorPinCode, setdirectorPinCode] = useState('');
  const [fullAddress, setfullAddress] = useState('');
  const [addressLine1, setaddressLine1] = useState('');
  const [addressLine2, setaddressLine2] = useState('');
  const [directorCity, setdirectorCity] = useState('');
  const [directorState, setdirectorState] = useState('');
  const [directorArea, setdirectorArea] = useState('');
  //const [directorCity, setdirectorCity]= useState('');
  const [bankstatement, setbankstatement] = useState('');
  const [validMobile, setvalidMobile] = useState(false);
  const [validbankpassword, setvalidbankpassword] = useState(false);
  const [validDOB, setvalidDOB] = useState(false);
  const [validPincode, setvalidPincode] = useState(false);
  const [validAadhaar, setvalidAadhaar] = useState(false);
  const [validAddressLine1, setvalidAddressLine1] = useState(false);
  const [validAddressLine2, setvalidAddressLine2] = useState(false);
  const [validDirectorArea, setvalidDirectorArea] = useState(false);
  const [validbankstatement, setvalidbankstatement] = useState(false);
  const [validDirectorCity, setvalidDirectorCity]= useState(false);
  const [validDirectorState, setvalidDirectorState] = useState(false);
  const [OTPvalue, setOTPValue] = useState('');
  const [validOTPValue, setvalidOTPValue] = useState(false);
  const [allValid,setallValid] = useState(false);

  const [erroropen, seterroropen] = useState(false);
  const [displaywait,setdisplaywait] = useState(false);
  const [errorTimer, seterrorTimer] = useState(20000);
  const [errorMessage, seterrorMessage] = useState('Loading details, please wait for sometime......');
  const [errortype, seterrortype] = useState('success');
  const [backopen, setbackopen] = useState(false);
  const [sucessopen, setsucessopen] = useState(false);
  const steps = ['Select campaign settings', 'Create an ad group', 'Create an ad'];
  const [currentActiveStep, setcurrentActiveStep] = useState(0);

  const [processingStatus, setprocessingStatus] = useState(true);
  const [sanctionletterstatus, setsanctionletterstatus] = useState(true);
  const [currentCount, setcurrentCount] = useState(0);
  const [bNachForm, setbNachForm] = useState('');
  //const [uploadedfile, setuploadedfile] = useState(null);
  const [eKYCStatus, seteKYCStatus] = useState('');
  const [bankStatementStatus, setbankStatementStatus] = useState(true);
  const [GSTCONSENTStatus, setGSTCONSENTStatus] = useState(false);
  const [GSTANALYSISStatus, setGSTANALYSISStatus] = useState('');
  const [GSTCONSENTURL, setGSTCONSENTURL]=useState('https://msn.com');
  const [sanctionletterurl,setsanctionletterurl]=useState('https://msn.com');
  const [processingfeeurl, setprocessingfeeurl]=useState('https://msn.com');
  const [nachurl, setnachurl]=useState('https://msn.com');
  const [ESIGNStatus, setESIGNStatus] = useState(true);
  const [LMSLIMITSETUPStatus, setLMSLIMITSETUPStatus] = useState('');
  const [LimitSetupType, setLimitSetupType] = useState('');
  const [finalLimit, setfinalLimit] = useState(0);
  //const [uatenv, setuatenv]= useState(isuat);

  useEffect(() => {
    const interval = setInterval(() => {
      let currentvalue = currentActiveStep + 1;
      
      if (sucessopen){      
          setcurrentActiveStep(currentvalue);
          console.log(currentActiveStep);

          let url = apiactivity+801;
        
          let config = {
              method: 'get',
              maxBodyLength: Infinity,
              url: url,
        
              headers: { 
                'client-id': 'STATU0004200016', 
                'client-secret': 'QOetBBBHrU5jLGvSBE1QiuhVk65NhOEgt3a4qGcy32A=', 
                'Accept': 'application/json', 
                'Content-Type': 'application/json'
              }
            };
            //console.log(url);
            axios.request(config)
            .then((response) => {
              if (JSON.stringify(response.data.responseCode)==200){
                console.log(response.data);
      
                  seteKYCStatus(response.data.data[0].status);
                  setbankStatementStatus(response.data.data[1].status);
                  setGSTCONSENTStatus((response.data.data[2].status =='COMP')?false : true);
                  setGSTANALYSISStatus(response.data.data[3].status);
                  setESIGNStatus(response.data.data[4].status);
                  setLMSLIMITSETUPStatus(response.data.data[5].status);
                  console.log("A");
                  console.log(GSTCONSENTURL);
                  console.log("BB");
                  //console.log(response.data.data[2].links[0].url); 
                  
                  console.log("B");
                  setGSTCONSENTURL((response.data.data[2].links[0]==null)?'' : (response.data.data[2].links[0].url));
                  
                  
                  //setorgname(response.data.data[6].orgName);
                  setsanctionletterstatus(response.data.data[7].status);
                  setprocessingStatus(response.data.data[8].status);
                  setLimitSetupType(response.data.data[9].activityName);
                  setfinalLimit(response.data.data[9].finalLimit);
                  //setfinalLimit(0);
                  //console.log(orgname);
                  //setGSTCONSENTStatus('INI');
                  //console.log(sanctionurl);
                  //console.log(sanctionletterurl);
      
              }
            })
            .catch((error) => {
            });
          }

      }, 1000)

    return () => {
        clearInterval(interval);
    }
}, [currentActiveStep])
  

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));



const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch"
    }
  },
  gridList: {
    width: "100%",
    height: "auto"
  },
  card: {
    maxWidth: 160,
    height: "100%"
  }
}));

const tileData = [
  {
    title: "title1",
    url:''
  },
  {
    title: "title2",
    url:''
  },
];
const classes = useStyles();


  const allvalidcheck =() =>{
    let allvalidvalue = false;

    if (valid){
      allvalidvalue = true;
    }else {
      allvalidvalue = false;
    }
    if (validdirector){
      allvalidvalue = true;
    }else {
      allvalidvalue = false;
    }

    if (validGender){
      allvalidvalue = true;
    }else {
      allvalidvalue = false;
    }

    if (validMobile){
      allvalidvalue = true;
    }else {
      allvalidvalue = false;
    }
    if (validbankpassword){
      allvalidvalue = true;
    }else {
      allvalidvalue = false;
    }
    if (validbankstatement){
      allvalidvalue = true;
    }else {
      allvalidvalue = false;
    }

    if (validDOB){
      allvalidvalue = true;
    }else {
      allvalidvalue = false;
    }

    if (validPincode){
      allvalidvalue = true;
    }else {
      allvalidvalue = false;
    }

    if (validAddressLine1){
      allvalidvalue = true;
    }else {
      allvalidvalue = false;
    }

    if (validAddressLine2){
      allvalidvalue = true;
    }else {
      allvalidvalue = false;
    }
    
    if (validDirectorArea){
      allvalidvalue = true;
    }else {
      allvalidvalue = false;
    }

    
    setallValid(allvalidvalue);
    // console.log('------------------------------------------------------');
    
    // console.log('PAN'+ valid);
    // console.log('Name'+ validdirector);
    //   console.log('Gender'+ validGender);
    //     console.log('Mobile'+ validMobile);
    //       console.log('Statement PAss'+ validbankpassword);
    //         console.log('Bank Statement'+ validbankstatement);
    //           console.log('DOB'+ validDOB);
    //             console.log('Pin'+ validPincode);
    //               console.log('Address Line1'+ validAddressLine1);
    //                                   console.log('Address Line2'+ validAddressLine2);
    //                   console.log('Director Area'+ validDirectorArea);
                      
    // console.log('------------------------------------------------------');
 }

  const handleOTPChange = (e) => {
    setOTPValue(e.target.value);
    let regex = new RegExp(/^[0-9]{6}$/);
    setvalidOTPValue(regex.test(e.target.value));
    //let dummycheck = allvalidcheck();
  }

  const handleValidation = (e) => {
    setdirectorPan(e.target.value);

    let regex = new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/);
    setValid(regex.test(e.target.value));
    //let dummycheck = allvalidcheck();
  };

  const handleAddressLine1 = (e) => {
    setaddressLine1(e.target.value);
    
    if ((e.target.value).length > 0 ) {
      setvalidAddressLine1(true);
    }
    else{
      setvalidAddressLine1(false);
    } 
    //let dummycheck = allvalidcheck();
  };

  const handlebankpassword = (e) => {
    setbStatPassword(e.target.value);
    
    if ((e.target.value).length > 0 ) {
      setvalidbankpassword(true);
    }
    else{
      setvalidbankpassword(false);
    } 
    //let dummycheck = allvalidcheck();
  };


  const handleAddressLine2 = (e) => {
    setaddressLine2(e.target.value);
    
    if ((e.target.value).length > 0 ) {
      setvalidAddressLine2(true);
    }
    else{
      setvalidAddressLine2(false);
    } 
    //let dummycheck = allvalidcheck();
  };


  const handleDirectorDOBValidation  = (e) => {
    setdirectorDOB(e.target.value);
    console.log(e.target.value);
    
    if (!isNaN(new Date(e.target.value))){
        setvalidDOB(true);
    }
    else{
      setvalidDOB(false);
    }
    //let dummycheck = allvalidcheck();
  };

  const handlePincodeValidation = (e) => {
    setdirectorPinCode(e.target.value);

    let regex = new RegExp(/^[0-9]{6}$/);
    
    setvalidPincode(regex.test(e.target.value));
    if (((e.target.value).substring(0,1))==0){
      setvalidPincode(false);
    }
    //let dummycheck = allvalidcheck();
  };


  const handleMobileValidation = (e) => {
    setdirectorMobile(e.target.value);

    let regex = new RegExp(/^[0-9]{10}$/);
    setvalidMobile(regex.test(e.target.value));
    if (((e.target.value).substring(0,1))==0){
      setvalidMobile(false);
    }
    //let dummycheck = allvalidcheck();
  };

  const handleAadhaarValidation = (e) => {
    setdirectorAadhaar(e.target.value);

    let regex = new RegExp(/^[0-9]{12}$/);
    setvalidAadhaar(regex.test(e.target.value));
    if (((e.target.value).substring(0,1))==0){
      setvalidAadhaar(false);
    }

  };
  
  
  const handleDirectorArea = (e) => {
    if ((e.target.value) !== ''){
      setdirectorArea(e.target.value);

      setvalidDirectorArea(true);
      //let dummycheck = allvalidcheck();
    }
    //let dummycheck = allvalidcheck();
  };


  const handleDirectorCity = (e) => {
    if ((e.target.value) !== ''){
      setdirectorCity(e.target.value);

      setvalidDirectorCity(true);
      //let dummycheck = allvalidcheck();
    }
    //let dummycheck = allvalidcheck();
  };

  const handleDirectorState = (e) => {
    if ((e.target.value) !== ''){
      setdirectorState(e.target.value);

      setvalidDirectorState(true);
      //let dummycheck = allvalidcheck();
    }
    //let dummycheck = allvalidcheck();
  };

  const handleDirectorValidation = (e) => {
    setvaliddirector(true);

    if ((e.target.value) !== ''){
      setselecteddirector(e.target.value);
      setdirectorName(e.target.value);

    }
//    alert(validdirector);
    //let dummycheck = allvalidcheck(); 
  };

  const handleDirectorGender = (e) => {
    setvalidGender(false);
    console.log(e.target.value);
    setdirectorGender(e.target.value);
    if ((e.target.value !== '')){
      if ((e.target.value !== 'S')){
        setvalidGender(true);
      }else{
        setvalidGender(false);
      }  
    }
  };

  
  const handleGenderValidation = (e) => {
    //console.log(e.target.value);
    setselectedGender(e.target.value);
    if ((e.target.value !== '')){
      if ((e.target.value !== 'S')){
        setvalidGender(true);
      }else{
        setvalidGender(false);
      }  
    }
    console.log(validGender);
    //let dummycheck = allvalidcheck();
  };

  function handleCitySelect1(e) {
    const citiesSel = e.target.value;
    setSelectedCity1(citiesSel);
    var retcomp = checkmobil();
  }

  function handlegender1(e) {
    const genderSel = e.target.value;
    setSelectedGender1(genderSel);
  }

  function handleorgtype(e) {
    const orgSel = e.target.value;
    setSelectedorgtype(orgSel);
  }
  
  // function handlegender2(e) {
  //   const genderSel = e.target.value;
  //   setSelectedGender2(genderSel);
  // }

/*   const interval = useInterval(
    () =>
      setProgress((current) => {
        if (current < 100) {
          return current + 1;
        }

        interval.stop();
        setLoaded(true);
        return 0;
      }),
    20
  );
 */

/** handles Your Data Value */
// const handleDataValue = (text) => {
//   setDirectorName(text);
//  };

  //alert("Google");
  //console.log("file in");
  const spreadsheetId = '1v5PxEGAOqw7AU-3eu46reEBYMoXppPOJ0JykSZ8O4mA'
  // 1. You can pass spreadsheetId when instantiating the parser:
  const parser = new PublicGoogleSheetsParser(spreadsheetId)
  var datlength;
  var itr;
  var isFound = false;
  var dirarr;


  parser.parse().then((items) => {
    datlength = items.length;
    //alert(datlength);
    for (itr = datlength - 1; itr > -1; itr--) {

      //parseInt(items[itr]["Customer ID"]);
      //buyerid;

      if ((parseInt(items[itr]["Customer ID"]) == buyerid) && (!isFound) && (isLoad)){

        setbFirstName(items[itr]["First Name"]);
        setbLastName(items[itr]["Last Name"]);
        setbEmail(items[itr]["Borrower Email"]);
        setSelectUserType(items[itr]["Select User Type"]);
        setSelectBorrowerType(items[itr]["Select Borrower Type"]);
        setbGSTN(items[itr]["GST Number"]);
        setbGSTLegalName(items[itr]["GST legal name"]);
        setbGSTOrgType(items[itr]["Company Type"]);
        setbGSTRegDate(items[itr]["Gstin Registration Date"]);
        setGSTStatus(items[itr]["Gstin Status"]);
        setbGSTSale((items[itr]["Gross Sale"]).toString());

        setbPAN(items[itr]["PAN Number"]);
        setbBName(items[itr]["Beneficiary Name"]);
        setbBank(items[itr]["Beneficiary Bank Name"]);
        setbAccount(items[itr]["Beneficiary Account"]);
        //setbAccountType(items[itr]["Account Type"]);
        setbIFSC(items[itr]["Beneficiary IFSC"]);
        //console.log(bBName);  
        //setbStatPassword(items[itr]["Bank Statement Password"]);
        //setbStatPassword((items[itr]["Bank Statement Password"]).toString());
        //setbMobile(items[itr]["Beneficiary Mobile"]);
        if ((items[itr]["Company Type"]) != 'PROPRIETORSHIP'){  
            setbnkstatRequired('Y');

        }
        setbShipAddress(items[itr]["Shipping Street Address"])
        const shiparr = (items[itr]["Shipping Street Address"]).split(',');
        if (shiparr[0] == undefined) {
          //setbShipAddress('.');
        }else{ 
          if (shiparr[0].trim()==''){
            //setbShipAddress('.');
          } else {
            //setbShipAddress(shiparr[0]);
          }
        }
        if (shiparr[0] == undefined) {
          setbShipLine1('');
        }else{ 
          if (shiparr[0].trim()==''){
            setbShipLine1('');
          } else {
            setbShipLine1(shiparr[0]);
          }
        }
        if (shiparr[1] == undefined) {
          setbShipLine2('');
        }else{ 
          if (shiparr[1].trim()==''){
            setbShipLine2('');
          } else {
            setbShipLine2(shiparr[1]);
          }
        } 
        setbShipCity(items[itr]["Shipping City"]);
        setbShipState(items[itr]["Shipping State/Province"]);
        setbShipPin(items[itr]["Shipping Zip/Postal Code"]);
        //setbShipCountry(items[itr]["Shipping Country"]);
        setbBillMobile(items[itr]["Billing Phone Number"]);
        setbBillAddress(items[itr]["Billing Street Address"]);
        const billarr = (items[itr]["Billing Street Address"]).split(',');
        if (billarr[0] == undefined) {
          //setbBillAddress('');
        }else{ 
          if (billarr[0].trim()==''){
            //setbBillAddress('');
          } else {
            //setbBillAddress(billarr[0]);
          }
        }
        if (billarr[0] == undefined) {
          setbBillLine1('');
        }else{ 
          if (billarr[0].trim()==''){
            setbBillLine1('');
          } else {
            setbBillLine1(billarr[0]);
          }
        }  
        if (billarr[1] == undefined) {
          setbBillLine2('');
        }else{ 
          if (billarr[1].trim()==''){
            setbBillLine2('');
          } else {
            setbBillLine2(billarr[1]);
          }
        }  


        setbBillCity(items[itr]["Billing City"]);
        setbBillState(items[itr]["Billing State/Province"]);
        setbBillPin(items[itr]["Billing Zip/Postal Code"]);
        //setbBillCountry(items[itr]["Billing Country"]);
        setbOrgName(items[itr]["Company Name"]);
        //setbIncorpoDate(items[itr]["Company Incorporation Date"]);
        setbOrgMobile((items[itr]["Company Phone Number"]).toString());

        const orgarr = (items[itr]["Company Street Address"]).split(',');
        setbOrgAddress(items[itr]["Company Street Address"]);
        if (orgarr[0] == undefined) {
          //setbOrgAddress('');
        }else{ 
          if (orgarr[0].trim()==''){
            //setbOrgAddress('');
          } else {
            //setbOrgAddress(orgarr[0]);
          }
        }  
        if (orgarr[0] == undefined) {
          setbOrgLine1('');
        }else{ 
          if (orgarr[0].trim()==''){
            setbOrgLine1('');
          } else {
            setbOrgLine1(orgarr[0]);
          }
        }  
        if (orgarr[1] == undefined) {
          setbOrgLine2('');
        }else{ 
          if (orgarr[1].trim()==''){
            setbOrgLine2('');
          } else {
            setbOrgLine2(orgarr[1]);
          }
        }
        
        setbOrgCity(items[itr]["Company City"]);
        setbOrgState(items[itr]["Company State/Province"]);
        setbOrgPin(items[itr]["Company Zip/Postal Code"]);
        //setbOrgCountry(items[itr]["Company Country"]);
        setbOrgType(items[itr]["Company Business Type"]);
        setbDirectors(items[itr]["Director Names"]);
       
        setDirectorNames((items[itr]["Director Names"]).split(","));
        //setselecteddirector(DirectorNames[0]);
        //setselectedGender("Male");
      
        // setrmName(items[itr]["RM Name"]);
        // setrmMobile(items[itr]["RM Mobile"]);
        // setrmMail(items[itr]["RM Email Address"]);
        
        setrmName("Pooja Chavan");
        setrmMobile("9920043540");
        setrmMail("sales@yarnbiz.com");
        //console.log("A");
        //console.log((items[itr]["Company Business Type"]).toUpperCase())        ;
        //console.log(wrongcomptype);
        //console.log("B");
        //console.log(wrongcomptype);
        //var organizationType = (items[itr]["Company Business Type"]);
        if (((items[itr]["Company Business Type"])) ==(("PARTNERSHIP")) ){
          setwrongcomptype('N');
        }
        //console.log(wrongcomptype);
        if (((items[itr]["Company Business Type"])) ==(("PRIVATE LIMITED COMPANY")) ){
          setwrongcomptype('N');
        }
        //console.log(wrongcomptype);
        if (((items[itr]["Company Business Type"])) ==(("PROPRIETORSHIP")) ){
          setwrongcomptype('N');
        }
        //console.log(wrongcomptype);
        if (((items[itr]["Company Business Type"])) ==(("PUBLIC LIMITED COMPANY")) ){
          setwrongcomptype('N');
        }


        
        dirarr = (items[itr]["Director Names"]).split(',');
        if (dirarr.length > 0) {
          //document.getElementById("dir1name").value = dirarr[0];
          //document.getElementById("dir1PAN").value = bPAN;
          //document.getElementById("dir1mobile").value = bOrgMobile;
          //document.getElementById("dir1Add1").value = bOrgLine1;
          //document.getElementById("dir1Add2").value = bOrgLine2;
          
          //document.getElementById("dir1PIN").value = bOrgPin;
          //setDirectorName(dirarr[0]);
          //vardirname = DirectorName;
        }

        if (dirarr.length > 1) {
          if (!isRender){
            //document.getElementById("dir2name").value = dirarr[1]; 
           }
        }




        setisRender(true);
        isFound = true;
        setisLoad(false);


        //break;
      }
      
      if (isFound) {

        //console.log(DirectorNames[0]);
        if ((bOrgType) == 'PROPRIETORSHIP'){  
          //setdirectorPan(items[itr]["PAN Number"]);
          //setdirectorMobile(items[itr]["Billing Phone Number"]);
          //setdirectorPinCode(items[itr]["Billing Zip/Postal Code"]);
          //DirectorNames.unshift('Select Director');
        //  setselecteddirector(DirectorNames[0]);
          //setselectedGender('M');
          //setvalidMobile(true);
          //setvalidPincode(true);
          //setValid(true);
          //setvalidbankpassword(true);       
          //setvalidbankstatement(true);  
          //let tmp = getPin({directorPinCode}, 1);
      }
      else
      {
        //setdirectorPan(items[itr]["PAN Number"]);
        //setdirectorMobile(items[itr]["Billing Phone Number"]);
        //setdirectorPinCode(items[itr]["Billing Zip/Postal Code"]);
        
      //  setselecteddirector(DirectorNames[0]);
        //setselectedGender('M');
        //setvalidMobile(true);
        //setvalidPincode(true);
        //setValid(true);
    }  
      //console.log(DirectorNames);
      //setvaliddirector(false);
      //setvalidGender(false);

      // }
      //DirectorNames.unshift('Select Director');
        break;
      }
    }
  })
  //setselecteddirector(DirectorNames[0]);
  //setselectedGender('M');
  if (bFirstName==""){
    //seterroropen(true);
    //seterrorMessage('Issue in connecting with server');
    //seterrorTimer(8000);
    //seterrortype('error');
    //alert('We are sorry, not able to connect to server to get the details, please try after sometime');
    //return false;
  }



//   if ((bOrgType !='')) {
//     console.log('searching details');
//     setallLoad(false);
//    let myconfig = {
//       method: 'get',
//       maxBodyLength: Infinity,
//       url: 'https://los.statuscapitals.com/apigateway/statusapi/GeneralMasters/getParamValues?paramType=COMTP',

//       headers: { 
//         'client-id': 'STATU0004200016', 
//         'client-secret': 'QOetBBBHrU5jLGvSBE1QiuhVk65NhOEgt3a4qGcy32A=', 
//         'Accept': 'application/json', 
//         'Content-Type': 'application/json'
//       }
//     };
//     axios.request(myconfig)
//     .then((response) => {
//       if (JSON.stringify(response.data.responseCode)==200){
//         for (compcount=0; compcount < (response.data.data.length); compcount++) {
//           if (((response.data.data[compcount].paramValue).toUpperCase()) ===((bOrgType).toUpperCase()) ){
//               comptype = response.data.data[compcount].paramCode;
//               setSelectedorgtype(comptype); 
//               setwrongcomptype('N');              
//               break;
//           }
//         }
//     }
//     })
//     .catch((error) => {
//     });

// }

  //console.log("file out");

  const changeDir2 = (e) =>{
    if (e.target.value==""){
      dirarr[1] ='';
    }

  }
  const myToday = () => {
    return (moment(new Date()).format('YYYY-MM-DD'));
  }


  
  /* const columns = useMemo(
    () => [
       {
        accessorKey: 'id',
        header: 'Id',
        enableEditing: false,
        enableHiding:false,
        columnVisibility:false,
        size: 80,
      }, 
      
      {
        accessorKey: 'firstName',
        header: 'First Name',
        
        mantineEditTextInputProps: {
          type: 'email',
          required: true,
          error: validationErrors?.firstName,
          striped: true,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              firstName: undefined,
            }),
          //optionally add validation checking for onBlur or onChange
        },
      },
      {
        accessorKey: 'lastName',
        header: 'Last Name',
        mantineEditTextInputProps: {
          type: 'email',
          required: true,
          error: validationErrors?.lastName,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              lastName: undefined,
            }),
        },
      },
      {
        accessorKey: 'dob',
        //Cell: props => {moment(props.value).format('dd/MM/yyyy')} ,
        //cell: props =>{moment(props.getValue()).format("dd-MM-YYYY")},
        header: 'Date of Birth',
         
        mantineEditTextInputProps: {
          type: 'date',
          required: true,
          max:myToday(),
          format:'dd-MM-yyyy',
          //min:myToday(),
          //max:{Date().toISOString().split('T')[0]},
          //max:"2023-25-08",

          
          error: validationErrors?.dob,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              dob: undefined,
            }),
        },
      },
      {
        accessorKey: 'pan',
        header: "PAN NO",
        
        mantineEditTextInputProps: {
          type: 'text',
          required: true,
          maxLength:10,
          //pattern: "[A-Z]{5}[0-9]{4}[A-Z]{1}",
          pattern:"[A-Z]{5})(\d{4})([A-Z]{1}",
          error: validationErrors?.pan,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              pan: undefined,
            }),
          //optionally add validation checking for onBlur or onChange
        },
      },
      {
        accessorKey: 'mobile',
        header: "Mobile No",
        
        mantineEditTextInputProps: {
          type: 'tel',
          required: true,
          maxLength:10,
          error: validationErrors?.mobile,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              mobile: undefined,
            }),
          //optionally add validation checking for onBlur or onChange
        },
      },
      {
        accessorKey: 'email',
        header: 'Email',
        mantineEditTextInputProps: {
          type: 'email',
          required: true,
          error: validationErrors?.email,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              email: undefined,
            }),
        },
      },
      {
        accessorKey: 'director',
        header: 'Director?',
        editVariant: 'select',
        mantineEditSelectProps: {
          data: ynselect,
          error: validationErrors?.director,
        },
      },
      //authlimit:0,
      //shareper:0,
      {
        accessorKey: 'authorized',
        header: 'Authorized Signatory?',
        editVariant: 'select',
        mantineEditSelectProps: {
          data: ynselect,
          
          error: validationErrors?.authorized,
        },
      },

      {
        accessorKey: 'authlimit',
        header: "Authorization Limit (₹)",
        mantineTableHeadCellProps: {
          align: 'right',
        },
        mantineTableBodyCellProps: {
          align: 'right',
        },
        
        mantineEditTextInputProps: {
          type: 'number',
          required: false,
          //enableEditing:true,
          maxLength:7,
          //value:0,
          min:0,
          
          max:9999999,
          muiTableHeadCellProps: {
            align: 'right',
          },
          muiTableBodyCellProps: {
            align: 'right',
          },
          Cell: ({ cell }) =>
            cell
              .getValue()
              .toLocaleString('en-IN', { style: 'currency', currency: '₹' }),
          error: validationErrors?.authlimit,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              authlimit: undefined,
            }),
          //optionally add validation checking for onBlur or onChange
        },
      },
      {
        accessorKey: 'shareper',
        header: "Share %",
        
        mantineEditTextInputProps: {
          type: 'number',
          required: false,
          //value:0,
          maxLength:3,
          min:0,
          max:100,
          error: validationErrors?.shareper,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              shareper: undefined,
            }),
          //optionally add validation checking for onBlur or onChange
        },
      },
      //addline1:'',
      //address:'',
      //city:'',
      //state: 'Uttar Pradesh',
      //pin:0,
      {
        accessorKey: 'addline1',
        header: "Address Line 1",
        
        mantineEditTextInputProps: {
          type: 'text',
          required: true,
          //maxLength:12,
          error: validationErrors?.addline1,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              addline1: undefined,
            }),
          //optionally add validation checking for onBlur or onChange
        },
      },
      {
        accessorKey: 'address',
        header: "Address",
        
        mantineEditTextInputProps: {
          type: 'text',
          required: true,
          //maxLength:12,
          error: validationErrors?.address,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              address: undefined,
            }),
          //optionally add validation checking for onBlur or onChange
        },
      },
      {
        accessorKey: 'city',
        header: "City",
        
        mantineEditTextInputProps: {
          type: 'text',
          required: true,
          //maxLength:12,
          error: validationErrors?.city,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              city: undefined,
            }),
          //optionally add validation checking for onBlur or onChange
        },
      },
      {
        accessorKey: 'pin',
        header: "PIN Code",
        
        mantineEditTextInputProps: {
          type: 'text',
          required: true,
          maxLength:6,
          error: validationErrors?.pin,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              pin: undefined,
            }),
          //optionally add validation checking for onBlur or onChange
        },
      },      
      {
        accessorKey: 'state',
        header: 'State',
        editVariant: 'select',
        required:true,
        mantineEditSelectProps: {
          data: InStates,
          error: validationErrors?.state,
        },
      },
    ],
    [validationErrors],
  ); */

  // const handleChange= (event) =>{
  //   setDirectorName({value: event.target.value});
  // }




  const handleFileChange = (event) => {
  let selectedFile = event.target.files;
  //let file = null;
  let fileName = "";
  let filetype = "";
  //Check File is not Empty
  if (selectedFile.length > 0) {
      // Select the very first file from list
      let fileToLoad = selectedFile[0];
      
      fileName = fileToLoad.name;
      filetype = (fileName.split(".").at(-1));
      //console.log("file type:" + filetype);
      //console.log(fileName);
      if ((filetype.toUpperCase()) != "PDF"){
        //alert("You have not uplodaed 'PDF' file, you have uploaded '"+ filetype.toUpperCase() +"'\n Please upload bank statement in 'PDF' format");
        event.target.value=null;
        seterroropen(true);
        seterrorMessage("You have not uplodaed 'PDF' file, you have uploaded '"+ filetype.toUpperCase() +". Please upload bank statement in 'PDF' format");
        seterrorTimer(8000);
        seterrortype('error');
        return false;
      }
      setbankstatement(fileToLoad);
      setvalidbankstatement(true);
      
      // FileReader function for read the file.
      let fileReader = new FileReader();
      // Onload of file read the file content
      fileReader.onload = function(fileLoadedEvent) {
          setuploadedfile(fileLoadedEvent.target.result);
          //setbStatement(fileLoadedEvent.target.result);
          // Print data in console
          console.log(uploadedfile);
      };
      // Convert data to base64
      fileReader.readAsDataURL(fileToLoad);
  }
setbStatement(uploadedfile);
//console.log(file);
//console.log('hello world');
//console.log(bStatement);
//console.log('hello again');
//console.log(file);
//console.log('hello over');
//this.setState({
//  fileData: file,
//  fileName: fileName
//})
}



  const handleFileChange1 = (event) => {
    const file = event.target.files[0];
    let readfile = null;  
    //console.log(file);
    //setUploadFile(file);
    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        //setbStatement(e.target.result);
        readfile = e.target.result;
      };
      //reader.readAsArrayBuffer(file); 
      //reader.readAsDataURL(file);
      //reader.readAsBinaryString (file);
      reader.readAsDataURL(readfile);
      
    }
    setbStatement(readfile);
    
  };

  const handleBoardFile = (event) => {
    const file = event.target.files[0];
    
    //setbResFile(file);

 
    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        //setbRes(e.target.result);
      };

      //reader.readAsDataURL(file);
      reader.readAsBinaryString (file);
    }
  }
  function validdirPAN(firstOrSecond) {
    var searchPAN ='';
    var searchName ='';
    var errorMessage = '';
    if (!isLoad) {
      if (firstOrSecond >0) {
        if (firstOrSecond == 1) {
          if (document.getElementById("dir1PAN") != null) {
            searchPAN = document.getElementById("dir1PAN").value;
          }
          if ((document.getElementById("dir1name") != null)) {
            searchName = document.getElementById("dir1name").value; 
          }
        } 
      if (searchPAN =='') {
          errorMessage = 'PAN No can not be blank';
      }
      if (searchName == ''){
          errorMessage += '\nName can not be blank';
      }
      console.log(errorMessage);
      if (errorMessage !=''){
          errorMessage = "You need to corrct following details for '" + ((firstOrSecond == 1) ? 'First' : 'Second') + "' director:\n either blank PAN or blank Name ";

          alert(errorMessage);
          return false;
      }

      // check PAN now
      // get the token

      let url = 'https://www.yarnbiz.com/rest/V1/integration/admin/token';
      let data = JSON.stringify({
        "username": "shreyas",
        "password": "shreyas@123"
      });
      
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: url,
        headers: { 
          'Content-Type': 'application/json'
        },
        data :data
      };  
      axios.request(config)
.then((response) => {
  console.log(JSON.stringify(response.data));
})
.catch((error) => {
  console.log(error);
});       

      

      }

  }

  }
  


  function getPAN(){
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "xtBAPJ4kdF6Tyr1vMLvbJeJpZu2AAuSG");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Access-Control-Allow-Origin", "*");
    
    const raw = JSON.stringify({
      "panNumber": "LTVPS5422F",
      "getStatusInfo": "false"
    });
    
    const requestOptions = {
      method: "POST",
      //mode: 'no-cors',
      headers: myHeaders,
      crossDomain: true,
      body: raw,
      redirect: "follow"
      //,
      //proxy: "https://api-preproduction.signzy.app"


    };
    
    fetch("https://api-preproduction.signzy.app/api/v3/panextensive", requestOptions)
      .then((response) => console.log(response.data))
      .then((result) => console.log(result))
      .catch((error) => console.error(error));

  }
  
  function getPAN1(){
    console.log('hello');
    //const axios = require('axios');

    let data = JSON.stringify({
      "panNumber": "ABWPG5400F",
      "getStatusInfo": "false"
    });
    
    let config = {
      method: 'post',
      //maxBodyLength: Infinity,
      url: 'https://api-preproduction.signzy.app/api/v3/panextensive',
      headers: { 
        'Content-Type': 'application/json', 
        'Authorization': 'xtBAPJ4kdF6Tyr1vMLvbJeJpZu2AAuSG', 
      },
      data : data
    };
    
    console.log('data');
    console.log(config);






//     const data = {
//         panNumber: 'ABWPG5400F',
//         getStatusInfo: false
//     };

//    let config = {
//       method: 'post',
//       maxBodyLength: Infinity,
//       url: 'https://api-preproduction.signzy.app/api/v3/panextensive',
//       //url: '',

//       headers: { 
// //        'Authorization': 'Basic T1gwLTFsNzdsMVYzOThhZmtWLjlhNTc5NWFiZjgzOGRlODdmOWYzMzhiMmM0MzUzMzI4OmNkZTgzMTg3YTg5YjY5NTdmN2MwYzI1ZDY4NTFiZjg0MzQzYTNkYWMxNWZiZjBiZA==', 
//         'Authorization': 'xtBAPJ4kdF6Tyr1vMLvbJeJpZu2AAuSG', 

// 'Content-Type': 'application/json'
//       },
//       data : data
//     };
    
//     console.log('data');
//     console.log(config);
    // axios.request(config)
    


    // //axios.post('https://api-preproduction.signzy.app/api/v3/panextensive', data, {
    // //    headers: {
    // //        'Content-Type': 'application/json',
    // //        'Authorization': 'xtBAPJ4kdF6Tyr1vMLvbJeJpZu2AAuSG'  // Replace with your actual token
    // //    }
    // //})
    // .then(response => {
    //   console.log('hello2');

    //   console.log(response.data);
    // })
    // .catch(error => {
    //   //console.log('hello3');
    //     console.error('Error:', error.response ? error.response.data : error.message);
    // });
    // console.log('hello4');

    axios.request(config)
    .then(response => {
      console.log(JSON.stringify(response.data));
      console.log(response.data);
    })
    .catch(error => { 
      console.log('error');
      console.log(error);
      console.log(error.message);
    });


  }


  function getoldPAN(){
    alert(directorPan);
    console.log('signzy api');
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "xtBAPJ4kdF6Tyr1vMLvbJeJpZu2AAuSG");
    

    const raw = JSON.stringify({
      "panNumber": "ABWPG5400F",
      "getStatusInfo": false
    });
    
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };
    
    fetch("api-preproduction.signzy.app/api/v3/panextensive", requestOptions)
      .then((response) => {response.text();console.log('1');})
      .then((result) => {console.log(result);console.log('2')})
      .catch((error) => {console.error(error);console.log('3')});

    let data = JSON.stringify({
      "panNumber": "ABWPG5400F",
      "getStatusInfo": false
    });
    
    let config = {
      method: 'post',
      //maxBodyLength: Infinity,
      url: 'https://api-preproduction.signzy.app/api/v3/panextensive',
      headers: { 
        'Content-Type': 'application/json', 
        'Authorization': 'xtBAPJ4kdF6Tyr1vMLvbJeJpZu2AAuSG'
      },
      data : data
    };
    
    //  axios.request(config)
    //  .then((response) => {
    //    console.log(JSON.stringify(response.data));
    //  })
    //  .catch((error) => {
    //    console.log('hi');
    //    console.log(error);
    //  }); 

    // data = JSON.stringify({
    // "panNumber": "ABWPG5400F",
    // "getStatusInfo": false
    // });

    
    
      console.log(data);
    // config = {
    //   method: 'post',
    //   maxBodyLength: Infinity,
      
    //   url: 'api-preproduction.signzy.app/api/v3/panextensive',
    //   headers: { 
    //     'Authorization': 'xtBAPJ4kdF6Tyr1vMLvbJeJpZu2AAuSG', 
    //     //'Access-Control-Allow-Origin' : '*',
    //     //  'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',   
    //     'Content-Type': 'application/json'
    //   },
    //   data : data
    // };

    console.log('attester api')
    data = JSON.stringify({
      "pan": "ABWPG5400F"
    });
    
    config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://api.attestr.com/api/v2/public/checkx/pan/comprehensive',
      //url: '',

      headers: { 
//        'Authorization': 'Basic T1gwLTFsNzdsMVYzOThhZmtWLjlhNTc5NWFiZjgzOGRlODdmOWYzMzhiMmM0MzUzMzI4OmNkZTgzMTg3YTg5YjY5NTdmN2MwYzI1ZDY4NTFiZjg0MzQzYTNkYWMxNWZiZjBiZA==', 
        'Authorization': 'Basic T1gwdk9BU0UzOXZGNGJXa3MyLjgyZTZlNTY3ZmNmMTVjMjMyZDBjZjdhNDZkOGExNDBhOjRkOTk4MTk0ODRlNjZlMjAwMjcyMWU2ZTVkZDAzOGVmNWQxYWE4OWFmZjk3N2JkOA==', 

'Content-Type': 'application/json'
      },
      data : data
    };
    
    axios.request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
    })
    .catch((error) => { 
      console.log(error);
    });
    




    console.log('secure api');
    axios.get('https://api.github.com/users/Bard')

    .then(response => {
  
      console.log(response.data);
  
    })
  
    .catch(error => {
  
      if (error.response) {
  
        // The server responded with a status code outside the 2xx range
  
        console.log('Error response:', error.response);
  
      } else if (error.request) {
  
        // The request was made but no response was received
  
        console.log('Error request:', error.request);
  
      } else {
  
        // Something happened in setting up the request that triggered an error
  
        console.log('Error message:', error.message);
  
      }
  
    });


    //axios.request(config)
    //.then((response) => {
    //  console.log(response);
    //  console.log(JSON.stringify(response.data));
      //update variables
      // setdirectorName();
      // setdirectorGender();
      // setdirectorMobile();
      // setdirectoraadhar();
      

    //})
    //.catch((error) => {
    //  console.log(error);
    //});


    






  }

  function getPin(pinToFind, firstOrSecond){
    var returnarray;
    returnarray = checkmobil();
    if (directorPinCode == undefined || directorPinCode == ''){
      return false
    }

    console.log("director value:")
    console.log(selecteddirector);
    console.log(selectedGender);
    console.log(directorPan);
    console.log(directorMobile);
    console.log(directorDOB);
    console.log(directorPinCode);
    console.log(addressLine1);
    console.log(addressLine2);
    console.log(directorArea);
    console.log(directorCity)
    // let url = 'https://los.statuscapitals.com/apigateway/statusapi/lead/verifyPIN?pincode='+(pinToFind);
    // console.log(url);
    let url = 'https://los.statuscapitals.com/apigateway/statusapi/lead/verifyPIN?pincode='+(directorPinCode);
    console.log(url);
    let config = {
      method: 'POST',
      maxBodyLength: Infinity,
      url: url,
   

      headers: { 
        'client-id': 'STATU0004200016', 
        'client-secret': 'QOetBBBHrU5jLGvSBE1QiuhVk65NhOEgt3a4qGcy32A=', 
        'Accept': 'application/json', 
        'Content-Type': 'application/json'
      }
    };

    axios.request(config)
    .then((response) => {
      if (response.data.responseCode==200){
          setdirectorCity(response.data.data.cityName);
          setdirectorState(response.data.data.stateName);
          setdir1Cities(response.data.data.areaName);
       }
    })
    .catch((error) => {
    });
    return returnarray;
  }


  function getOrgType(){
  }

  //console.log("company type");
  //console.log(getOrgType());
  //console.log("company type");

  function checkmobil(){
    var compcount;
    var comptype = 0;
    var el;

    //console.log("A");
    //console.log(wrongcomptype);

    if (wrongcomptype == "N"){
      //console.log("true");
      let myconfig = {
          method: 'get',
          maxBodyLength: Infinity,
          url: 'https://los.statuscapitals.com/apigateway/statusapi/GeneralMasters/getParamValues?paramType=COMTP',

          headers: { 
            'client-id': 'STATU0004200016', 
            'client-secret': 'QOetBBBHrU5jLGvSBE1QiuhVk65NhOEgt3a4qGcy32A=', 
            'Accept': 'application/json', 
            'Content-Type': 'application/json'
          }
        };
        axios.request(myconfig)
        .then((response) => {
          //console.log("comptype");
          //console.log(response.data);
          if (JSON.stringify(response.data.responseCode)==200){
            
            for (compcount=0; compcount < (response.data.data.length); compcount++) {
              if (((response.data.data[compcount].paramValue).toUpperCase()) == (bOrgType.toUpperCase())){
                  comptype = response.data.data[compcount].paramCode;
                  console.log("comptype 2");
                  //console.log(comptype);
                  //setSelectedorgtype(comptype); 
                  setSelectedorgtype(comptype);
                  el = document.getElementById('selectedcomptype');
                  el.value = comptype;

                  
                  setwrongcomptype('N');              
                  //console.log("inside loop");
                                //console.log(comptype);
                  console.log(Selectedorgtype);
                  break;
              }
            }
        }
        })
        .catch((error) => {
        });
    }
    //console.log("outside loop");
    //console.log("comptype");
    //console.log(comptype);
    //console.log(Selectedorgtype);
    //console.log("B");
    console.log(Selectedorgtype);
    return comptype;

  }
  const SaveDetails = async (event) => {
    //var data = '';
    var notdata = "";
    var error = "";
    var dircount = 0;
    var authcount = 0;
    var shareholder = 0;
    var sharetotla = 0;
    var gstMember = '';
    //var GSTOrgType = bGSTOrgType;
    //var resultdata;
    //var comptype;
    var compcount;
    var comptype = 0;
    var el;

    //var retcomp = checkmobil();

    // var responseArray;
    // console.log("A");
    // responseArray = getOrgType();
    // console.log("B");

    // console.log(responseArray);

    // var oOrType = Selectedorgtype;
    //  //console.log(oOrType);
    //  responseArray = getOrgType();
    //  oOrType = Selectedorgtype;
    //  //console.log(oOrType);
    //  //console.log(Selectedorgtype);

    // if (responseArray.length > 0) {
    // responseArray.forEach((orData)=>{
    //     if (((orData.paramValue).trim()).toUpperCase() == (bOrgType.trim()).toUpperCase()) {
    //       oOrType = orData.paramCode;
    //       console.log(oOrType);
    //     }
    // })
    // }

    //setsucessopen(true);
    //return false;


    // const cresponseArray = [
    //   {
    //     "paramCode": 52,
    //     "paramType": "COMTP",
    //     "paramValue": "Pvt Ltd"
    // },
    // {
    //     "paramCode": 53,
    //     "paramType": "COMTP",
    //     "paramValue": "LLP"
    // },
    // {
    //     "paramCode": 54,
    //     "paramType": "COMTP",
    //     "paramValue": "Proprietorship"
    // },
    // {
    //     "paramCode": 55,
    //     "paramType": "COMTP",
    //     "paramValue": "Partnership"
    // },
    // {
    //     "paramCode": 56,
    //     "paramType": "COMTP",
    //     "paramValue": "MNC"
    // },
    // {
    //     "paramCode": 57,
    //     "paramType": "COMTP",
    //     "paramValue": "Public ltd"
    // },
    // {
    //     "paramCode": 58,
    //     "paramType": "COMTP",
    //     "paramValue": "HUF"
    // },
    // {
    //     "paramCode": 59,
    //     "paramType": "COMTP",
    //     "paramValue": "Trust"
    // },
    // {
    //     "paramCode": 60,
    //     "paramType": "COMTP",
    //     "paramValue": "Society"
    // },
    // {
    //     "paramCode": 137,
    //     "paramType": "COMTP",
    //     "paramValue": "Individual"
    // },
    // {
    //     "paramCode": 138,
    //     "paramType": "COMTP",
    //     "paramValue": "One Person Company"
    // },
    // {
    //     "paramCode": 139,
    //     "paramType": "COMTP",
    //     "paramValue": "labour"
    // }
    // ]

/*    if (bOrgType == "PROPRIETORSHIP") {

    }else{
         if ((uploadFile == undefined) || (uploadFile.length <1)){ 
          alert('Bank statement not selected to upload, please select the same before saving details');
          return false
        }
        if ((bStatPassword == undefined) || (bStatPassword.trim() == '')){ 
          alert('Bank statement password is mandatory, please enter the same before saving details');
          return false 
        } 
    }
/*     if ((bResFile == undefined) || (bResFile.length <1)){ 
      alert('Board Resolution not uploaded, please upload the same before saving details');
      return false 
    } */
    // read file content to upload
/*     if(!window.FileReader) {
      alert("Your browser is not supporting file upload!!!");
      return false;
    }// Browser is not compatible
 */
    


    //Check for stakeholder details grid empty or not
    // var isStakeError = "";
    
    // if ((document.getElementById('dir1name').value) == "") {
    //   isStakeError = "Name";
    // }
    // if ((document.getElementById('dir1PAN').value) == "") {
    //   isStakeError += "\nPAN";
    // }
    // if ((document.getElementById('dir1mobile').value) == "") {
    //   isStakeError += "\nMobile No";
    // }
    // if ((document.getElementById('dir1DOB').value) == "") {
    //   isStakeError += "\nDate of Birth";
    // }   
    // if ((document.getElementById('dir1PIN').value) == "") {
    //   isStakeError += "\nCity PIN code";
    // }
    // if ((document.getElementById('dir1Add1').value) == "") {
    //   isStakeError += "\nAddress Line1";
    // }
    // if ((document.getElementById('dir1area').value) == "") {
    //   isStakeError += "\nAddress";
    // }   
    // if ((document.getElementById('dir1city').value) == "") {
    //   isStakeError += "\nYou need to select 'City'";
    // }
    // if ((document.getElementById('dir1state').value) == "") {
    //   isStakeError += "\nState";
    // }

    // if (isStakeError !=""){
    //   alert('Following Details of First Stakeholder is required:\n' +isStakeError);
    //   return false
    // }

    // isStakeError = '';
    // if ((document.getElementById('dir1PAN').value).search(/^([A-Z]{5})(\d{4})([A-Z]{1})$/)==-1) {
    //   isStakeError +=  "Incorrect PAN format - Expected format 'AAAAA1234A'";
    // }

    // if (document.getElementById('dir1mobile').value.length < 10) {
    //   isStakeError +=  "\n10 digit mobile number is required ";
    // }

    // if (document.getElementById('dir1PIN').value.length < 6) {
    //   isStakeError +=  "\n6 digit PIN is required ";
    // }

    // if (isNaN(+(document.getElementById('dir1PIN').value))) {
    //   isStakeError +=  "\nEntered PIN is not a number";
    // }
  
    // if (isNaN(+(document.getElementById('dir1mobile').value))) {
    //   isStakeError +=  "\nEntered Mobile for is not a number";
    // }
  
    
    // if (isStakeError !=""){
    //   alert('Following Details are not correct for First Stakeholder:\n' +isStakeError);
    //   return false
    // }

    // //Check for 2nd stakeholder details grid empty or not
    // isStakeError = "";

    // if ((document.getElementById('dir2name').value).trim() != "") {

    //   if ((document.getElementById('dir2PAN').value) == "") {
    //     isStakeError += "\nPAN";
    //   }
    //   if ((document.getElementById('dir2mobile').value) == "") {
    //     isStakeError += "\nMobile No";
    //   }
    //   if ((document.getElementById('dir2DOB').value) == "") {
    //     isStakeError += "\nDate of Birth";
    //   }   
    //   if ((document.getElementById('dir2PIN').value) == "") {
    //     isStakeError += "\nCity PIN code";
    //   }
    //   if ((document.getElementById('dir2Add1').value) == "") {
    //     isStakeError += "\nAddress Line1";
    //   }
    //   if ((document.getElementById('dir2Add').value) == "") {
    //     isStakeError += "\nAddress";
    //   }   
    //   if ((document.getElementById('dir2city').value) == "") {
    //     isStakeError += "\nYou need to select 'City'";
    //   }
    //   if ((document.getElementById('dir2state').value) == "") {
    //     isStakeError += "\nState";
    //   }
    
    //   if (isStakeError !=""){
    //     alert("Following Details need to enter for '"+(document.getElementById('dir2name').value).trim() +"' :\n" +isStakeError);
    //     return false
    //   }

    // }
    // if ((document.getElementById('dir2name').value).trim() != "") {
    //   isStakeError = '';
    //   if ((document.getElementById('dir2PAN').value).search(/^([A-Z]{5})(\d{4})([A-Z]{1})$/)==-1) {
    //     isStakeError +=  "Incorrect PAN format - Expected format 'AAAAA1234A'";
    //   }
  
    //   if (document.getElementById('dir2mobile').value.length < 10) {
    //     isStakeError +=  "\n10 digit mobile number is required ";
    //   }
  
    //   if (document.getElementById('dir2PIN').value.length < 6) {
    //     isStakeError +=  "\n6 digit PIN is required ";
    //   }
  
    //   if (isNaN(+(document.getElementById('dir2PIN').value))) {
    //     isStakeError +=  "\nEntered PIN is not a number";
    //   }
    
    //   if (isNaN(+(document.getElementById('dir2mobile').value))) {
    //     isStakeError +=  "\nEntered Mobile for is not a number";
    //   }
    
      
  

    //   if (isStakeError !=""){
    //     alert("Following Details are not correct for '"+(document.getElementById('dir2name').value).trim() +"':\n" +isStakeError);
    //     return false
    //   }




    // }

    



   class dirUsers {
      constructor() {
        this.name = "";
        this.PAN = "";
        this.gender ="";
        this.mobile = "";
        this.dob = "";
        this.pin = "";
        this.address1 = "";
        this.address2 = "";
        this.area="";
        this.city = "";
        this.state = "";
      }
    }
    
    var fetchedUsers = [];
 
    fetchedUsers.push(new dirUsers()); 
    // fetchedUsers[0].name = document.getElementById('dir1name').value;
    // fetchedUsers[0].PAN = document.getElementById('dir1PAN').value;
    // fetchedUsers[0].gender = document.getElementById('dir1gender').value;
    // fetchedUsers[0].mobile = document.getElementById('dir1mobile').value;
    // fetchedUsers[0].dob = document.getElementById('dir1DOB').value;
    // fetchedUsers[0].pin = document.getElementById('dir1PIN').value;
    // fetchedUsers[0].address1 = document.getElementById('dir1Add1').value;
    // fetchedUsers[0].address2 = document.getElementById('dir1Add2').value;
    // fetchedUsers[0].area = document.getElementById('dir1area').value;
    // fetchedUsers[0].city = document.getElementById('dir1city').value;
    // fetchedUsers[0].state = document.getElementById('dir1state').value;
    fetchedUsers[0].name = selecteddirector;
    fetchedUsers[0].PAN = directorPan;
    fetchedUsers[0].gender = selectedGender;
    fetchedUsers[0].mobile = ""+ directorMobile +"";
    fetchedUsers[0].dob = directorDOB;
    fetchedUsers[0].pin = directorPinCode;
    fetchedUsers[0].address1 = addressLine1;
    fetchedUsers[0].address2 = addressLine2;
    fetchedUsers[0].area = directorArea;
    fetchedUsers[0].city = directorCity;
    fetchedUsers[0].state = directorState;

    // if ((document.getElementById('dir2name').value).trim() != "") {
    //   fetchedUsers.push(new dirUsers()); 
    //   fetchedUsers[1].name = document.getElementById('dir2name').value;
    //   fetchedUsers[1].PAN = document.getElementById('dir2PAN').value;
    //   fetchedUsers[1].gender = document.getElementById('dir2gender').value;
    //   fetchedUsers[1].mobile = document.getElementById('dir2mobile').value;
    //   fetchedUsers[1].dob = document.getElementById('dir2DOB').value;
    //   fetchedUsers[1].pin = document.getElementById('dir2PIN').value;
    //   fetchedUsers[1].address1 = document.getElementById('dir2Add1').value;
    //   fetchedUsers[1].address2 = document.getElementById('dir2Add2').value;
    //   fetchedUsers[1].area = document.getElementById('dir2area').value;
    //   fetchedUsers[1].city = document.getElementById('dir2city').value;
    //   fetchedUsers[1].state = document.getElementById('dir2state').value;
    // }

   

/*     fetchedUsers.forEach((stakdata) => {
      //get director count
      if (stakdata.director == "Yes") {
          if (gstMember.trim()==''){
            gstMember=(stakdata.firstName + " " + stakdata.lastName);
          }
          dircount++;
      }
      //get authorized person count
      if (stakdata.authorized == "Yes") {
        authcount++;
      } 
      // get shareholder cound
      if ((stakdata.shareper == 0) || (stakdata.shareper == undefined)) {
        
      } else {
        shareholder++;
        sharetotla=sharetotla+stakdata.shareper;
      }
    }
    )
    if (dircount < 1) {
      error = "At least one(1) Director is required \n\n";
    }

    if (authcount < 1) {
      error += "At least one(1) Authorized Signatory is required \n\n";
    }

    if (authcount > 3) {
      error += "Maximum Three(3) Authorized Signatory allowed \n\n";
    }

    if (shareholder < 1) {
      error += "At least one(1) Shareholer is required \n\n";
    } 

    if (error.length > 0) {
      alert(error);
      return false;
    } */


    

/*     const fileReader = new FileReader(); 

    fileReader.readAsText(uploadFile);
    fileReader.onload = function(e) {
    var rawLog = fileReader.result;
    setbStatement(rawLog);
    console.log(rawLog);
    };
 */

  

      //alert("Saving details");



      const get_file_array = (file) => {
        return new Promise((acc, err) => {
            const reader = new FileReader();
            reader.onload = (event) => { acc(event.target.result) };
            reader.onerror = (err)  => { err(err) };
            reader.readAsArrayBuffer(file);
        });
     }
      
    
      let dirData = [];

      function Directory() {
        var sharepercent = 0;
        var authrlimit = 0;
        var address;

        let returnList =  fetchedUsers.forEach((stakdata) => {
         
          if (stakdata.shareper == undefined){
            sharepercent = "0"
          }else{  
            sharepercent = (stakdata.shareper).toString()
          }  
          if (stakdata.authlimit == undefined){
            authrlimit = "0"
          }else{  
            authrlimit = (stakdata.authlimit).toString()
          }  

          if ((stakdata.address == undefined) || (stakdata.address == '')){
            address = '.';
          } else {
            address = stakdata.address;
          }

          
          const dirname = (stakdata.name).split(' ');
          var dirFName, dirLName = '.';
          if (dirname[0] == undefined) {
            dirFName = '.';
          }else{ 
            if (dirname[0].trim()==''){
              dirFName = '.';
            } else {
              dirFName =dirname [0];
            }
          }
          if (dirname[dirname.length-1] == undefined) {
            dirLName = '.';
          }else{ 
            if (dirname[dirname.length-1].trim()==''){
              dirLName = '.';
            } else {
              dirLName =dirname [dirname.length-1];
            }
          }


          dirData = [
            ...dirData,
            {
              address: {
                areaName: stakdata.area,
                city: stakdata.city,
                landmark: "",
                line1: stakdata.address1,
                line2: stakdata.address2,
                noOfMonthsAtThisAddr: 0,
                noOfYearsAtThisAddr: 0,
                ownedYN: "Y",
                pinCode: parseInt((stakdata.pin)),
                state: stakdata.state
              },
              dob: (moment(stakdata.dob).format('YYYY-MM-DD')),
              emailId: bEmail,
              firstName: dirFName,
              gender: stakdata.gender,
              middleName: "",
              mobileNo: stakdata.mobile,
              panNo: stakdata.PAN,
              lastName: dirLName,
              authSignYN: "Y",
              directorYN: "Y",
              shareHoldingPercentage:sharepercent,
              designation: "5",
              boardResolutionFile:null
            }
          ]
          console.log("Dir====> after loop ", dirData)
          return dirData;
        })
        return returnList;
      }
       Directory();


     console.log('here we finally ve the file as a ArrayBuffer : ',bStatement);
      var filContent;
      let splitfile;
      if (bOrgType == "PROPRIETORSHIP") {
          filContent =[];
      }else{
          if (uploadedfile != null){
            splitfile =uploadedfile.split(',');
            filContent = (splitfile[1]);
          }else{
            filContent =[];
          }
        }

        if (wrongcomptype == "N" && (bOrgType.toUpperCase())=="PRIVATE LIMITED COMPANY"){
          comptype = 52;
        }  

        if (wrongcomptype == "N" && (bOrgType.toUpperCase())=="LLP"){
          comptype = 53;
        }  
        if (wrongcomptype == "N" && (bOrgType.toUpperCase())=="PROPRIETORSHIP"){
          comptype = 54;
        }  
        if (wrongcomptype == "N" && (bOrgType.toUpperCase())=="PARTNERSHIP"){
          comptype = 55;
        }  
        if (wrongcomptype == "N" && (bOrgType.toUpperCase())=="MNC"){
          comptype = 56;
        }  
        if (wrongcomptype == "N" && (bOrgType.toUpperCase())=="PUBLIC LIMITED COMPANY"){
          comptype = 57;
        }  
        if (wrongcomptype == "N" && (bOrgType.toUpperCase())=="HUF"){
          comptype = 58;
        }  
        if (wrongcomptype == "N" && (bOrgType.toUpperCase())=="TRUST"){
          comptype = 59;
        }  
        if (wrongcomptype == "N" && (bOrgType.toUpperCase())=="SOCIETY"){
          comptype = 60;
        }  
        if (wrongcomptype == "N" && (bOrgType.toUpperCase())=="INDIVIDUAL"){
          comptype = 137;
        }  
        if (wrongcomptype == "N" && (bOrgType.toUpperCase())=="ONE PERSON COMPANY"){
          comptype = 138;
        }  
        if (wrongcomptype == "N" && (bOrgType.toUpperCase())=="LABOUR"){
          comptype = 139;
        }  
        
        if (wrongcomptype == "P"){
          //console.log("true");
          let myconfig = {
              method: 'get',
              maxBodyLength: Infinity,
              url: 'https://los.statuscapitals.com/apigateway/statusapi/GeneralMasters/getParamValues?paramType=COMTP',
    
              headers: { 
                'client-id': 'STATU0004200016', 
                'client-secret': 'QOetBBBHrU5jLGvSBE1QiuhVk65NhOEgt3a4qGcy32A=', 
                'Accept': 'application/json', 
                'Content-Type': 'application/json'
              }
            };
            axios.request(myconfig)
            .then((response) => {
              //console.log("comptype");
              //console.log(response.data);
              if (JSON.stringify(response.data.responseCode)==200){
                
                for (compcount=0; compcount < (response.data.data.length); compcount++) {
                  if (((response.data.data[compcount].paramValue).toUpperCase()) == (bOrgType.toUpperCase())){
                      comptype = response.data.data[compcount].paramCode;
                      console.log("comptype 2");
                      console.log(comptype);
                      setSelectedorgtype(comptype); 
                      console.log(Selectedorgtype);
                      
                      setwrongcomptype('N');              
                      el = document.getElementById('selectedcomptype');
                      el.value = comptype;
                      //console.log("inside loop");
                                    //console.log(comptype);
                      break;
                  }
                }
            }
            })
            .catch((error) => {
            });
        }  



      //comptype = document.getElementById('selectedcomptype').value;  

      const finaldata = {
        bankDetail: {
          ifscdetails: {
            city: bOrgCity,
            office: bOrgCity,
            district: bOrgCity,
            ifsc: bIFSC,
            micr: "",
            state: bOrgState,
            contact: bOrgMobile,
            branch: bOrgCity,
            address: bOrgCity,
            bank: bBank
          },
          accHldrName: bOrgName,
          accountNumber: bAccount,
          accountType: 'C',
          bankName: bBank,
          bankStmtPassword: bStatPassword,
          file: [
            {
              fileContent: filContent
            }
          ],
          ifscCode: bIFSC
        },
        billingAddressDto: {
          areaName: bBillAddress,
          city: bBillCity,
          landmark: "",
          line1: bBillLine1,
          line2: bBillLine2,
          noOfMonthsAtThisAddr: 12,
          noOfYearsAtThisAddr: 12,
          ownedYN: "Y",
          pinCode: bBillPin,
          state: bBillState
        },
        loanDetail: {
          appliedDate: moment(),
          appliedLimit: 0,
          buyerId: buyerid,
          productCode: "1"
          //productCode:"2w01"  
        },
        panDetails: {
          panno: bPAN,
          name: "",
          panid: bPAN,
          pantroid: bPAN,
          task: "fetch",
          verified: true,
          message: "done",
          upstreamname: "",
          panjson: ""
        },
        gstDetails: {
          gstlegalname: bGSTLegalName,
          gsttradename: bOrgName,
          gstin: bGSTN,
          gstregistrationdate: bGSTRegDate,
          members: gstMember,
          status: bGSTStatus,
          companytype: bOrgType,
          grosssale: bGSTSale,
          noofmonth: "12",
          natureOfBusiness:[bGSTOrgType],
          address:bOrgAddress
        },
        organizationDetail: {
          bussAddressDto: {
            areaName: bOrgAddress,
            city: bOrgCity,
            landmark: "",
            line1: bOrgLine1,
            line2: bOrgLine2,
            noOfMonthsAtThisAddr: 0,
            noOfYearsAtThisAddr: 0,
            ownedYN: "Y",
            pinCode: bOrgPin,
            state: bOrgState
          },
          incorporationDate: null,
          orgEmail: bEmail,
          orgGSTNo: bGSTN,
          orgLandline: bOrgMobile,
          orgMobile: bOrgMobile,
          orgName: bOrgName,
          orgPanNo: bPAN,
          orgType: comptype
          //orgType:'52'
        },
        shippingAddressDto: {
          areaName: bShipAddress,
          city: bShipCity,
          landmark: "",
          line1: bShipLine1,
          line2: bShipLine2,
          noOfMonthsAtThisAddr: 0,
          noOfYearsAtThisAddr: 0,
          ownedYN: "Y",
          pinCode: bShipPin,
          state: bShipState
        },
        rmdetails:{
          rmName: rmName,
          rmMobileNo: rmMobile,
          rmEmailId: rmMail
      },
        directorsDetailDto: dirData
      }


      const jsondata = {
        "bankDetail": {
            "ifscdetails": {
                "city": "ANANDPURA",
                "office": "ANANDPURA",
                "district": "ANANDPURA",
                "ifsc": "HDFC000204",
                "micr": ".",
                "state": "Gujarat",
                "contact": "7358498777",
                "branch": "ANANDPURA",
                "address": "ANANDPURA",
                "bank": "HDFC Bank"
            },
            "accHldrName": "VISHWAJIT YARN PRIVATE LIMITED ",
            "accountNumber": "502000279560",
            "accountType": "CC",
            "bankName": "HDFC Bank",
            "bankStmtPassword": "",
            "file": [
                {
                    "fileContent": filContent
                }
            ],
            "ifscCode": "HDFC000204"
        },
        "billingAddressDto": {
            "areaName": "1054/2, ANANDPURA, VEDA VILLAGE, MANASA TALUKA, Gandhinagar, Gujarat, 382845",
            "city": "ANANDPURA",
            "landmark": ".",
            "line1": ".",
            "line2": ".",
            "noOfMonthsAtThisAddr": 12,
            "noOfYearsAtThisAddr": 12,
            "ownedYN": "N",
            "pinCode": 382845,
            "state": "Gujarat"
        },
        "loanDetail": {
            "appliedDate": "2023-11-01T07:06:55.485Z",
            "appliedLimit": 0,
            "buyerId": 1151,
            "productCode": "1"
        },
        "panDetails": {
            "panno": "AAGCL0836M",
            "name": ".",
            "panid": "AAGCL0836M",
            "pantroid": "AAGCL0836M",
            "task": "fetch",
            "verified": true,
            "message": "done",
            "upstreamname": ".",
            "panjson": "."
        },
        "gstDetails": {
            "gstlegalname": "VISHWAJIT YARN PRIVATE LIMITED",
            "gsttradename": "VISHWAJIT YARN PRIVATE LIMITED ",
            "gstin": "27DXEPS5432M1Z1",
            "gstregistrationdate": "2017-07-01",
            "members": "",
            "status": "ACTIVE",
            "companytype": "PRIVATE LIMITED COMPANY",
            "grosssale": "987654321",
            "noofmonth": "12",
            "natureOfBusiness": [
                "PROPRIETORSHIP"
            ]
        },
        "organizationDetail": {
            "bussAddressDto": {
                "areaName": "1054/2, ANANDPURA, VEDA VILLAGE, MANASA TALUKA, Gandhinagar, Gujarat, 382845",
                "city": "ANANDPURA",
                "landmark": ".",
                "line1": ".",
                "line2": ".",
                "noOfMonthsAtThisAddr": 0,
                "noOfYearsAtThisAddr": 0,
                "ownedYN": "Y",
                "pinCode": 382845,
                "state": "Gujarat"
            },
            "electricityConsumerNo": ".",
            "electricityProvider": ".",
            "incorporationDate": "01-01-2023",
            "orgEmail": "vasudeo.patil@yarnbiz.com",
            "orgGSTNo": "27DXEPS5432M1Z1",
            "orgLandline": "7358498777",
            "orgMobile": "7358498777",
            "orgName": "VISHWAJIT YARN PRIVATE LIMITED ",
            "orgPanNo": "AAGCL0836M",
            "orgType": 55
        },
        "shippingAddressDto": {
            "areaName": "1054/2, ANANDPURA, VEDA VILLAGE, MANASA TALUKA, Gandhinagar, Gujarat, 382845",
            "city": "ANANDPURA",
            "landmark": ".",
            "line1": ".",
            "line2": ".",
            "noOfMonthsAtThisAddr": 0,
            "noOfYearsAtThisAddr": 0,
            "ownedYN": "Y",
            "pinCode": 382845,
            "state": "Gujarat"
        },
        "rmdetails": {
            "rmName": "Vasudev Patil",
            "rmMobileNo": 7358498777,
            "rmEmailId": "vasudeo.patil@yarnbiz.com"
        },
        "directorsDetailDto": [
            {
                "address": {
                    "areaName": "ANANDPURA",
                    "city": "Vesava",
                    "landmark": ".",
                    "line1": "1054/2",
                    "line2": ".",
                    "noOfMonthsAtThisAddr": 0,
                    "noOfYearsAtThisAddr": 0,
                    "ownedYN": "Y",
                    "pinCode": 400061,
                    "state": "Maharashtra"
                },
                "dob": "1974-06-14",
                "emailId": "vasudeo.patil@yarnbiz.com",
                "firstName": "Vasudeo",
                "gender": "M",
                "middleName": ".",
                "mobileNo": "7358498777",
                "panNo": "AXDPQ2950R",
                "lastName": "Patil",
                "authSignYN": "Y",
                "directorYN": "Y",
                "shareHoldingPercentage": "0",
                "designation": "5",
                "boardResolutionFile": null
            },
            {
                "address": {
                    "areaName": "ANANDPURA",
                    "city": "Vesava",
                    "landmark": ".",
                    "line1": "1054/2",
                    "line2": ".",
                    "noOfMonthsAtThisAddr": 0,
                    "noOfYearsAtThisAddr": 0,
                    "ownedYN": "Y",
                    "pinCode": 400061,
                    "state": "Maharashtra"
                },
                "dob": "1974-06-14",
                "emailId": "vasudeo.patil@yarnbiz.com",
                "firstName": "Vasudeo",
                "gender": ".",
                "middleName": ".",
                "mobileNo": "7358498777",
                "panNo": "AXDPQ2950R",
                "lastName": "Patil",
                "authSignYN": "Y",
                "directorYN": "Y",
                "shareHoldingPercentage": "0",
                "designation": "5",
                "boardResolutionFile": null
            }
        ]
    }
    
      
      console.log("Final Request Body :: ", finaldata);
      
      let dataurl = apibaseurl;
      if (qaenv) {
        dataurl = apiqaurl;
      }
      console.log(dataurl);
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        //url: 'https://yarnbizqa.4fin.in/apigateway/statusapi/yarnbiz/checkLimit',
        //url: 'https://los.statuscapitals.com/apigateway/statusapi/yarnbiz/checkLimit',
        url: dataurl + 'checkLimit',
        headers: {
          'client-id': 'STATU0004200016',
          'client-secret': 'QOetBBBHrU5jLGvSBE1QiuhVk65NhOEgt3a4qGcy32A=',
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data: finaldata
      };

      //console.log(data);
      //return false;
      //console.log(JSON.stringify(data));
      axios.request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          if (JSON.stringify(response.data.responseCode) == 200) {
            setlimitAppNo(JSON.stringify(response.data.data.limitApplicationNo));
            setisOTP(true);
            //alert("Details saved successfully, \n Please enter OTP received on your mobile!!");
            seterrorMessage('Details saved successfully, Please enter OTP received on your mobile!!'); 
            seterroropen(true);
            seterrorTimer(8000);
            seterrortype('success');
    

          } else {
            //alert(JSON.stringify(response.data.responseCode));
            //alert("Some issues in saving details, please retry!!");
            seterrorMessage('Sorry! Issue in saving details, please try after sometime');     
            seterroropen(true);
            seterrorTimer(8000);
            seterrortype('error');
    
           }
        })
        .catch((error) => {
          console.log(error);
          //alert("Please retry, we are facing some issue!!");
          seterrorMessage('Sorry! Issue in saving details, please try after sometime');     
          seterroropen(true);
          seterrorTimer(8000);
          seterrortype('error');
  
        });

  }

  const resendOTP = () => {

    let dataurl = apibaseurl;
    if (qaenv) {
      dataurl = apiqaurl;
    }

    let data = JSON.stringify({
      "leadNo": + limitAppNo,
      "mobile": "" + bOrgMobile + ""
    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      //url: 'https://yarnbizqa.4fin.in/apigateway/statusuatapi/yarnbiz/resendotp',
      //url: 'https://los.statuscapitals.com/apigateway/statusapi/yarnbiz/resendotp',
      url: dataurl + 'resendotp',
      headers: {
        'client-id': 'STATU0004200016',
        'client-secret': 'QOetBBBHrU5jLGvSBE1QiuhVk65NhOEgt3a4qGcy32A=',
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        if (JSON.stringify(response.data.responseCode) == 200) {
          //alert("OTP resent successfully, Please submit the same!!!")
          seterrorMessage('OTP resent successfully, Please enter the same!!!'); 
          seterroropen(true);
          seterrorTimer(8000);
          seterrortype('success');
  

        } else {
          //alert("Issue in sending OTP, please retry again!!!")
          seterrorMessage('Sorry! Issue in sending OTP, please try after sometime');     
          seterroropen(true);
          seterrorTimer(8000);
          seterrortype('error');
  
          
        }
      })
      .catch((error) => {
        console.log(error);
      });

  }

  const submitOTP = () => {
    //alert(document.getElementById('otp'));
    alert(qaenv);
    //alert(apibaseurl);
    let apiurl = "";
    if (qaenv){
      apiurl = apiqaurl;
    }else{
      apiurl = apibaseurl;
    }
    if (OTPvalue.length < 6 ){
      //document.getElementById('otp').length < 6) {
      //alert("6 digit OTP is required");
      seterrorMessage('Sorry! 6 digit OTP is required, please enter the received OTP again');     
      seterroropen(true);
      seterrorTimer(8000);
      seterrortype('error');

    } else {
      let data = JSON.stringify({
        "limitApplicationNo": + limitAppNo,
        "mobile": "" + bOrgMobile + "",
        "otp": "" + (document.getElementById('otp').value) + ""
      });

      alert(data);
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        //url: 'https://yarnbizqa.4fin.in/apigateway/statusapi/yarnbiz/validateotp',
        //url: 'https://los.statuscapitals.com/apigateway/statusapi/yarnbiz/validateotp',
        //url: apibaseurlap +'validateotp',
        url:apiurl + 'validateotp',
        headers: {
          'client-id': 'STATU0004200016',
          'client-secret': 'QOetBBBHrU5jLGvSBE1QiuhVk65NhOEgt3a4qGcy32A=',
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data: data
      };
      alert(config);
      axios.request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          //alert(JSON.stringify(response.data.responseCode));

          if (JSON.stringify(response.data.responseCode) == 200) {
            //let msg = "OTP submited successfully.\n\nSMS and email communication has been sent to you for:\n1. Bank statement verification\n2. GST verification and \n3. E KYC\nPlease wait for some time, "  


            //alert(msg);

            setisOTP(false);
            //setsavdetails(false);
            //const history = createBrowserHistory({forceRefresh:true});

            //history.push('/success');
          } else {
           // alert("Issue in OTP submission, please reenter correct OTP!!!")
            seterrorMessage('Issue in OTP submission, please reenter correct OTP!!!');     
            seterroropen(true);
            seterrorTimer(8000);
            seterrortype('error');
    
            }
        })
        .catch((error) => {
          console.log(error);
          //alert("Issue in OTP submission, please reenter correct OTP!!!")
          seterrorMessage('Issue in OTP submission, please reenter correct OTP!!!');     
          seterroropen(true);
          seterrorTimer(8000);
          seterrortype('error');
          });
    }
  }



  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }    
    seterroropen(false);
    setdisplaywait(false);
    seterrorMessage('');
    setbackopen(false);
    seterrortype('success');
    setsucessopen(false);
  };



  const handlebackClose = () => {
    setbackopen(false);
  };

  //call CREATE hook
/*   const { mutateAsync: createUser, isLoading: isCreatingUser } =
    useCreateUser();
  //call READ hook
  const {
    data: fetchedUsers = [],
    isError: isLoadingUsersError,
    isFetching: isFetchingUsers,
    isLoading: isLoadingUsers,
  } = useGetUsers();
  //call UPDATE hook
  const { mutateAsync: updateUser, isLoading: isUpdatingUser } =
    useUpdateUser();
  //call DELETE hook
  const { mutateAsync: deleteUser, isLoading: isDeletingUser } =
    useDeleteUser();

  //CREATE action
  const handleCreateUser = async ({ values, exitCreatingMode }) => {
    const newValidationErrors = validateUser(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    await createUser(values);
    exitCreatingMode();
  };
 */
  //UPDATE action
/*   const handleSaveUser = async ({ values, table }) => {
    const newValidationErrors = validateUser(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    await updateUser(values);
    table.setEditingRow(null); //exit editing mode
  };

  //DELETE action
  const openDeleteConfirmModal = (row) =>
    modals.openConfirmModal({
      title: 'Are you sure you want to delete this stakeholder?',
      children: (
        <Text>
          Are you sure you want to delete '{row.original.firstName}{' '}
          {row.original.lastName}'? This action cannot be undone.
        </Text>
      ),
      labels: { confirm: 'Delete', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onConfirm: () => deleteUser(row.original.id),
    });
 */
//   const table = useMantineReactTable({
//     columns,
//     data: fetchedUsers,
//     enableRowSelection:false,
//     enableColumnFilters:false,
//     enableColumnActions:false,
//     enablePagination:false,
//     enableTableFooter:false,
//     enableRowActions: true,
//     enableStickyHeader: true,
//     enableBottomToolbar:false,
//     mantineTableContainerProps: { sx: { maxHeight: '300px' } }, 
//     renderRowActionMenuItems: ({ row }) => (
//       <>
//         <Menu.Item onClick={() => console.info('Edit')}>Edit</Menu.Item>
//         <Menu.Item onClick={() => console.info('Delete')}>Delete</Menu.Item>
// {/*         <Menu.Item onClick={() => console.info('Edit')}>PAN VALIDATE</Menu.Item>
//         <Menu.Item onClick={() => console.info('Delete')}>BOARD</Menu.Item> */}
//       </>
//     ),
//     initialState: {
//       density: 'xs',
//       columnVisibility: {
//         id: false, //hide firstName column by default
//         //'mrt-row-expand': false, //hide row expand column by default
//       },
//     },
//     createDisplayMode: 'modal', //default ('row', and 'custom' are also available)
//     editDisplayMode: 'modal', //default ('row', 'cell', 'table', and 'custom' are also available)
//     enableEditing: true,
//     getRowId: (row) => row.id,
//     mantineToolbarAlertBannerProps: isLoadingUsersError
//       ? {
//           color: 'red',
//           children: 'Error loading data',
//         }
//       : undefined,
// /*     mantineTableContainerProps: {
//       sx: {
//         minHeight: '500px',
//       },
//     }, */
//     onCreatingRowCancel: () => setValidationErrors({}),
//     onCreatingRowSave: handleCreateUser,
//     onEditingRowCancel: () => setValidationErrors({}),
//     onEditingRowSave: handleSaveUser,
//     renderCreateRowModalContent: ({ table, row, internalEditComponents }) => (
//       <Stack>
//         <Title order={3}>New Stakeholder</Title>
//         {internalEditComponents}
//         <Flex justify="flex-end" mt="xl">
//           <MRT_EditActionButtons variant="text" table={table} row={row} />
//         </Flex>
//       </Stack>
//     ),
//     renderEditRowModalContent: ({ table, row, internalEditComponents }) => (
//       <Stack>
//         <Title order={3}>Edit User</Title>
//         {internalEditComponents}
//         <Flex justify="flex-end" mt="xl">
//           <MRT_EditActionButtons variant="text" table={table} row={row} />
//         </Flex>
//       </Stack>
//     ),
//     renderRowActions: ({ row, table }) => (
//       <Flex gap="md">
//         <Tooltip label="Edit">
//           <ActionIcon color = "teal" onClick={() => table.setEditingRow(row)}>
//             <IconEdit />
//           </ActionIcon>
//         </Tooltip>
//         <Tooltip label="Delete">
//           <ActionIcon color="red" onClick={() => openDeleteConfirmModal(row)}>
//             <IconTrash />
//           </ActionIcon>
//         </Tooltip>
// {/*         <Tooltip label="PAN">
//           <ActionIcon color="teal" onClick={() => table.setEditingRow(row)}>
//             <IconCheck />
//           </ActionIcon>
//         </Tooltip>
//         <Tooltip label="BOARD">
//           <ActionIcon color="teal" onClick={() => openDeleteConfirmModal(row)}>
//             <IconUserPin   />
//           </ActionIcon>
//         </Tooltip> */}
//       </Flex>
//     ),
//     renderTopToolbarCustomActions: ({ table }) => (
//       <Box sx={{ display: 'flex', gap: '16px', padding: '4px', verticalAlign:'middle'}}>
//         <Button title='' 
//             color="teal"
//             onClick={() => {
//             table.setCreatingRow(true); //simplest way to open the create row modal with no default values
//             //or you can pass in a row object to set default values with the `createRow` helper function
//             // table.setCreatingRow(
//             //   createRow(table, {
//             //     //optionally pass in default values for the new row, useful for nested data or other complex scenarios
//             //   }),
//             // );
//             }}
//           >
//           New Stakeholder
//         </Button>
//  {/*        <label
//           color="teal"

//           variant="filled"
//           style={{'fontSize':'0.8em'}}
//           >
          
//           Upload Board Resolution
//         </label>
//         <input type="file" id ='brdres' onChange={handleBoardFile} style={{'fontSize':'0.8em'}}/>  */}
// {/*         <label style={{    'display': 'inline-block', 'background': '#12b886', 'height':'2em','color':'#fff', 'border': '1px outset #ccc', 'borderRadius': '.3em','padding': '.1em 1em','margin': '.1em', 'verticalAlign':'middle','borderStyle': 'inset'}}>
//             Upload Board Resolution</label> */}

//       </Box>
   
//     ),
//        //customize built-in buttons in the top-right of top toolbar
//        renderToolbarInternalActions: ({ table }) => (
//         <Flex gap="xs" align="center">
//           {/* add custom button to print table  */}
// {/*           <ActionIcon
//             onClick={() => {
//               window.print();
//             }}
//           >
//             <IconPrinter />
//           </ActionIcon> */}
//           {/* along-side built-in buttons in whatever order you want them */}
// {/*           <MRT_ToggleDensePaddingButton table={table} />
//           <MRT_ToggleFullScreenButton table={table} /> */}
//         </Flex>
//       ),
//        //Adding a custom button to the bottom toolbar
// /*  renderBottomToolbarCustomActions: ({ table }) => (

//      <Button title='' 
//       //variant="contained"
//       color="teal"
//       //extract all selected rows from the table instance and do something with them
//       //={() => handleDownloadRows(table.getSelectedRowModel().rows)}
//     >
//       Upload Board Resolution
//     </Button> 
    
//   )  */
  
//       //renderBottomToolbarCustomActions: ({ table }) => (
// /*         <Button title='' 
//           //variant="contained"
//           //color="lightblue"
//           //extract all selected rows from the table instance and do something with them
//           //onClick={() => handleDownloadRows(table.getSelectedRowModel().rows)}
//         >
//           Save Details
//         </Button> */
// /*               <Box sx={{ display: 'flex', gap: '16px', padding: '4px' }}>
//               <Button title='' 
//                 color="teal" */
//                 //onClick={handleExportData(fetchedUsers.rows)}
// /*                 onClick={SaveDetails()} */
//                 //onClick={() => {
//                   //alert(rows.values);
//                   //alert(table.data.columns);
//                   //alert(fetchedUsers.length);

//                   //alert(fetchedUsers.row[1].firstName);
//  /*                 alert(table.getRow[1].firstName);
//                    alert(table.sortedgetSelectedRowModel.length); */
//                   //alert(table.row.original.firstName);
//                   //alert('Save details');
                  
                
//                 //}}
// /*                 variant="filled"
//               >
//                 Save Details
//               </Button> */

//     /*         </Box> */
//       //),
//     state: {
//       isLoading: isLoadingUsers,
//       isSaving: isCreatingUser || isUpdatingUser || isDeletingUser,
//       showAlertBanner: isLoadingUsersError,
//       showProgressBars: isFetchingUsers,
//     },
//   });

  
    
/*     const direarr = (bDirectors.split(','));
    if ( direarr.length > 0  ){
      let namearr;
      var valueToPush = {}
      let isrecordadded = false;
      var diritr;
      //console.log(isrecordadded);
      console.log(direarr.length);
      let firstarr = direarr[0];
      console.log(firstarr);
      if (userAdded){
      for (diritr=0;diritr< direarr.lenght; diritr++) {
      //direarr.forEach(function(item, index){
        console.log(diritr);
        console.log(direarr[diritr]);
        firstarr = direarr[diritr];
        console.log(firstarr);
        console.log(isrecordadded);
        namearr = (firstarr.split(' '));
        console.log(namearr[0]);
        console.log(namearr.length);
        
        
        //console.log(namearr[namearr.length])
        if (namearr[0].trim() !=''){
          valueToPush.firstName= namearr[0].trim();
          isrecordadded = true;
        }
        if ((namearr[namearr.length-1]).trim() !=''){
           valueToPush.firstName= namearr[namearr.length-1].trim();
           isrecordadded = true;
        }
        console.log(isrecordadded);
        if (isrecordadded){
          valueToPush.id = diritr + 1;
          valueToPush.director='Yes';
          valueToPush.authlimit=0;
          valueToPush.shareper=0;
          User.push(valueToPush);
        }
      }
    }
    setuserAdded(false);  
  } */

  return (
    <>
      {/* <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <CardTable />
        </div>
        <div className="w-full mb-12 px-4">
          <CardTable color="dark" />
        </div>
      </div> */}
      {/* <div className="relative bg-lightBlue-600 md:pt-32 pb-32 pt-12">
        <div className="px-4 md:px-10 mx-auto w-full">
          <div>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="Organization Details"
                  statTitle={bOrgName}
                  statArrow=""
                  statPercent="Org Type"
                  statPercentColor="text-emerald-500"
                  statDescripiron={bOrgType}
                  statIconName="far fa-chart-bar"
                  statIconColor="bg-red-500"
                />
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="PAYMENTS"
                  statTitle="2,356"
                  statArrow="down"
                  statPercent="3.48"
                  statPercentColor="text-red-500"
                  statDescripiron="Since last week"
                  statIconName="fas fa-chart-pie"
                  statIconColor="bg-orange-500"
                />
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="DUE TRANSACTIONS"
                  statTitle="924"
                  statArrow="down"
                  statPercent="1.10"
                  statPercentColor="text-orange-500"
                  statDescripiron="Since yesterday"
                  statIconName="fas fa-users"
                  statIconColor="bg-pink-500"
                />
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="PERFORMANCE"
                  statTitle="49,65%"
                  statArrow="up"
                  statPercent="12"
                  statPercentColor="text-emerald-500"
                  statDescripiron="Since last month"
                  statIconName="fas fa-percent"
                  statIconColor="bg-lightBlue-500"
                />
              </div>
            </div>
          </div>
        </div>
      </div> */}

<Dialog
        //fullScreen
        //maxWidth = ''
      //   contentStyle={{
      //     width: '100%',
      //     maxWidth: 'md',
      //  }}
      //  
      fullWidth
      maxWidth='xl'
      //maxhight={400}
        //open={sucessopen}
        open={false}

        onClose={handleClose}
        //TransitionComponent={Transition}
      >
        <DialogContent
        style={{height:'1000px'}}> 
            <Typography sx={{ ml: 1, flex: 0 }} variant="h9" component="div">
              {bOrgName} : Registration status
            </Typography>
        
        {/* <AppBar sx={{ position: 'relative' }}>
          <Toolbar> */}
            {/* <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton> */}
            {/* <Button autoFocus color="inherit" onClick={handleClose}>
              save
            </Button> */}

          {/* </Toolbar>
        </AppBar> */}
        {/* <Stepper activeStep={0} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Stepper activeStep={0} alternativeLabel>
    <Step>
      <StepLabel>Register your name</StepLabel>
    </Step>
    <Step>
      <StepLabel>Register your email</StepLabel>
    </Step>
    <Step completed>
      <StepLabel>Click on Finish</StepLabel>
    </Step>
  </Stepper> */}
  <br></br>
  {/* <Stepper nonLinear activeStep={currentActiveStep} sx={{ boxShadow:0, backgroundColor: "rgba(0,0,0,0.1)", padding: 2, '& .MuiStepIcon-root': { color: 'green' }  }}>
  Steps go here 
</Stepper>
*/}
        {/* <Stepper nonLinear activeStep={currentActiveStep} alternativeLabel  sx={{ '& .MuiStepIcon-active': { color: 'green' }, '& .MuiStepIcon-completed': { color: 'green' } }}>
        <Step key={0} completed={1==1}>
        <StepLabel>Provide GST Consent</StepLabel>
        <Typography
              align="center"
              // sx={TypographySx} // For solution 2
            >
            </Typography> 
  
        </Step>
        <Step key={1}>
          <StepLabel>Receive GST Analysis</StepLabel>
          <Typography
              align="center"
              // sx={TypographySx} // For solution 2
            >
      
            </Typography>
        </Step>
        <Step key={2}>
            <Typography
              align="center"
              // sx={TypographySx} // For solution 2
            >
            </Typography>
          <StepLabel>Limit Setup</StepLabel>
        </Step>
        <Step key={3}>
        <StepLabel>Sanction Letter Acceptance</StepLabel>
        <Typography
              align="center"
              // sx={TypographySx} // For solution 2
            >
            <Link target = '_blank' underline="always" href="https://statuscapitals.com/sanction/Mjk=" rel="noreferrer" align='center'>Sanction Letter Acceptance</Link>
            </Typography>
          
        </Step>
        <Step key={4}>
        <Typography
              align="center"
              // sx={TypographySx} // For solution 2
            >
            <Link target = '_blank' underline="always" href="https://statuscapitals.com/fldInvst/Mjk=" rel="noreferrer" align='center'>Sanction Letter Acceptance</Link>
            </Typography>
          <StepLabel
           //icon={<WarningIcon color={red500} />}
           style={{color: 'red'}}
          > Processing Fees Payment</StepLabel>
        </Step>
        <Step key={5}>            
          <StepLabel>E-NACH UPload</StepLabel>
        </Step>
        <Step key={6}>
          <StepLabel>E-Sign</StepLabel>
        </Step>
      </Stepper> */}

      <Grid container spacing={2} sx={{ width: '100%' }}>
      {/* First row */}
      {/* <Grid item xs={4}>
      <Card className={classes.card} style={{borderStyle: `solid`,
          borderWidth: 3,
          borderColor: `teal`,justifyContent:'center'}}>
            <CardActionArea>
            <Filter1 color='action' />
            <CardContent>
            <Repeat color='secondary'/>
<Typography gutterBottom variant="body2" component="h2">
Provide GST Consent
</Typography>
<Link target = '_blank' underline="always" href="https://statuscapitals.com/fldInvst/Mjk=" rel="noreferrer" align='center'>GST Consent</Link>

</CardContent>

                
            </CardActionArea>
          </Card>        
        </Grid> */}
       <Grid item xs={1.75}>
        <Stack 
          direction='column' 
          alignItems="center" 
          spacing={2} 
          sx={{ 
            border: 1, 
            borderRadius:'2.5%',          
            borderWidth: 3,
            borderColor: `teal` 
          }}
        >
          <div>            
            <Filter1 color='action' />
          </div>
          <div style={{height: '3em'}}>
            {GSTCONSENTStatus&&<CircularProgress
              value={40}
              sx={{color:'blueviolet'}} 
              style={{
              //width: "100px",
              //height: "100px",
              borderRadius: "100%",
              boxShadow: "inset 0 0 0px 4px success",
              }}
              thickness={4}
            />}

            {!GSTCONSENTStatus&&<DoneAll color='success' />}
          </div>
          <div>
            <Typography gutterBottom variant="body2" component="h2">
              Provide GST Consent
            </Typography>
          </div>
          <div style={{height: '1.3em'}}>
            {GSTCONSENTStatus&& <Link target = '_blank' underline="always" href={GSTCONSENTURL} rel="noreferrer" align='center'>GST Consent</Link>}
            
          </div>
        </Stack>
       </Grid>
       <Grid item xs={1.75}>
        <Stack 
          direction='column' 
          alignItems="center" 
          spacing={2} 
          sx={{ 
            border: 1, 
            borderRadius:'2.5%',          
            borderWidth: 3,
            borderColor: `teal` 
          }}
        >
          <div>            
            <Filter2Rounded color='action' />
          </div>
          <div style={{height: '3em'}}>
            {bankStatementStatus&&<CircularProgress
              value={40}
              sx={{color:'blueviolet'}} 
              style={{
              //width: "100px",
              //height: "100px",
              borderRadius: "100%",
              boxShadow: "inset 0 0 0px 4px success",
              }}
              thickness={4}
            />}

            {!bankStatementStatus&&<DoneAll color='success'/>}
          </div>
          <div>
            <Typography gutterBottom variant="body2" component="h2">
              Bank Statement
            </Typography>
          </div>
          <div style={{height: '1.3em'}}>
            {false&&<Link target = '_blank' underline="always" href={'/'} rel="noreferrer" align='center'>GST Consent</Link>}
          </div>
        </Stack>
       </Grid>

       <Grid item xs={1.75}>
        <Stack 
          direction='column' 
          alignItems="center" 
          spacing={2} 
          sx={{ 
            border: 1, 
            borderRadius:'2.5%',          
            borderWidth: 3,
            borderColor: `teal` 
          }}
        >
          <div>            
            <Filter3 color='action' />
          </div>
          <div style={{height: '3em'}}>
            {processingStatus&&<CircularProgress
              value={40}
              sx={{color:'blueviolet'}} 
              style={{
              //width: "100px",
              //height: "100px",
              borderRadius: "100%",
              boxShadow: "inset 0 0 0px 4px success",
              }}
              thickness={4}
            />}

            {!processingStatus&&<DoneAll color='success'/>}
            {GSTCONSENTStatus&&'Please Wait'}
          </div>
          <div>
            <Typography gutterBottom variant="body2" component="h2">
              Processing Fee Payment
            </Typography>
          </div>
          <div style={{height: '1.3em'}}>
            {processingStatus&&<Link target = '_blank' underline="always" href={processingfeeurl} rel="noreferrer" align='center'>Pay Processing Fees</Link>}
          </div>
        </Stack>
       </Grid>
       <Grid item xs={1.75}>
        <Stack 
          direction='column' 
          alignItems="center" 
          spacing={2} 
          sx={{ 
            border: 1, 
            borderRadius:'2.5%',          
            borderWidth: 3,
            borderColor: `teal` 
          }}
        >
          <div>            
            <Filter4 color='action' />
          </div>
          <div style={{height: '3em'}}>
            {sanctionletterstatus&&<CircularProgress
              value={40}
              sx={{color:'blueviolet'}} 
              style={{
              //width: "100px",
              //height: "100px",
              borderRadius: "100%",
              boxShadow: "inset 0 0 0px 4px success",
              }}
              thickness={4}
            />}

            {!sanctionletterstatus&&<DoneAll color='success'/>}
          </div>
          <div>
            <Typography gutterBottom variant="body2" component="h2">
              Sanction Letter Acceptance
            </Typography>
          </div>
          <div style={{height: '1.3em'}}>
            {sanctionletterstatus&&<Link target = '_blank' underline="always" href={sanctionletterurl} rel="noreferrer" align='center'>Sanction Letter</Link>}
          </div>
        </Stack>
       </Grid>
       <Grid item xs={1.75}>
        <Stack 
          direction='column' 
          alignItems="center" 
          spacing={2} 
          sx={{ 
            border: 1, 
            borderRadius:'2.5%',          
            borderWidth: 3,
            borderColor: `teal` 
          }}
        >
          <div>            
            <Filter5 color='action' />
          </div>
          <div style={{height: '3em'}}>
            {true&&<CircularProgress
              value={40}
              sx={{color:'blueviolet'}} 
              style={{
              //width: "100px",
              //height: "100px",
              borderRadius: "100%",
              boxShadow: "inset 0 0 0px 4px success",
              }}
              thickness={4}
            />}

            {false&&<DoneAll color='success'/>}
          </div>
          <div>
            <Typography gutterBottom variant="body2" component="h2">
              NACH Form Upload
            </Typography>
          </div>
          <div style={{height: '1.3em'}}>
            <Link target = '_blank' underline="always" href={nachurl} rel="noreferrer" align='center'>NACH form upload</Link>
          </div>
        </Stack>
       </Grid>       
       <Grid item xs={1.75}>
        <Stack 
          direction='column' 
          alignItems="center" 
          spacing={2} 
          sx={{ 
            border: 1, 
            borderRadius:'2.5%',          
            borderWidth: 3,
            borderColor: `teal` 
          }}
        >
          <div>            
            <Filter6Outlined color='action' />
          </div>
          <div style={{height: '3em'}}>
            {true&&<CircularProgress
              value={40}
              sx={{color:'blueviolet'}} 
              style={{
              //width: "100px",
              //height: "100px",
              borderRadius: "100%",
              boxShadow: "inset 0 0 0px 4px success",
              }}
              thickness={4}
            />}

            {false&&<DoneAll color='success'/>}
          </div>
          <div>
            <Typography gutterBottom variant="body2" component="h2">
              E-sign
            </Typography>
          </div>
          <div style={{height: '1.3em'}}>
            {false&&<Link target = '_blank' underline="always" href={'/'} rel="noreferrer" align='center'>E-sign</Link>}
          </div>
        </Stack>
       </Grid>
    </Grid>            


{/* 
            setESIGNStatus(response.data.data[4].status);
            setLMSLIMITSETUPStatus(response.data.data[5].status);
            setGSTCONSENTURL(response.data.data[2].links[0].url);
            setorgname(response.data.data[6].orgName);
            setsanctionletterstatus(response.data.data[7].status);
            setprocessingStatus(response.data.data[8].status);
            setLimitSetupType(response.data.data[9].activityName);
            setfinalLimit(response.data.data[9].finalLimit); */}
        {/* <List>
          <ListItemButton>
            <ListItemText primary="Phone ringtone" secondary="Titania" />
          </ListItemButton>
          <Divider />
          <ListItemButton>
            <ListItemText
              primary="Default notification ringtone"
              secondary="Tethys"
            />
          </ListItemButton>
        </List> */}
     </DialogContent>
      </Dialog>




      {/* <Modal
        open={sucessopen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography>
        </Box>
      </Modal> */}





      <Snackbar autoHideDuration={errorTimer} anchorOrigin={{ vertical: "top", horizontal: "right" }} open={displaywait}  onClose={handleClose}>
        <Alert

          severity='info'
          variant="filled"
          sx={{ width: '100%'}}
          >
          {errorMessage}  
        </Alert>
      </Snackbar>

      <Snackbar autoHideDuration={errorTimer} anchorOrigin={{ vertical: "top", horizontal: "right" }} open={erroropen}  onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={errortype}
          variant="filled"
          sx={{ width: '100%' }}
          >
          {errorMessage}  
        </Alert>
      </Snackbar>
      {/* <Snackbar open={NACHError} autoHideDuration={5000}>
        <Alert
          onClose={handleClose}
          severity='error'
          variant="filled"
          sx={{ width: '100%' }}
          >
          {errorMessage}  
        </Alert>
      </Snackbar> */}


      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backopen}
      >
        <CircularProgress
          value={40}
          //color='error'
          sx={{color:"white"}} 
          style={{
          width: "100px",
          height: "100px",
          borderRadius: "100%",
          boxShadow: "inset 0 0 0px 11px warning",
          //backgroundColor: "blue",
          }}
          thickness={4}
        />
      </Backdrop> 

  <Modal opened={opened} onClose={close} size="auto" title="Guidelines to Register on Status Capitals Pvt Ltd">
  <b>A. GST</b>
  <br></br>
  <font size='2'>
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;OTP based GST consent:
  <br></br>
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1. Your will receive the mail to provide the GST consent
  <br></br>
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2. Click the URL to provide the GST consent
  <br></br>
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3. Enter GST user name
  <br></br>
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4. Your will receive the OTP for GST consent on mobile no registered on GST
  <br></br>
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5. Enter OTP to complete the GST consent
  <br></br>
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Note. To provide consent using OTP, you must have to enable GST API for your account
  <br></br>
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Watch this video to</font> <Anchor href="https://www.youtube.com/watch?v=W6HLhP2Yyec" target="_blank" underline="always">
  Enable GST API for your account</Anchor>
  
  <br></br>
  <b>B. Bank</b><br></br>
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1. Download PDF copy of bank statement of "" for last six(6) months.
  <br></br>
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2. You will need a password of downloaded bank statement during submission
  <br></br>
  
  <br></br>
  

  <b>C. E-KYC</b><br></br>
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1. You will receive SMS for E-KYC from DIGIO.
  <br></br>
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2. You will need to submit Director's Aadhar number along with OTP received on Directors Mobile(Registered with Aadhar)
  <br></br>
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3. Submit the OTP
  <br></br>

  <b>D. Sanction Acceptance</b><br></br>
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4. Submit the OTP
  <br></br>

  <b>E. Processing Fees Payment</b><br></br>
  <b>F. E-Nach Form</b><br></br>
  <b>G. E-Sign</b><br></br>

In case you are unable to start registration now you can restart using current url. <br></br>
<CopyButton value={window.location.href}>
      {({ copied, copy }) => (
        <Button title=''  color={copied ? 'teal' : 'blue'} onClick={copy}>
          {copied ? 'Copied url' : 'Copy url'}
        </Button>
      )}
    </CopyButton><br></br>
 Please save this copied url. This url is also shared with you by mail.
 </Modal>
  <div className={
      "relative flex flex-col min-w-0 break-words w-full pl-12 mb-2 shadow-lg rounded " +
      (color === "light" ? "bg-white" : "bg-lightBlue-900")
    }
  >
    <table style={{ width: "100%"}} >
      <thead>
        <tr >
          <th style={{ width: "2%"}} >
          </th>
          <th style={{ width: "40%"}} >
            <img style={{ width: "7%", height: "7%", marginLeft:0 }} src={require("assets/img/logo.png").default} alt="Status Capitals Pvt Ltd" />  
          </th>
          <th style={{ width: "40%"}} >                        
            
                <h3
              className={
              "font-semibold text-lg " +
              (color === "light" ? "text-blueGray-700" : "text-white")
              }
            >
              Buyer Onboarding {(qaenv)?"- UAT Enviornment":""}
            </h3>
          </th>
          <th style={{ width: "55%", align: "right"}} >
            <Button title=''   color='teal' onClick={open}>Guidelines for Registration</Button> 
          </th>
        </tr>
      </thead>
    </table>
  </div>          
      
  <div
    className={
      "relative flex flex-col min-w-0 break-words w-full mb-2 shadow-lg rounded " +
      (color === "light1" ? "bg-white" : "bg-lightBlue-900")
    }
  >

    <div className="flex flex-wrap mt-4">
      <div className="w-full mb-12 px-4">
        <div
          className={
            "relative flex flex-col min-w-0 break-words w-full mb-2 shadow-lg rounded " +
            (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
          }
        >

          <div className="block w-full overflow-x-auto">
            <table className="items-center w-full bg-transparent border-collapse">

              <tbody>
                <tr>
                  <th className="border-t-0 px-0 align-middle border-l-0 uppercase border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                    <span
                      className={
                        "ml-3 font-bold " +
                        +(color === "light" ? "text-blueGray-600" : "text-white")
                      }
                    >
                      Organization Name
                    </span>
                  </th>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {bOrgName}
                  </td>
                  <th className="border-t-0 px-0 align-middle border-l-0 uppercase border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                    <span
                      className={
                        "ml-3 font-bold " +
                        +(color === "light" ? "text-blueGray-600" : "text-white")
                      }
                    >
                      Organization Type
                    </span>
                  </th>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {bOrgType}
                  </td>
                  <th className="border-t-0 px-0 align-middle border-l-0 uppercase border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                    <span
                      className={
                        "ml-3 font-bold " +
                        +(color === "light" ? "text-blueGray-600" : "text-white")
                      }
                    >
                      GST NO
                    </span>
                  </th>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {bGSTN}
                  </td>
                </tr>
                <tr>
                  <th className="border-t-0 px-0 align-middle uppercase border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                    <span
                      className={
                        "ml-3 font-bold " +
                        +(color === "light" ? "text-blueGray-600" : "text-white")
                      }
                    >
                      Address
                    </span>
                  </th>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" colSpan={5} >
                    {bOrgAddress} 
                  </td>
                </tr>
                <tr>
                  <th className="border-t-0 px-0 align-middle border-l-0 uppercase border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                    <span
                      className={
                        "ml-3 font-bold " +
                        +(color === "light" ? "text-blueGray-600" : "text-white")
                      }
                    >
                      City
                    </span>
                  </th>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {bOrgCity}
                  </td>
                  <th className="border-t-0 px-0 align-middle border-l-0 uppercase border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                    <span
                      className={
                        "ml-3 font-bold " +
                        +(color === "light" ? "text-blueGray-600" : "text-white")
                      }
                    >
                      State
                    </span>
                  </th>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {bOrgState}
                  </td>
                  <th className="border-t-0 px-0 align-middle border-l-0 uppercase border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                    <span
                      className={
                        "ml-3 font-bold " +
                        +(color === "light" ? "text-blueGray-600" : "text-white")
                      }
                    >
                      Pin Code
                    </span>
                  </th>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {bOrgPin}
                  </td>
                </tr>
                <tr>
                  <th className="border-t-0 px-0 align-middle uppercase border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                    <span
                      className={
                        "ml-3 font-bold " +
                        +(color === "light" ? "text-blueGray-600" : "text-white")
                      }
                    >
                      Name
                    </span>
                  </th>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {bFirstName} {bLastName} 
                  </td>
                                    <th className="border-t-0 px-0 align-middle uppercase border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                    <span
                      className={
                        "ml-3 font-bold " +
                        +(color === "light" ? "text-blueGray-600" : "text-white")
                      }
                    >
                      Email
                    </span>
                  </th>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" >
                    {bEmail}                     
                  </td>
                  <th className="border-t-0 px-0 align-middle uppercase border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                    <span
                      className={
                        "ml-3 font-bold " +
                        +(color === "light" ? "text-blueGray-600" : "text-white")
                      }
                    >
                      Mobile No
                    </span>
                  </th>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {bOrgMobile}
                  </td>
                </tr>
                <tr>
                  <th className="border-t-0 px-0 align-middle border-l-0 uppercase border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                    <span
                      className={
                        "ml-3 font-bold " +
                        +(color === "light" ? "text-blueGray-600" : "text-white")
                      }
                    >
                      Beneficiary Name
                    </span>
                  </th>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {bBName}
                  </td>
                  <th className="border-t-0 px-0 align-middle border-l-0 uppercase border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                    <span
                      className={
                        "ml-3 font-bold " +
                        +(color === "light" ? "text-blueGray-600" : "text-white")
                      }
                    >
                      Bank and IFSC
                    </span>
                  </th>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {bBank}, {bIFSC}
                  </td>
                  <th className="border-t-0 px-0 align-middle border-l-0 uppercase border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                    <span
                      className={
                        "ml-3 font-bold " +
                        +(color === "light" ? "text-blueGray-600" : "text-white")
                      }
                    >
                      Account No
                    </span>
                  </th>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {bAccount}   
                  </td>
                </tr>
                <tr>
                  <th className="border-t-0 px-0 align-middle uppercase border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                    <span
                      className={
                        "ml-3 font-bold " +
                        +(color === "light" ? "text-blueGray-600" : "text-white")
                      }
                    >
                      Billing Address	
                    </span>
                  </th>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" colSpan={5} >
                    {bBillAddress} 
                  </td>
                </tr>
                <tr>
                  <th className="border-t-0 px-0 align-middle border-l-0 uppercase border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                    <span
                      className={
                        "ml-3 font-bold " +
                        +(color === "light" ? "text-blueGray-600" : "text-white")
                      }
                    >
                      City
                    </span>
                  </th>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {bBillCity}
                  </td>
                  <th className="border-t-0 px-0 align-middle border-l-0 uppercase border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                    <span
                      className={
                        "ml-3 font-bold " +
                        +(color === "light" ? "text-blueGray-600" : "text-white")
                      }
                    >
                      State
                    </span>
                  </th>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {bBillState}
                  </td>
                  <th className="border-t-0 px-0 align-middle border-l-0 uppercase border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                    <span
                      className={
                        "ml-3 font-bold " +
                        +(color === "light" ? "text-blueGray-600" : "text-white")
                      }
                    >
                      Pin Code
                    </span>
                  </th>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {bBillPin}
                  </td>
                </tr>
                <tr>
                  <th className="border-t-0 px-0 align-middle uppercase border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                    <span
                      className={
                        "ml-3 font-bold " +
                        +(color === "light" ? "text-blueGray-600" : "text-white")
                      }
                    >
                      RM Name
                    </span>
                  </th>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {rmName} 
                  </td>
                    <th className="border-t-0 px-0 align-middle uppercase border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                    <span
                      className={
                        "ml-3 font-bold " +
                        +(color === "light" ? "text-blueGray-600" : "text-white")
                      }
                    >
                      RM Email
                    </span>
                  </th>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" >
                    {rmMail}                     
                  </td>
                  <th className="border-t-0 px-0 align-middle uppercase border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                    <span
                      className={
                        "ml-3 font-bold " +
                        +(color === "light" ? "text-blueGray-600" : "text-white")
                      }
                    >
                      RM Mobile No
                    </span>
                  </th>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {rmMobile}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
   {/*     </div>
    </div>
   </div>
  <div
    className={
      "relative flex flex-col min-w-0 break-words w-full mb-2 shadow-lg rounded " +
      (color === "light1" ? "bg-white" : "bg-lightBlue-900")
    }
  > 

    <div className="flex flex-wrap mt-4">
      <div className="w-full mb-12 px-4">*/}
        <div
          className={
            "relative flex flex-col min-w-0 break-words w-full mb-0 shadow-lg rounded " +
            (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
          }
        >

          <div className="block w-full overflow-x-auto">
          <table className="items-center w-full bg-transparent border-collapse">
              <tbody>
                <tr>
                  <th style={{ color: 'red' }} className="border-t-0 px-0 align-middle border-l-0 uppercase border-r-0 text-xs whitespace-nowrap p-1 text-left flex items-center">
                    <span
                      className={
                        "ml-3 font-bold color-red" +
                        +(color === "light" ? "text-red-600" : "text-white")
                      }
                    >
                      Select a 'Director' from the list and enter the PAN for the selected Director; following details for this PAN/AADHAR will be used for eKYC and eSign.
                    </span>
                  </th>
                </tr>
              </tbody>
            </table>
            <table className="items-center w-full bg-transparent border-collapse">
              <tbody>
                <tr>
                  <th style={{ color: 'red' }} className="border-t-0 px-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-1 text-left flex items-center">
                    <span
                      className={
                        "ml-3 font-bold color-red" +
                        +(color === "light" ? "text-red-600" : "text-white")
                      }
                    >
                      <Box sx={{ flexGrow: 1, sborder: '2px solid grey',marginTop:'5px' }}>
                        <Grid container spacing={1}>
                          <Grid item xs={2.5}>
                            <TextField
                                fullWidth
                                size='small'
                                variant="outlined"
                                placeholder='Select Director'
                                value={selecteddirector}
                                onChange={(e) => handleDirectorValidation(e)}
                                select
                                required
                                label="Directors"
                                inputProps={{style: {fontSize: 12}}} // font size of input text
                                InputLabelProps={{style: {fontSize: 12}}}
                                //error={!validdirector}
                                
                                // helperText={validdirector?"✓":"Select Director"}
                                //helperText={validdirector?"✓"+validdirector:"Select Director"+validdirector}
                              >
                                {DirectorNames.map((dirnames, key) => (
                                <MenuItem key={key} value={dirnames}>{dirnames}</MenuItem>
                                ))}

                            </TextField>
                          </Grid>
                          <Grid item xs={1.2}>
                            <TextField
                              fullWidth
                              label="PAN"
                              placeholder='Enter PAN'
                              variant="outlined"
                              value={directorPan}
                              pattern='([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}'
                              maxLength={10}
                              inputProps={{style: {fontSize: 12}}} // font size of input text
                              InputLabelProps={{style: {fontSize: 12}}}
                              required
                              onChange={(e) => handleValidation(e)}
                              size='small'          
                              error={!valid}
                              //helperText={valid?"✓"+valid:"PAN is required/Invalid"+valid}
                              helperText={valid?"✓":"PAN is required/Invalid"}
                            />
                          </Grid>
                          
                          <Grid item xs={2.5}>
                            <TextField
                              fullWidth
                              label="Name"
                              //placeholder='Enter Mobiel No'
                              variant="outlined"
                              value={directorName}
                              //pattern='(([0-9]){10}'
                              inputProps={{style: {fontSize: 12}}} // font size of input text
                              InputLabelProps={{style: {fontSize: 12}}}
                              
                              //inputProps={{ maxLength: 10 }}
                              // commented due to changes in director details collection 21 Nov 24
                              //readOnly
                              //onChange={(e) => handleMobileValidation(e)}
                              size='small'          
                              //error={!validMobile}
                              //helperText={validMobile?"✓"+validMobile:"Mobile is required/Invalid"+validMobile}
                              //helperText={validMobile?"✓":"Mobile is required/Invalid"}
                            />
                          </Grid>

                          <Grid item xs={1}>
                          <TextField
                                fullWidth
                                size='small'
                                variant="outlined"
                                placeholder='Select Gender'
                                value={directorGender}
                                onChange={(e) => handleDirectorGender(e)}
                                select
                                required
                                label="Gender"
                                inputProps={{style: {fontSize: 8}}} // font size of input text
                                InputLabelProps={{style: {fontSize: 10}}}
                                //error={!validdirector}
                                
                                // helperText={validdirector?"✓":"Select Director"}
                                //helperText={validdirector?"✓"+validdirector:"Select Director"+validdirector}
                              >
                              <MenuItem key={0} value="S" inputProps={{style: {fontSize: 8}}}>
                              
                                Select Gender
                              </MenuItem>
                              <MenuItem key={1} value="M">
                                Male
                              </MenuItem>
                              <MenuItem key={2} value="F">
                                Female
                              </MenuItem>
                              <MenuItem key={3} value="O">
                                Other
                              </MenuItem>  
                            </TextField>

                          </Grid>

                          <Grid item xs={1.1}>
                            <TextField
                              fullWidth
                              label="Mobile"
                              //placeholder='Enter Mobiel No'
                              variant="outlined"
                              value={directorMobile}
                              inputProps={{style: {fontSize: 12}}} // font size of input text
                              InputLabelProps={{style: {fontSize: 12}}}
                              //pattern='(([0-9]){10}'
                              
                              //inputProps={{ maxLength: 10 }}
                              //readOnly
                              onChange={(e) => handleMobileValidation(e)}
                              size='small'          
                              error={!validMobile}
                              //helperText={validMobile?"✓"+validMobile:"Mobile is required/Invalid"+validMobile}
                              helperText={validMobile?"✓":"Mobile is required/Invalid"}
                            />
                          </Grid>
                          <Grid item xs={1.3}>
                            <TextField
                              fullWidth
                              label="Masked Aadhaar"
                              //placeholder='Enter Mobiel No'
                              variant="outlined"
                              value={directorAadhaar}
                              inputProps={{style: {fontSize: 12}}} // font size of input text
                              InputLabelProps={{style: {fontSize: 12}}}
                              //pattern='(([0-9]){10}'
                              
                              //inputProps={{ maxLength: 10 }}
                              //readOnly
                              onChange={(e) => handleAadhaarValidation(e)}
                              size='small'          
                              //error={!validMobile}
                              //helperText={validMobile?"✓"+validMobile:"Mobile is required/Invalid"+validMobile}
                              helperText={validAadhaar?"✓":"Aadhaar is required/Invalid"}
                            />
                          </Grid>

                          <Grid item xs={1}>
                            <TextField
                              fullWidth
                              label="Date of Birth"
                              //placeholder='Enter Director Date of Birth'
                              variant="outlined"
                              value={directorDOB}
                              
                              InputLabelProps={{style: {fontSize: 12}}}
                              type='date'
                              readOnly
                              onChange={(e) => handleDirectorDOBValidation(e)}
                              size='small' 

                              inputProps={{
                              style: {fontSize: 12},  
                              maxLength:10,
                              min: (moment(new Date()).subtract(100,"year").format('yyyy-MM-DD')),
                              max: (moment(new Date()).format('YYYY-MM-DD'))
                              }}        
                              error={!validDOB}
                              //helperText={validDOB?"✓"+validDOB:"Date of birth is required/Invalid"+validDOB}
                              helperText={validDOB?"✓":"Date of birth is required/Invalid"}
                            />

                          </Grid>
                        </Grid>
                      </Box>
                    {/* </span>
                  </th>
                </tr>
                </tbody>
            </table>
            <table className="items-center w-full bg-transparent border-collapse">
              <tbody>
                <tr>
                  <th style={{ color: 'red' }} className="border-t-0 px-0 align-middle border-l-0 uppercase border-r-0 text-xs whitespace-nowrap p-1 text-left flex items-center">
                    <span
                      className={
                        "ml-3 font-bold color-red" +
                        +(color === "light" ? "text-red-600" : "text-white")
                      }
                    > */}
                      <Box sx={{ flexGrow: 1, sborder: '2px solid grey',marginTop:'5px' }}>
                        <Grid container spacing={1}>
                          <Grid item xs={2.4}>
                            <TextField
                              label="Full Address"
                              //placeholder='Enter Address Line1'
                              variant="outlined"
                              value={fullAddress}
                              inputProps={{style: {fontSize: 12}}} // font size of input text
                              InputLabelProps={{style: {fontSize: 12}}}                              
                              //inputProps={{ maxLength: 30 }}
                              readOnly
                              //onChange={(e) => handleAddressLine1(e)}
                              size='small'          
                              //error={!validAddressLine1}
                              fullWidth
                              //helperText={validAddressLine1?"✓":"Address Line 1 is required/Invalid"}
                              //helperText={validAddressLine1?"✓"+validAddressLine1:"Address Line 1 is required/Invalid"+validAddressLine1}
                            />
                          </Grid>
                          <Grid item xs={2.4}>
                            <TextField
                              label="Address Line1"
                              //placeholder='Enter Address Line1'
                              variant="outlined"
                              value={addressLine1}
                              inputProps={{style: {fontSize: 12}}} // font size of input text
                              InputLabelProps={{style: {fontSize: 12}}}
                              //inputProps={{ maxLength: 30 }}
                              //required
                              onChange={(e) => handleAddressLine1(e)}
                              size='small'          
                              error={!validAddressLine1}
                              fullWidth
                              //helperText={validAddressLine1?"✓":"Address Line 1 is required/Invalid"}
                              helperText={validAddressLine1?"✓"+validAddressLine1:"Address Line 1 is required"}
                            />
                          </Grid>
                          <Grid item xs={2.4}>
                            <TextField
                              fullWidth
                              label="Address Line2"
                              //placeholder='Enter Address Line2'
                              variant="outlined"
                              value={addressLine2}
                              inputProps={{style: {fontSize: 12}}} // font size of input text
                              InputLabelProps={{style: {fontSize: 12}}}
                              //inputProps={{ maxLength: 30 }}
                              required
                              onChange={(e) => handleAddressLine2(e)}
                              size='small'          
                              error={!validAddressLine2}
                              //helperText={validAddressLine2?"✓"+validAddressLine2:"Address Line 2 is required/Invalid"+validAddressLine2}
                              helperText={validAddressLine2?"✓":"Address Line 2 is required"}
                            />
                          </Grid>
                          <Grid item xs={1.3}>
                            <TextField
                              //fullWidth
                              size='small'
                              variant="outlined"
                              //placeholder='Select Residential Area'
                              value={directorArea}
                              inputProps={{style: {fontSize: 12}}} // font size of input text
                              InputLabelProps={{style: {fontSize: 12}}}
                              onChange={(e) => handleDirectorArea(e)}
                              //select
                              required
                              label="Residential Area"
                              error={!validDirectorArea}
                              //helperText={validDirectorArea?"✓"+validDirectorArea:"Select Residential Area"+validDirectorArea}
                              helperText={validDirectorArea?"✓":"Residential Area is required"}
                            >
                              {/* {dir1Cities.map((city, key) => (
                              <MenuItem key={key} value={city}>{city}</MenuItem>
                              ))} */}
                            </TextField>

                          </Grid>
                          <Grid item xs={1.3}>
                            <TextField
                              fullWidth
                              label="Residence City"
                              variant="outlined"
                              value={directorCity}
                              onChange={(e) => handleDirectorCity(e)}
                              inputProps={{style: {fontSize: 12}}} // font size of input text
                              InputLabelProps={{style: {fontSize: 12}}}
                              required
                              error={!validDirectorCity}
                              //InputProps={{
                              //readOnly: true,
                              //}}
                              size='small'          
                              //helperText={"Residence City"}
                              helperText={validDirectorCity?"✓":"Residence City is required"}
                            />

                          </Grid>
                          <Grid item xs={1.3}>
                            <TextField
                              fullWidth
                              label="Residence State"
                              variant="outlined"
                              value={directorState}
                              inputProps={{style: {fontSize: 12}}} // font size of input text
                              InputLabelProps={{style: {fontSize: 12}}}
                              //InputProps={{
                              //readOnly: true,
                              //}}
                              size='small'          
                              required
                              onChange={(e) => handleDirectorState(e)}
                              error={!validDirectorState}
                              //helperText={"Residence State"}
                              helperText={validDirectorState?"✓":"Residence State is required"}
                            />

                          </Grid>
                          <Grid item xs={0.8}>
                            <TextField
                              fullWidth
                              label="PIN Code"
                              placeholder='Enter PIN Code'
                              variant="outlined"
                              value={directorPinCode}
                              inputProps={{style: {fontSize: 12}}} // font size of input text
                              InputLabelProps={{style: {fontSize: 12}}}
                              //pattern='(([0-9]){6}'
                              //inputProps={{ maxLength: 6 }}
                              //readOnly
                              type='text'
                              onChange={(e) => handlePincodeValidation(e)}
                              size='small'          
                              error={!validPincode}
                              //helperText={validPincode?"✓"+validPincode:"PIN Code is required/Invalid"+validPincode}
                              helperText={validPincode?"✓":"PIN Code is required/Invalid"}
                            />
                          </Grid>

                        </Grid>
                      </Box>                    
                    </span>
                  </th>
                </tr>
                </tbody>
            </table>
            <table className="items-center w-full bg-transparent border-collapse">
              <tbody>     
                           
                <tr>
                  <th style={{ color: 'red' }} className="border-t-0 px-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-1 text-left flex items-center">
                    <span
                      className={
                        "ml-3 font-bold color-red" +
                        +(color === "light" ? "text-red-600" : "text-white")
                      }
                    >
                      {(bOrgType != "PROPRIETORSHIP") &&
                      <Box sx={{ flexGrow: 1, sborder: '2px solid grey'}}>
                        Upload any 12 months bank statement(only PDF format) between <u>{moment().subtract(14, 'months').startOf('month').format('MMM-YYYY')}</u> and <u>{moment().startOf('month').format('MMM-YYYY')}</u> for <u>{bBName}</u> and account no <u>{bAccount} ({bBank})</u>

                        <Grid container spacing={1}>
                          <Grid item xs={2.25}>
                            <TextField
                              fullWidth
                              label="Bank Statement Password"
                              placeholder='Enter Bank Statement Password'
                              variant="outlined"
                              value={bStatPassword}
                              //inputProps={{ maxLength: 50}}
                              required
                              inputProps={{
                                maxLength:50, 
                                fonsize:12,
                              }}
                              InputLabelProps={{style: {fontSize: 12}}}  
                              //style={{ border: 'none' }}
                              type='password'
                              onChange={(e) => handlebankpassword(e)}
                              size='small'          
                              error={!validbankpassword}
                              //helperText={validbankpassword?"✓"+validbankpassword:"Bank statement password is required"+validbankpassword}
                              helperText={validbankpassword?"✓":"Bank statement password is required"}
                            />
                          </Grid>
                          <Grid item xs={3.0}>
                            <TextField
                              fullWidth
                              placeholder='Upload bank statement'
                              variant="outlined"
                              id='bnkstatement'
                              type='file'
                              required
                              onChange={(e) => handleFileChange(e)}
                              inputProps={{style: {fontSize: 12}}} // font size of input text
                              InputLabelProps={{style: {fontSize: 12}}}  
                              size='small'
                              //helperText={<b>Upload any 12 months bank statement(only PDF format) between <u>{moment().subtract(14, 'months').startOf('month').format('MMM-YYYY')}</u> and <u>{moment().startOf('month').format('MMM-YYYY')}</u> for <u>{bBName}</u> and account no <u>{bAccount}</u></b>}
                            />
                          </Grid>
                        {/* </Grid>
                        <Grid container spacing={1} justifyContent='center' marginTop='5px'>  */}
                        <Grid item xs={2}>
                          <Button title='' 
                            //style={{ height: "40px" }}
                            size="sm"
                            color='teal'
                            disabled={!(validdirector && validGender && valid && validMobile && validDOB && validPincode && validAddressLine1 && validDirectorArea && validbankpassword && validbankstatement && validDirectorCity && validDirectorState) }
                            onClick={() => {
                              SaveDetails();
                            }}
                            >
                            Get OTP to Save Details
                          </Button>
                        </Grid>
                        <Grid item xs={0.05}>
                        </Grid>

                        <Grid item xs={1.5}>
                          <TextField
                              fullWidth
                              label='OTP'
                              placeholder='Enter six digit OTP'
                              variant="outlined"
                              value={OTPvalue}
                              id='otp'
                              //inputProps={{style: {fontSize: 12}}} // font size of input text
                              InputLabelProps={{style: {fontSize: 12}}}  
                              type='number'
                              inputProps={{
                                maxLength:6, 
                                max:999999, 
                                min:111111,
                                fonsize:12,
                              }}
                              error={!validOTPValue}
                              helperText={validOTPValue?"✓":"OTP is required/Invalid"}
                                required
                              onChange={(e) => handleOTPChange(e)}
                              size='small'
                            />
                        </Grid>
                        <Grid item xs={0.05}>
                        </Grid>
                        <Grid item xs={1.25}>
                            <Button title='' 
                              //style={{ height: "40px" }}
                              size="sm"
                              color='teal'
                              disabled={!isOTP}
                              onClick={() => {
                                submitOTP();
                              }}
                              >
                              Submit OTP
                            </Button>

                        </Grid>
                        {/* <Grid item xs={0.05}>
                        </Grid> */}
                        <Grid item xs={1}>
                          <Button title='' 
                            //style={{ height: "40px" }}
                            size="sm"
                            color='teal'
                            disabled={!isOTP}
                            onClick={() => {
                              resendOTP();
                            }}
                            >
                            Resend OTP
                          </Button>
                          <input type="hidden" id="selectedcomptype"></input>

                        </Grid>
                      </Grid>
                      </Box>
                      }
                      {(bOrgType === "PROPRIETORSHIP") &&
                      <Box sx={{ flexGrow: 1, sborder: '2px solid grey'}}>
                        <Grid container spacing={1}>
                        {/* </Grid>
                        <Grid container spacing={1} justifyContent='center' marginTop='5px'>  */}
                        <Grid item xs={4}>
                          <Button title='' 
                            //style={{ height: "40px" }}
                            size="sm"
                            color='teal'
                            disabled={!(validdirector && validGender && valid && validMobile && validDOB && validPincode && validAddressLine1 && validDirectorArea) }
                            onClick={() => {
                              SaveDetails();
                            }}
                            >
                            Get OTP to Save Details
                          </Button>
                        </Grid>
                        <Grid item xs={0.05}>
                        </Grid>

                        <Grid item xs={2.75}>
                          <TextField
                              fullWidth
                              label='OTP'
                              placeholder='Enter six digit OTP'
                              variant="outlined"
                              value={OTPvalue}
                              id='otp'
                              //inputProps={{style: {fontSize: 12}}} // font size of input text
                              InputLabelProps={{style: {fontSize: 12}}}  
                              type='number'
                              inputProps={{
                                maxLength:6, 
                                max:999999, 
                                min:111111,
                                fonsize:12,
                              }}
                              error={!validOTPValue}
                              helperText={validOTPValue?"✓":"OTP is required/Invalid"}
                                required
                              onChange={(e) => handleOTPChange(e)}
                              size='small'
                            />
                        </Grid>
                        <Grid item xs={0.05}>
                        </Grid>
                        <Grid item xs={2.5}>
                            <Button title='' 
                              //style={{ height: "40px" }}
                              size="sm"
                              color='teal'
                              //disabled={!isOTP}
                              onClick={() => {
                                submitOTP();
                              }}
                              >
                              Submit OTP
                            </Button>

                        </Grid>
                        {/* <Grid item xs={0.05}>
                        </Grid> */}
                        <Grid item xs={1}>
                          <Button title='' 
                            //style={{ height: "40px" }}
                            size="sm"
                            color='teal'
                            disabled={!isOTP}
                            onClick={() => {
                              resendOTP();
                            }}
                            >
                            Resend OTP
                          </Button>
                          <input type="hidden" id="selectedcomptype"></input>

                        </Grid>
                      </Grid>
                      </Box>
                      }
                    {/* </span>
                  </th>
                </tr>
               </tbody>
            </table>
            <table className="items-center w-full bg-transparent border-collapse">
              <tbody>                
                <tr>
                  <th style={{ color: 'red' }} className="border-t-0 px-0 align-middle border-l-0 uppercase border-r-0 text-xs whitespace-nowrap p-1 text-left flex items-center">
                    <span
                      className={
                        "ml-3 font-bold color-red" +
                        +(color === "light" ? "text-red-600" : "text-white")
                      }
                    > */}


                    </span>
                  </th>
                </tr>
              </tbody>
            </table>
      
          </div>
        </div>
      </div>
    </div>
  </div>



{/* <table width="100%">
  <tbody>
    <tr>
      <td width="85%" align='left'> <font size="4"><b>Buyer Details</b></font>  */}
      {/*<div style="float: center; text-align: center;">this is right</div>
       <font size="4"><b>Buyer Details</b></font> */}
      {/* </td> */}
      {/* <td width="30%" style={{
            alignItems: 'right'
        }}><Button title=''  onClick={open}>Registration Instructions</Button></td> */}
      {/* <td width="15%" align='right'> <Button title=''   color='teal' onClick={open}>Guidelines for Registration</Button>     */}
         {/* <div style="float: right; text-align: right; "> */}
      {/* </td>
    </tr>
  </tbody>
</table> */}

        {/* <table>
          <tbody>
          <tr>
              <td width="100%" colSpan={7}></td>
            </tr> */}
            {/* <tr>
              <td colSpan={6}><b>Organization Details</b></td>
            </tr> */}
          {/* <tr>
            <td width="10%"><b>Organization Name</b></td>
            <td width="22%">{bOrgName}</td>
            <td width="10%"><b>Organization Type</b></td>
            <td colspan="3" width="68%">{bOrgType}</td> */}
            {/* <td width="16%"><b>Incorporation Date</b> */}
            {/* <select
                    name="tmporgtype"
                    id='tmporgtype'  
                    onChange={e => handleorgtype(e)}
                    value={Selectedorgtype}
                    style={{'fontSize':'0.8em', 'border':'none'}} 
                    //style={{'fontSize':'0.8em', 'border':'none', 'border-bottom': '1px solid', 'height':'1' }} 
                    >
                      <option value="52">Pvt Ltd</option>
                      <option value="53">LLP</option>
                      <option value="54">Proprietorship</option>
                      <option value="55">Partnership</option>
                      <option value="56">MNC</option>
                      <option value="57">Public ltd</option>
                      <option value="58">HUF</option>
                      <option value="59">Trust</option>
                      <option value="60">Society</option>
                      <option value="137">Individual</option>
                      <option value="138">One Person Company</option>
                      <option value="139">labour</option>
                  </select> */}
            
            
{/*         </td>
            <td width="16%">{moment(bIncorpoDate,'DD-MMM-YYYY')}</td> 
            {bIncorpoDate} </td>*/}

          {/* </tr>
          <tr>
            <td width="10%"><b>Address</b></td>
            <td colspan = "5">{bOrgAddress}</td> */}
            {/* <td width="16%"><b>Address Line1</b></td>
            <td width="16%">{bOrgLine1}</td>
            <td width="16%"><b>Address Line2</b></td>
            <td width="16%">{bOrgLine2}</td> */}
          {/* </tr>
          <tr>
            
            <td width="10%"><b>City</b></td>
            <td width="22%">{bOrgCity}</td>
            <td width="10%"><b>State</b></td>
            <td width="28%">{bOrgState}</td>

            <td width="10%"><b>Pin Code</b></td>
            <td width="16%">{bOrgPin}</td>
          </tr>
          <tr>
          <td width="10%"></td>
            <td width="84%" colSpan={6}></td> */}
{/*             <td width="16%"><b>Nature of Business</b></td>
            <td width="84%" colSpan={6}>{bGSTOrgType}</td>
 */}          
 {/* </tr>
           <tr>

              <td width="10%"><b>GST No.</b></td>
              <td width="22%">{bGSTN}</td>
              <td width="10%"><b>GST Status</b></td>
              <td width="28%">{bGSTStatus}</td>
              
              <td width="10%"><b>PAN No.</b></td>
              <td width="16%">{bPAN}</td>
            </tr>
            <tr>
              <td width="10%"><b>Name</b></td>
              <td width="22%">{bFirstName} {bLastName}</td>
              <td width="10%"></td>
              <td width="28%"></td>
              <td width="10%"></td>
              <td width="16%"></td>
            </tr>
            <tr>
              <td width="10%"><b>Email</b></td>
              <td width="22%">{bEmail}</td>
              <td width="10%"><b>Mobile</b></td>
               <td colspan="3">{bOrgMobile}</td>    */}
               {/* <td width="10%"><b>Contact Person Mobile</b></td> */}
               {/* <td width="16%">{bBillMobile}</td>    */}
            {/* </tr>
          </tbody>
        </table> */}
        {/* <table>
          <tbody>
            <tr>
              <td width="100%" colSpan={7}></td>
            </tr> */}
            {/* <tr>
              <td colSpan={6}><b>Bank Details</b></td>
            </tr> */}
            {/* <tr>
              <td width="10%"><b>Beneficiary Name</b></td>
              <td width="22%">{bBName}</td>
              <td width="10%"><b>Bank and IFSC</b></td>
              <td width="28%">{bBank}, {bIFSC}</td>
              <td width="10%"><b>Account No</b></td>
              <td width="16%">{bAccount}</td>
            </tr> */}
            {/* <tr>
              <td width="16%"><b>IFSC</b></td>
              <td width="16%">{bIFSC}</td>
              
              <td width="16%"></td>
              <td width="16%"></td>
            </tr> */}
          {/* </tbody>  
        </table> */}
{/*         <table>
          <tbody>
            <tr>
              <td width="100%" colSpan={7}></td>
            </tr>
            <tr>
              <td colSpan={6}><b>Shipping Details</b></td>
            </tr>
            <tr>
              <td width="16%"><b>Address</b></td>
              <td width="16%">{bShipAddress}</td>
              <td width="16%"><b>Address Line1</b></td>
              <td width="16%">{bShipLine1}</td>
              <td width="16%"><b>Address Line2</b></td>
              <td width="16%">{bShipLine2}</td>
            </tr>
            <tr>
              <td width="16%"><b>City</b></td>
              <td width="16%">{bShipCity}</td>
              <td width="16%"><b>State</b></td>
              <td width="16%">{bShipState}</td>
              <td width="16%"><b>Pin Code</b></td>
              <td width="16%">{bShipPin}</td>
            </tr>
          </tbody>
        </table>
 */}
         {/* <table>
          <tbody>
            <tr>
              <td width="100%" colSpan={7}></td>
            </tr>
            {/* <tr> */}
              {/* <td colSpan={6}><b>Billing Details</b></td>
            </tr> */}
            {/* <tr>

              <td width="10%"><b>Billing Address</b></td>
              <td colspan = "5" >{bBillAddress}</td> */}
              {/* <td width="16%"><b>Address Line1</b></td> */}
              {/* <td width="16%">{bBillLine1}</td>
              <td width="16%"><b>Address Line2</b></td>
              <td width="16%">{bBillLine2}</td> */}
            {/* </tr>
            <tr>              
              <td width="10%"><b>City</b></td>
              <td width="22%">{bBillCity}</td>
              <td width="10%"><b>State</b></td>
              <td width="28%">{bBillState}</td>
              <td width="10%"><b>Pin Code</b></td>
              <td width="16%">{bBillPin}</td>
            </tr> */} 
            {/* <tr>
              <td width="10%"><b>Mobile</b></td>
              <td width="90%" colSpan={5}>{bBillMobile}</td>
            </tr> 
        //   </tbody>
        // </table>
        {/* <span className="p-float-label">
    <Calendar inputId="birth_date" value={date} onChange={(e) => setDate(e.value)} />
    <label htmlFor="birth_date">Birth Date</label>
</span> */}
        {/* // <table>
        //   <tbody>
        //     <tr>
        //       <td width="100%" colSpan={7}></td>
        //     </tr> */}
            {/* <tr>
              <td colSpan={6}><b>RM Details</b></td>
            </tr> 
            // <tr>*/}
              {/* <td width="16%"><b>RM Name</b></td>
              <td width="16%">Vasudeo Patil</td>
              <td width="16%"><b>RM Mobile</b></td>
              <td width="16%">7358498777</td>
              <td width="16%"><b>RM Email</b></td>
              <td width="16%">vasudeo.patil@yarnbiz.com</td> */}
        {/* //       <td width="10%"><b>RM Name</b></td>
        //       <td width="22%">{rmName}</td>
        //       <td width="10%"><b>RM Mobile</b></td>
        //       <td width="28%">{rmMobile}</td>
        //       <td width="10%"><b>RM Email</b></td>
        //       <td width="16%">{rmMail}</td>
        //     </tr>

        //   </tbody> 
        // </table>*/}
        {/* <table>
          <tbody>
            <tr>
              <td width="100%" colSpan={7}></td>
            </tr>

            <tr>
              <td colSpan={6} ><font color='red'><b>Select a 'Director' from the list and enter the PAN for the selected Director; following details for this PAN/AADHAR will be used for eKYC and eSign.</b></font> </td>
            </tr>
          </tbody>
        </table>  */}
        {/* <Box sx={{ flexGrow: 1, sborder: '2px solid grey',marginTop:'5px' }}>
          <Grid container spacing={1}>
            <Grid item xs={2.5}>
              <TextField
                  fullWidth
                  size='small'
                  variant="outlined"
                  placeholder='Select Director'
                  value={selecteddirector}
                  onChange={(e) => handleDirectorValidation(e)}
                  select
                  required
                  label="Directors"
                  //error={!validdirector}
                  
                  // helperText={validdirector?"✓":"Select Director"}
                  //helperText={validdirector?"✓"+validdirector:"Select Director"+validdirector}
                >
                  {DirectorNames.map((dirnames, key) => (
                  <MenuItem key={key} value={dirnames}>{dirnames}</MenuItem>
                  ))}

              </TextField>
            </Grid>
            <Grid item xs={1.4}>
              <TextField
                fullWidth
                size='small'
                variant="outlined"
                placeholder='Select Gender'
                value={selectedGender}
                onChange={(e) => handleGenderValidation(e)}
                select
                inputProps={{width:"25px"}}
                required
                label="Gender"
                error={!validGender}
                helperText={validGender?"✓":"Select Gender"}
                //helperText={validGender?"✓"+validGender:"Select Gender"+validGender}
            >
                <MenuItem key={0} value="S">
                  Select Gender
                </MenuItem>
                <MenuItem key={1} value="M">
                  Male
                </MenuItem>
                <MenuItem key={2} value="F">
                  Female
                </MenuItem>
                <MenuItem key={3} value="O">
                  Other
                </MenuItem>

              </TextField>
            </Grid>
            <Grid item xs={1.5}>
              <TextField
                fullWidth
                label="PAN"
                placeholder='Enter PAN'
                variant="outlined"
                value={directorPan}
                pattern='([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}'
                maxLength={10}
                required
                onChange={(e) => handleValidation(e)}
                size='small'          
                error={!valid}
                //helperText={valid?"✓"+valid:"PAN is required/Invalid"+valid}
                helperText={valid?"✓":"PAN is required/Invalid"}
              />
            </Grid>
            <Grid item xs={1.5}>
              <TextField
                fullWidth
                label="Mobile"
                placeholder='Enter Mobiel No'
                variant="outlined"
                value={directorMobile}
                pattern='(([0-9]){10}'
                inputProps={{ maxLength: 10 }}
                required
                onChange={(e) => handleMobileValidation(e)}
                size='small'          
                error={!validMobile}
                //helperText={validMobile?"✓"+validMobile:"Mobile is required/Invalid"+validMobile}
                helperText={validMobile?"✓":"Mobile is required/Invalid"}
              />
            </Grid>
            <Grid item xs={1.8}>
              <TextField
                fullWidth
                label="Date of Birth"
                placeholder='Enter Director Date of Birth'
                variant="outlined"
                value={directorDOB}
                type='date'
                required
                onChange={(e) => handleDirectorDOBValidation(e)}
                size='small'  
                inputProps={{
                maxLength:10,
                min: (moment(new Date()).subtract(100,"year").format('yyyy-MM-DD')),
                max: (moment(new Date()).format('YYYY-MM-DD'))
                }}        
                error={!validDOB}
                //helperText={validDOB?"✓"+validDOB:"Date of birth is required/Invalid"+validDOB}
                helperText={validDOB?"✓":"Date of birth is required/Invalid"}
              />

            </Grid>
            <Grid item xs={1.5}>
              <TextField
                fullWidth
                label="PIN Code"
                placeholder='Enter PIN Code'
                variant="outlined"
                value={directorPinCode}
                pattern='(([0-9]){6}'
                inputProps={{ maxLength: 6 }}
                required
                type='text'
                onChange={(e) => handlePincodeValidation(e)}
                size='small'          
                error={!validPincode}
                //helperText={validPincode?"✓"+validPincode:"PIN Code is required/Invalid"+validPincode}
                helperText={validPincode?"✓":"PIN Code is required/Invalid"}
              />

            </Grid>
            <Grid item xs={1.5}>
              <Button title='' 
                size="md"
                color='teal'
                disabled={(!validPincode)}
                onClick={() => {
                  getPin({directorPinCode}, 1);
                }}
                >
                Get Pin Code Details
              </Button>

            </Grid>
          </Grid>
        </Box>
        <hr/>
        <Box sx={{ flexGrow: 1, sborder: '2px solid grey',marginTop:'5px' }}>
          <Grid container spacing={1}>
            <Grid item xs={2.4}>
              <TextField
                label="Address Line1"
                placeholder='Enter Address Line1'
                variant="outlined"
                value={addressLine1}
                inputProps={{ maxLength: 30 }}
                required
                onChange={(e) => handleAddressLine1(e)}
                size='small'          
                error={!validAddressLine1}
                fullWidth
                helperText={validAddressLine1?"✓":"Address Line 1 is required/Invalid"}
                //helperText={validAddressLine1?"✓"+validAddressLine1:"Address Line 1 is required/Invalid"+validAddressLine1}
              />
            </Grid>
            <Grid item xs={2.4}>
              <TextField
                fullWidth
                label="Address Line2"
                placeholder='Enter Address Line2'
                variant="outlined"
                value={addressLine2}
                inputProps={{ maxLength: 30 }}
                required
                onChange={(e) => handleAddressLine2(e)}
                size='small'          
                error={!validAddressLine2}
                //helperText={validAddressLine2?"✓"+validAddressLine2:"Address Line 2 is required/Invalid"+validAddressLine2}
                helperText={validAddressLine2?"✓":"Address Line 2 is required/Invalid"}
              />
            </Grid>
            <Grid item xs={2.4}>
              <TextField
                fullWidth
                size='small'
                variant="outlined"
                placeholder='Select Residential Area'
                value={directorArea}
                onChange={(e) => handleDirectorArea(e)}
                select
                required
                label="Residential Area"
                error={!validDirectorArea}
                //helperText={validDirectorArea?"✓"+validDirectorArea:"Select Residential Area"+validDirectorArea}
                helperText={validDirectorArea?"✓":"Select Residential Area"}
              >
                {dir1Cities.map((city, key) => (
                <MenuItem key={key} value={city}>{city}</MenuItem>
                ))}
              </TextField>

            </Grid>
            <Grid item xs={2.4}>
              <TextField
                fullWidth
                label="Residence City"
                variant="outlined"
                value={directorCity}
                InputProps={{
                readOnly: true,
                }}
                size='small'          
                helperText={"Residence City"}
              />

            </Grid>
            <Grid item xs={2.4}>
              <TextField
                fullWidth
                label="Residence State"
                variant="outlined"
                value={directorState}
                InputProps={{
                readOnly: true,
                }}
                size='small'          
                helperText={"Residence State"}
              />

            </Grid>
          </Grid>
        </Box> */}

        {/* <Box sx={{ flexGrow: 1, sborder: '2px solid grey',marginTop:'5px' }}>
          <Grid container spacing={1}> */}
            {/* <Grid item xs={2.5}>
              <TextField
                  fullWidth
                  size='small'
                  variant="outlined"
                  placeholder='Select Director'
                  value={selecteddirector}
                  onChange={(e) => handleDirectorValidation(e)}
                  select
                  required
                  label="Directors"
                  //error={!validdirector}
                  
                  // helperText={validdirector?"✓":"Select Director"}
                  //helperText={validdirector?"✓"+validdirector:"Select Director"+validdirector}
                >
                  {DirectorNames.map((dirnames, key) => (
                  <MenuItem key={key} value={dirnames}>{dirnames}</MenuItem>
                  ))}

              </TextField>
            </Grid> */}
            {/* <Grid item xs={1.2}>
              <TextField
                fullWidth
                label="PAN"
                placeholder='Enter PAN'
                variant="outlined"
                value={directorPan}
                pattern='([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}'
                maxLength={10}
                required
                onChange={(e) => handleValidation(e)}
                size='small'          
                error={!valid}
                //helperText={valid?"✓"+valid:"PAN is required/Invalid"+valid}
                helperText={valid?"✓":"PAN is required/Invalid"}
              />
            </Grid> */}
            {/* <Grid item xs={1.35}>
              <Button title='' 
                size="md"
                color='teal'
                disabled={(!valid)}
                onClick={() => {
                  //getPin({directorPinCode}, 1);
                  getPAN({directorPan});
                }}
                >
                Get PAN Details
              </Button>

            </Grid>
            <Grid item xs={2.5}>
              <TextField
                fullWidth
                label="Name"
                //placeholder='Enter Mobiel No'
                variant="outlined"
                value={directorName}
                //pattern='(([0-9]){10}'
                
                //inputProps={{ maxLength: 10 }}
                readOnly
                //onChange={(e) => handleMobileValidation(e)}
                size='small'          
                //error={!validMobile}
                //helperText={validMobile?"✓"+validMobile:"Mobile is required/Invalid"+validMobile}
                //helperText={validMobile?"✓":"Mobile is required/Invalid"}
              />
            </Grid> */}

            {/* <Grid item xs={1}>
              <TextField
                fullWidth
                size='small'
                variant="outlined"
                //placeholder='Select Gender'
                value={directorGender}
                readOnly
                //onChange={(e) => handleGenderValidation(e)}
                //select
                //inputProps={{width:"25px"}}
                //required
                label="Gender"
                //error={!validGender}
                //helperText={validGender?"✓":"Select Gender"}
                //helperText={validGender?"✓"+validGender:"Select Gender"+validGender}
            >
                <MenuItem key={0} value="S">
                  Select Gender
                </MenuItem>
                <MenuItem key={1} value="M">
                  Male
                </MenuItem>
                <MenuItem key={2} value="F">
                  Female
                </MenuItem>
                <MenuItem key={3} value="O">
                  Other
                </MenuItem> 

              </TextField>
            </Grid> */}

            {/* <Grid item xs={1.1}>
              <TextField
                fullWidth
                label="Mobile"
                //placeholder='Enter Mobiel No'
                variant="outlined"
                value={directorMobile}
                //pattern='(([0-9]){10}'
                
                //inputProps={{ maxLength: 10 }}
                readOnly
                //onChange={(e) => handleMobileValidation(e)}
                size='small'          
                //error={!validMobile}
                //helperText={validMobile?"✓"+validMobile:"Mobile is required/Invalid"+validMobile}
                //helperText={validMobile?"✓":"Mobile is required/Invalid"}
              />
            </Grid> */}
            {/* <Grid item xs={1.3}>
              <TextField
                fullWidth
                label="Masked Aadhaar"
                //placeholder='Enter Mobiel No'
                variant="outlined"
                value={directoraadhar}
                //pattern='(([0-9]){10}'
                
                //inputProps={{ maxLength: 10 }}
                readOnly
                //onChange={(e) => handleMobileValidation(e)}
                size='small'          
                //error={!validMobile}
                //helperText={validMobile?"✓"+validMobile:"Mobile is required/Invalid"+validMobile}
                //helperText={validMobile?"✓":"Mobile is required/Invalid"}
              />
            </Grid> */}

            {/* <Grid item xs={1}>
              <TextField
                fullWidth
                label="Date of Birth"
                //placeholder='Enter Director Date of Birth'
                variant="outlined"
                value={directorDOB}
                //type='date'
                readOnly
                //onChange={(e) => handleDirectorDOBValidation(e)}
                size='small'  
                //inputProps={{
                //maxLength:10,
                //min: (moment(new Date()).subtract(100,"year").format('yyyy-MM-DD')),
                //max: (moment(new Date()).format('YYYY-MM-DD'))
                //}}        
                //error={!validDOB}
                //helperText={validDOB?"✓"+validDOB:"Date of birth is required/Invalid"+validDOB}
                //helperText={validDOB?"✓":"Date of birth is required/Invalid"}
              />

            </Grid>
          </Grid>
        </Box>
        <hr/> */}
        {/* <Box sx={{ flexGrow: 1, sborder: '2px solid grey',marginTop:'5px' }}>
          <Grid container spacing={1}> */}
            {/* <Grid item xs={2.4}>
              <TextField
                label="Full Address"
                //placeholder='Enter Address Line1'
                variant="outlined"
                value={fullAddress}
                //inputProps={{ maxLength: 30 }}
                readOnly
                //onChange={(e) => handleAddressLine1(e)}
                size='small'          
                //error={!validAddressLine1}
                fullWidth
                //helperText={validAddressLine1?"✓":"Address Line 1 is required/Invalid"}
                //helperText={validAddressLine1?"✓"+validAddressLine1:"Address Line 1 is required/Invalid"+validAddressLine1}
              />
            </Grid>
            <Grid item xs={2.4}>
              <TextField
                label="Address Line1"
                //placeholder='Enter Address Line1'
                variant="outlined"
                value={addressLine1}
                //inputProps={{ maxLength: 30 }}
                //required
                //onChange={(e) => handleAddressLine1(e)}
                size='small'          
                //error={!validAddressLine1}
                fullWidth
                //helperText={validAddressLine1?"✓":"Address Line 1 is required/Invalid"}
                //helperText={validAddressLine1?"✓"+validAddressLine1:"Address Line 1 is required/Invalid"+validAddressLine1}
              />
            </Grid>
            <Grid item xs={2.4}>
              <TextField
                fullWidth
                label="Address Line2"
                //placeholder='Enter Address Line2'
                variant="outlined"
                value={addressLine2}
                //inputProps={{ maxLength: 30 }}
                //required
                //onChange={(e) => handleAddressLine2(e)}
                size='small'          
                //error={!validAddressLine2}
                //helperText={validAddressLine2?"✓"+validAddressLine2:"Address Line 2 is required/Invalid"+validAddressLine2}
                //helperText={validAddressLine2?"✓":"Address Line 2 is required/Invalid"}
              />
            </Grid> */}
            {/* <Grid item xs={1.3}>
              <TextField
                //fullWidth
                size='small'
                variant="outlined"
                //placeholder='Select Residential Area'
                value={directorArea}
                //onChange={(e) => handleDirectorArea(e)}
                //select
                //required
                label="Residential Area"
                //error={!validDirectorArea}
                //helperText={validDirectorArea?"✓"+validDirectorArea:"Select Residential Area"+validDirectorArea}
                //helperText={validDirectorArea?"✓":"Select Residential Area"}
              >
              {dir1Cities.map((city, key) => (
                <MenuItem key={key} value={city}>{city}</MenuItem>
                ))} 
              </TextField>

            </Grid> */}
            {/* <Grid item xs={1.3}>
              <TextField
                fullWidth
                label="Residence City"
                variant="outlined"
                value={directorCity}
                //InputProps={{
                //readOnly: true,
                //}}
                size='small'          
                //helperText={"Residence City"}
              />

            </Grid> */}
            {/* <Grid item xs={1.3}>
              <TextField
                fullWidth
                label="Residence State"
                variant="outlined"
                value={directorState}
                //InputProps={{
                //readOnly: true,
                //}}
                size='small'          
                //helperText={"Residence State"}
              />

            </Grid> */}
            {/* <Grid item xs={0.8}>
              <TextField
                fullWidth
                label="PIN Code"
                placeholder='Enter PIN Code'
                variant="outlined"
                value={directorPinCode}
                //pattern='(([0-9]){6}'
                //inputProps={{ maxLength: 6 }}
                readOnly
                type='text'
                //onChange={(e) => handlePincodeValidation(e)}
                size='small'          
                //error={!validPincode}
                //helperText={validPincode?"✓"+validPincode:"PIN Code is required/Invalid"+validPincode}
                //helperText={validPincode?"✓":"PIN Code is required/Invalid"}
              />
            </Grid> */}

          {/* </Grid>
        </Box> */}

        {/* <div style={{'overflowX':'auto', 'whiteSpace': 'nowrap','width':'100%'}}> */}
          {/* <table>
            <tbody>
              <tr>
                <td>
                  <FormControl
                    variant="outlined"
                    margin={"1"}
                    >
                    <TextField
                      width="25%"
                      size='small'
                      variant="outlined"
                      placeholder='Select Director'
                      value={selecteddirector}
                      onChange={(e) => handleDirectorValidation(e)}
                      select
                      required
                      label="Directors"
                      error={!validdirector}
                      helperText={valid?"✓":"Select Director"}
                    >
                      {DirectorNames.map((dirnames, key) => (
                      <MenuItem key={key} value={dirnames}>{dirnames}</MenuItem>
                      ))}

                    </TextField>
                  </FormControl>
                </td>
                <td>
                    <TextField
                      size='small'
                      variant="outlined"
                      placeholder='Select Gender'
                      value={selectedGender}
                      onChange={(e) => handleGenderValidation(e)}
                      select
                      inputProps={{width:"25px"}}
                      required
                      label="Gender"
                      error={!validGender}
                      helperText={valid?"✓":"Select Gender"}
                      //defaultValue="Male"
                   >
                      <MenuItem key={1} value="M">
                        Male
                      </MenuItem>
                      <MenuItem key={2} value="F">
                        Female
                      </MenuItem>
                      <MenuItem key={3} value="O">
                        Other
                      </MenuItem>

                    </TextField>
                </td>

                <td> 
                  <FormControl
                      variant="outlined"
                      margin={"1"}
                      >               
                      <TextField
                        label="PAN"
                        placeholder='Enter PAN'
                        variant="outlined"
                        value={directorPan}
                        pattern='([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}'
                        maxLength={10}
                        required
                        onChange={(e) => handleValidation(e)}
                        size='small'          
                        error={!valid}
                        helperText={valid?"✓":"PAN is required/Invalid"}
                      />
                  </FormControl>
                </td>


                <td>                
                  <FormControl
                      variant="outlined"
                      margin={"1"}
                    >               
                    <TextField
                      label="Mobile"
                      placeholder='Enter Mobiel No'
                      variant="outlined"
                      value={directorMobile}
                      pattern='(([0-9]){10}'
                      inputProps={{ maxLength: 10 }}
                      required
                      onChange={(e) => handleMobileValidation(e)}
                      size='small'          
                      
                      error={!validMobile}
                      helperText={validMobile?"✓":"Mobile is required/Invalid"}
                    />
                  </FormControl>
                </td>
                <td>                
                  <FormControl
                      variant="outlined"
                      margin={"1"}
                    >               
                    <TextField
                      label="Date of Birth"
                      placeholder='Enter Director Date of Birth'
                      variant="outlined"
                      value={directorDOB}
                      type='date'
                      required
                      onChange={(e) => handleDirectorDOBValidation(e)}
                      size='small'  
                      inputProps={{
                        maxLength:10,
                        min: (moment(new Date()).subtract(100,"year").format('yyyy-MM-DD')),
                        max: (moment(new Date()).format('YYYY-MM-DD'))
                      }}        
                      error={!validDOB}
                      helperText={validDOB?"✓":"Date of birth is required/Invalid"}
                    />
                  </FormControl>
                </td>
                <td>                
                  <FormControl
                      variant="outlined"
                      margin={"1"}
                    >               
                    <TextField
                      label="PIN Code"
                      placeholder='Enter PIN Code'
                      variant="outlined"
                      value={directorPinCode}
                      pattern='(([0-9]){6}'
                      inputProps={{ maxLength: 6 }}
                      required
                      type='text'
                      onChange={(e) => handlePincodeValidation(e)}
                      size='small'          
                      error={!validPincode}
                      helperText={validPincode?"✓":"PIN Code is required/Invalid"}
                    />
                  </FormControl>
                </td>
                <td>                
                  <Button title='' 
                    size="md"
                    color='teal'
                    disabled={(!validPincode)}
                    onClick={() => {
                      getPin({directorPinCode}, 1);
                    }}
                    >
                    Get Pin Code Details
                  </Button>

                </td>


              </tr>
            </tbody>
          </table> */}
          {/* <table>
            <tbody>
              <tr>
                <td> 
                  <FormControl
                      variant="outlined"
                      margin={"1"}
                      style={{ width: "100%", marginBottom: 0 }}
                      >               
                      <TextField
                        label="Address Line1"
                        placeholder='Enter Address Line1'
                        variant="outlined"
                        value={addressLine1}
                        inputProps={{ maxLength: 30 }}
                        required
                        onChange={(e) => handleAddressLine1(e)}
                        size='small'          
                        error={!validAddressLine1}
                        fullWidth
                        helperText={validAddressLine1?"✓":"Address Line 1 is required/Invalid"}
                      />
                  </FormControl>
                </td>
                <td> 
                  <FormControl
                      variant="outlined"
                      margin={"1"}
                      style={{ width: "100%", marginBottom: 0 }}
                      >               
                      <TextField
                        label="Address Line2"
                        placeholder='Enter Address Line2'
                        variant="outlined"
                        value={addressLine2}
                        inputProps={{ maxLength: 30 }}
                        required
                        onChange={(e) => handleAddressLine2(e)}
                        size='small'          
                        error={!validAddressLine2}
                        helperText={validAddressLine2?"✓":"Address Line 2 is required/Invalid"}
                      />
                  </FormControl>
                </td>
                <td>
                  <FormControl
                    variant="outlined"
                    margin={"1"}
                    style={{ width: "100%", marginBottom: 0 }}
                    >
                    <TextField
                      size='small'
                      variant="outlined"
                      placeholder='Select Residential Area'
                      value={directorArea}
                      onChange={(e) => handleDirectorArea(e)}
                      select
                      required
                      label="Residential Area"
                      error={!validDirectorArea}
                      helperText={validDirectorArea?"✓":"Select Residential Area"}
                    >
                      {dir1Cities.map((city, key) => (
                      <MenuItem key={key} value={city}>{city}</MenuItem>
                      ))}

                    </TextField>
                  </FormControl>
                </td>
                <td> 
                  <FormControl
                      variant="outlined"
                      margin={"1"}
                      style={{ width: "100%", marginBottom: 0 }}
                      >               
                      <TextField
                        label="Residence City"
                        variant="outlined"
                        value={directorCity}
                        InputProps={{
                          readOnly: true,
                        }}
                        size='small'          
                        helperText={"Residence City"}
                      />
                  </FormControl>
                </td>
                <td> 
                  <FormControl
                      variant="outlined"
                      margin={"1"}
                      style={{ width: "100%", marginBottom: 0 }}
                      >               
                      <TextField
                        label="Residence State"
                        variant="outlined"
                        value={directorState}
                        InputProps={{
                          readOnly: true,
                        }}
                        size='small'          
                        helperText={"Residence State"}
                      />
                  </FormControl>
                </td>
              </tr>
            </tbody>
          </table> */}
          {/* <table>
            <tbody>
              <tr>
                <td><b>Name</b></td>
                <td><b>Gender</b></td> 
                <td><b>PAN</b></td>
                <td><b>Validation</b></td>
                <td><b>Mobile</b></td>
                <td><b>DOB</b></td>
                <td><b>Pin</b></td>
                <td></td>
                <td><b>Residence Address Line1</b></td>
                <td><b>Residence Address Line2</b></td>
                <td><b>Area</b></td>
                <td><b>City</b></td>
                <td><b>State</b></td>
              </tr> 
              <tr>
                <td> 
                  <input type="text" size = {40} maxLength={50} id='dir1name' style={{'fontSize':'0.8em'}} placeholder='Director 1 name, max length : 50 characters'/>
                </td>
                 <td>        
                  <select
                    name="dir1gender"
                    id='dir1gender'  
                    onChange={e => handlegender1(e)}
                    value={SelectedGender1}
                    style={{'fontSize':'0.8em', 'border':'none'}} 
                    >
                    <option value="M">Male</option>
                    <option value="F">Female</option>
                    <option value="O">OTHER</option>
                  </select>
                </td>        
                <td>        
                  <input type="text" size = {10}  maxLength={10} id='dir1PAN' style={{'fontSize':'0.8em'}} pattern='([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}' placeholder='AAAAA1234A'/>
                </td>   
                <td>
                  <Button title='' 
                      style={{ height: "30px"}}
                      color='teal'
                      disabled={true} 
                      onClick={() => {
                      validdirPAN(1);
                      }}
                      >
                      Validate
                  </Button>
                </td>   
                <td>        
                   <input type="text" size = {10} maxLength={10} id='dir1mobile' style={{'fontSize':'0.8em'}} placeholder='9999999999' /> 
                </td>                                         
                <td>        
                  <input type="date" size = {10}  maxLength={10} id='dir1DOB' max = {myToday()} style={{'fontSize':'0.8em'}} />
                </td>
                <td>        
                  <input type="text"  size = {6} maxLength={6} id='dir1PIN' style={{'fontSize':'0.8em'}} placeholder='999999' />
                </td>     
                <td>
                  <Button title='' 
                      style={{ height: "30px"}}
                      color='teal'
                      onClick={() => {
                      getPin(document.getElementById("dir1PIN").value, 1);
                      }}
                      >
                      Get Details
                  </Button>
                </td>                  
                <td>        
                  <input type="text" size = {30}  maxLength={30} id='dir1Add1' style={{'fontSize':'0.8em'}} placeholder='Address Line 1 - 30 characters max'/>
                </td>      
                <td>        
                  <input type="text" size = {30}  maxLength={30} id='dir1Add2' style={{'fontSize':'0.8em'}} placeholder='Address Line 2 - 30 characters max'/>
                </td>            
           
                <td>        
                  <select
                    name="dir1area"
                    id='dir1area'  
                    onChange={e => handleCitySelect1(e)}
                    value={SelectedCity1}
                    style={{'fontSize':'0.8em', 'border':'none'}} 
                    >
                    <option value="">Select the Area</option>
                    {dir1Cities.map((city, key) => (
                        <option key={key} value={city}>{city}</option>
                    ))}
                  </select>
                </td>  
                <td>        
                  <input type="text"  size = {40} maxLength={50} disabled={true} id='dir1city' style={{'fontSize':'0.8em'}} />
                </td>                   
                <td>        
                  <input type="text"  size = {30} disabled={true} maxLength={50} id='dir1state' style={{'fontSize':'0.8em'}} />
                </td>     
              </tr>
            </tbody>
          </table>  */}
        {/* </div> */}

{/* 
      <MantineReactTable table={table}  /> */}
{/* if (bOrgType != "PROPRIETORSHIP") { */}

{/* {(bOrgType != "PROPRIETORSHIP") && */}

{/* {(bOrgType != "PROPRIETORSHIP") &&
<table width='100%'>
  <tbody>
    <tr>        
      <Box sx={{ flexGrow: 1, sborder: '2px solid grey',marginTop:'5px' }}>

        <Grid container spacing={1}>

          <Grid item xs={2.25}>
            <TextField
              fullWidth
              label="Bank Statement Password"
              placeholder='Enter Bank Statement Password'
              variant="outlined"
              value={bStatPassword}
              inputProps={{style: {fontSize: 12}}} // font size of input text
              InputLabelProps={{style: {fontSize: 12}}}
              required

              //style={{ border: 'none' }}
              type='password'
              onChange={(e) => handlebankpassword(e)}
              size='small'          
              error={!validbankpassword}
              //helperText={validbankpassword?"✓"+validbankpassword:"Bank statement password is required"+validbankpassword}
              helperText={validbankpassword?"✓":"Bank statement password is required"}
            />
          </Grid>
          <Grid item xs={4.55}>
            <TextField
              fullWidth
              placeholder='Upload bank statement'
              variant="outlined"
              inputProps={{style: {fontSize: 12}}} // font size of input text
              InputLabelProps={{style: {fontSize: 12}}}
              id='bnkstatement'
              type='file'
              required
              onChange={(e) => handleFileChange(e)}
              size='small'
              helperText={<b>Upload 12 months bank statement(only PDF format) between <u>{moment().subtract(14, 'months').startOf('month').format('MMM-YYYY')}</u> and <u>{moment().startOf('month').format('MMM-YYYY')}</u> for <u>{bBName}</u> and account no <u>{bAccount}</u></b>}
            />
          </Grid>
        </Grid>
      </Box>
    </tr>
  </tbody>

</table>
} */}

      {/* <Box sx={{ flexGrow: 1, sborder: '2px solid grey',marginTop:'10px', display:'flex', justifyContent:'center',alignItems:'center' }}>*/}

      {/* </Box> */}
{/*    </tr>
  </tbody>

</table>
 } */}

{(false) &&<table width="100%" >
        <tbody>
          <tr>
{/*             <td width='18%'>
            <label style={{    'display': 'inline-block', 'background': '#12b886', 'height':'2.7em','color':'#fff', 'border': '1px outset #ccc', 'borderRadius': '.3em','padding': '.3em 1em','margin': '.5em', 'verticalAlign':'middle','borderStyle': 'inset'}}>
            <b> Upload Board Resolution</b><input type="file" id ='brdres' onChange={handleBoardFile}  style={{'position': 'fixed','top': '-100%', 'width':'0'}}/> </label>
            </td>
            <td width='18%'>
            <label style={{    'display': 'none', 'background': '#12b886', 'height':'2.7em','color':'#fff', 'border': '1px outset #ccc', 'borderRadius': '.3em','padding': '.3em 1em','margin': '.5em', 'verticalAlign':'middle','borderStyle': 'inset'}}>
            <b> Upload Bank Statement</b><input type="file" id ='brdres' onChange={handleBoardFile}  style={{'position': 'fixed','top': '-100%', 'width':'0'}}/> </label>
            </td> */}
    <td width="23%">     
      <TextField
        label="Bank Statement Password"
        placeholder='Enter Bank Statement Password'
        variant="outlined"
        value={bStatPassword}
        //pattern='(([0-9]){6}'
        //inputProps={{ maxLength: 50 }}
        inputProps={{style: {fontSize: 12}}} // font size of input text
        InputLabelProps={{style: {fontSize: 12}}}
        required
        type='password'
        onChange={(e) => handlebankpassword(e)}
        size='small'          
        error={!validbankpassword}
        helperText={validbankpassword?"✓":"Bank statement password is required"}
      />
    
    
    
    
    
    
    
        {/* <label
          color="teal"
          variant="filled"
          style={{'fontSize':'0.9em'}}
          >
        <b>Bank Statement Password</b>
        </label>
        <input type="password" maxLength={50} id='bnkpassword' disabled={false} style={{'fontSize':'0.8em'}}required onChange={(e) => setbStatPassword(e.target.value)}/> */}
{/*         <input type="password" maxLength={50} id='bnkpassword' disabled={bOrgType == "PROPRIETORSHIP"} style={{'fontSize':'0.8em'}}required onChange={(e) => setbStatPassword(e.target.value)}/>
 */}{/*         <Button title='' 
          color="teal"
          disabled={bnkstatRequired == "Y"}
          onClick={() => {
          //alert('Delete Selected Accounts');
          document.getElementById('bnkstatement').click();
          }}
          variant="filled"
          >
          Upload bank Statement
        </Button>
   */}
</td>

 <td> 
    {/* <font color="red" fontSize ="0.9em">
          <b>Upload any 12 months bank statement(only PDF format) between <u>{moment().subtract(14, 'months').startOf('month').format('MMM-YYYY')}</u> and <u>{moment().startOf('month').format('MMM-YYYY')}</u></b>
          </font> &nbsp;&nbsp; */}

   {/*      <input type='file' id='bnkstatement' disabled={bOrgType == "PROPRIETORSHIP"} onChange={handleFileChange} style={{'fontSize':'0.8em'}}/> */}
        {/* <input type='file' id='bnkstatement' disabled={false} onChange={handleFileChange} style={{'fontSize':'0.9em'}}/> */}
        <TextField
          label="Bank Statement"
          placeholder='Upload bank statement'
          variant="outlined"
          //value={directorDOB}
          id='bnkstatement'
          type=''
          required
          
          onChange={(e) => handleFileChange(e)}
          size='small'
          inputProps={{style: {fontSize: 12}}} // font size of input text
          InputLabelProps={{style: {fontSize: 12}}}  
          // inputProps={{
          // maxLength:10,
          // min: (moment(new Date()).subtract(100,"year").format('yyyy-MM-DD')),
          // max: (moment(new Date()).format('YYYY-MM-DD'))
          // }}        
          //error={!validDOB}
          helperText={<b>Upload any 12 months bank statement(only PDF format) between <u>{moment().subtract(14, 'months').startOf('month').format('MMM-YYYY')}</u> and <u>{moment().startOf('month').format('MMM-YYYY')}</u> for <u>{bBName}</u> and account no <u>{bAccount}</u></b>}

        />

    </td>
    <td > 
              <Button title='' 
                  style={{ height: "40px" }}
                  color='teal'
                  onClick={() => {
                  SaveDetails();
                  }}
                  >
                  Get OTP
              </Button>
            </td>

            <td > 
              {/* <label><b>OTP Verification</b></label>
              &nbsp; &nbsp; */}
              <input
                  type="numeric"
                  //value={props.value}
                  id="otp"
                  maxLength={6}
                  minLength={6}
                  min={111111}
                  max={999999}
                  size={6}
                  required={true}
                  placeholder="Enter OTP"
                  //disabled={!isOTP}
                  //onChange={e => props.onChange(e.target.value)}
              />
              &nbsp;
              &nbsp;
              <Button title='' 
                style={{ height: "40px" }}
                color='teal'
                //variant="contained"
                //disabled={!isOTP}
                onClick={() => {
                  submitOTP();
                }}
                >
                Submit OTP
              </Button>
              &nbsp;
              &nbsp;
              <Button title='' 
                style={{ height: "40px" }}
                color='teal'
                //variant="contained"

                //disabled={!isOTP}
                onClick={() => {
                resendOTP();
                }}
                >
                Resend OTP
              </Button>
              <input type="hidden" id="selectedcomptype"></input>
            </td>

</tr>
        </tbody>
      </table> 
      }
{/* } */}

      {/* <table width="100%">
        <tbody>
          <tr>

            <td width="3%"> 
              <Button title='' 
                  style={{ height: "40px" }}
                  color='teal'
                  onClick={() => {
                  SaveDetails();
                  }}
                  >
                  Generate OTP
              </Button>
            </td>

            <td width="54%"> 
              <label><b>OTP Verification</b></label>
              &nbsp; &nbsp;
              <input
                  type="numeric"
                  //value={props.value}
                  id="otp"
                  maxLength={6}
                  minLength={6}
                  min={111111}
                  max={999999}
                  size={6}
                  required={true}
                  placeholder="Enter OTP"
                  //disabled={!isOTP}
                  //onChange={e => props.onChange(e.target.value)}
              />
              &nbsp;
              &nbsp;
              <Button title='' 
                style={{ height: "40px" }}
                color='teal'
                //variant="contained"
                //disabled={!isOTP}
                onClick={() => {
                  submitOTP();
                }}
                >
                Submit OTP
              </Button>
              &nbsp;
              &nbsp;
              <Button title='' 
                style={{ height: "40px" }}
                color='teal'
                //variant="contained"

                //disabled={!isOTP}
                onClick={() => {
                resendOTP();
                }}
                >
                Resend OTP
              </Button>
              <input type="hidden" id="selectedcomptype"></input>
            </td>
            <td width="2%">

            </td>
          </tr>
        </tbody>
      </table> 
      <br></br> */}
 </>
  )  ;
};

//CREATE hook (post new user to api)
function useCreateUser() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (user) => {
      //send api update request here
      await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
      return Promise.resolve();
    },
    //client side optimistic update
    onMutate: (newUserInfo) => {
      queryClient.setQueryData(['users'], (prevUsers) => [
        ...prevUsers,
        {
          ...newUserInfo,
          id: (Math.random() + 1).toString(36).substring(7),
        },
      ]);
    },
    // onSettled: () => queryClient.invalidateQueries({ queryKey: ['users'] }), //refetch users after mutation, disabled for demo
  });
}

//READ hook (get users from api)
/* function useGetUsers() {
  return useQuery({
    queryKey: ['users'],
    queryFn: async () => {
      //send api request here
      await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
      return Promise.resolve(User);
    },
    refetchOnWindowFocus: false,
  });
}

//UPDATE hook (put user in api)
function useUpdateUser() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (user) => {
      //send api update request here
      await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
      return Promise.resolve();
    },
    //client side optimistic update
    onMutate: (newUserInfo) => {
      queryClient.setQueryData(['users'], (prevUsers) =>
        prevUsers?.map((prevUser) =>
          prevUser.id == newUserInfo.id ? newUserInfo : prevUser,
        ),
      );
    },
    // onSettled: () => queryClient.invalidateQueries({ queryKey: ['users'] }), //refetch users after mutation, disabled for demo
  });
}

//DELETE hook (delete user in api)
function useDeleteUser() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (userId) => {
      //send api update request here
      await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
      return Promise.resolve();
    },
    //client side optimistic update
    onMutate: (userId) => {
      queryClient.setQueryData(['users'], (prevUsers) =>
        prevUsers?.filter((user) => user.id !== userId),
      );
    },
    // onSettled: () => queryClient.invalidateQueries({ queryKey: ['users'] }), //refetch users after mutation, disabled for demo
  });
} */


const queryClient = new QueryClient();

const CompDetails = () => (
  //Put this with your other react-query providers near root of your app
  <QueryClientProvider client={queryClient}>
{/*     <ModalsProvider> */}
      <CompanDetails />
{/*     </ModalsProvider> */}
  </QueryClientProvider>
);

export default CompDetails;

const validateRequired = (value) => !!value.length;
const color= "light";
const validateEmail = (email) =>
  !!email.length &&
  email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    ); 

//const validlessthantoday =(dob) => return { dob > Today};   
//const validlessthantoday =(dob) => datecheck(dob);   

function validateUser(user) {
  
  return {
     firstName: !validateRequired(user.firstName)
      ? 'First Name is Required'
      : '',
    lastName: !validateRequired(user.lastName) ? 'Last Name is Required' : '',
    dob: !validateRequired(user.dob) ? 'Date of birth is Required' : '',
    pan: !validateRequired(user.pan) ? "PAN No is Required - Expected format 'AAAAA1234A'":'',
    addline1: !validateRequired(user.addline1) ? "Address Line 1 is Required":'',
    address: !validateRequired(user.address) ? "Address is required":'',
    city: !validateRequired(user.city) ? "City is Required'":'',
    pin: !validateRequired(user.pin) ? "PIN Code is Required'":'',
    pin: !checkaadhar(user.pin, 6) ? "6 digit PIN Code is Required":'',

    dob: !(moment().isSameOrAfter(user.dob)) ? 'Date of birth can not be future date' : '',
    pan: ((user.pan).search(/^([A-Z]{5})(\d{4})([A-Z]{1})$/)==-1) ? "Incorrect format - Expected format 'AAAAA1234A'":'',
    mobile: !checkaadhar(user.mobile, 10) ? "10 digit Mobile No is Required":'',
    email: !validateEmail(user.email) ? 'Incorrect Email Format' : '', 
    authlimit:(user.authorized == "Yes") ? (parseInt(user.authlimit) > 0) ? '': 'Authorization Limit is Required for Authorized stakeholder':(parseInt(user.authlimit) > 0) ? 'Stakeholder is not Authorized stakeholder, so Limit is not Required': '', 
    shareper:(user.shareper < 0 || user.shareper < 101 )? '' : (user.shareper == "" ) ? '':'Share percent should be between 0% to 100%',
  };
}

function checkaadhar(aadh, leng){
  if ((parseInt(aadh)> 0) && (aadh.length>(leng-1))) {
    return true;
  } 
  return false;
}
