import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";

// layouts

import Admin from "layouts/Admin.js";
import Auth from "layouts/Auth.js";

// views without layouts

import Landing from "views/Landing.js";
import Profile from "views/Profile.js";
//import Index from "views/Index.js";
import About from "company/Aboutus";
import Products from "company/Products";
import Contact from "company/Contacts";
import Transactions from "company/Transactions";
//import { Payment } from "@material-ui/icons";
import Payments from "company/Payments";
//import Companydetails from "company/Companydetails";

//import CompanyDetails from "company/CompanyDetails";
import Sanction from "company/Sanction";
import FieldInvt from "company/FieldInvt";
/* import CompDetails from "company/CompDetails"; */
import CompDetails from "company/CompDetails";
import Processing from "company/Processing";
import FileUpload from "company/FileUpload";
import mymail from "company/mymail";
import Terms from "company/Terms";
import Policy from "company/Policy";
import Success from "company/Success";
import Refund from "company/Refund";
import Nach from "company/Nach";
import ByPassFi from "company/ByPassFi";
import PfPayment from "company/PfPayment";


ReactDOM.render(
  <BrowserRouter>
    <Switch>
      {/* add routes with layouts */}
      <Route path="/admin" component={Admin} />
      <Route path="/auth" component={Auth} />
      <Route path="/term" component={Terms}/>
      <Route path="/policy" component={Policy}/>
      <Route path="/refund" component={Refund}/>
      <Route path="/about" component={About} />
      <Route path="/products" component={Products}/>
      <Route path="/contact" component={Contact}/>
      <Route path="/Transactions" component={Transactions}/>
      <Route path="/success" component={Success}/>
      <Route path="/paypf" component={PfPayment}/>
      {/* <Route path="/pfPay" component={PfPayment}/> */}

     {/*  <Route path="/Payments" component={Payments}/> */}
      <Route path="/Payment" component={Payments}/>
      <Route path="/Sanction" component={Sanction}/>
      <Route path="/FldInvst" component={FieldInvt}/>
      <Route path="/ByPassFi" component={ByPassFi}/>
      <Route path="/mymail" component={mymail}/>
      <Route path="/CompDetails" component={CompDetails}/>
      <Route path="/Processing" component={Processing}/>
      <Route path="/FileUpload" component={FileUpload}/>
      <Route path="/nach" component={Nach}/>
     {/*  <Route path="/CompDetails" component={CompDetails}/> */}
      {/* <Route path="/Company" component={Companydetails}/> 
      <Route path="/Company" component={Company}/>*/}
{/* 
      <Route path="/CompanyDetails" component={CompanyDetails} /> */}

      {/* add routes without layouts */}
      <Route path="/landing" exact component={Landing} />
      <Route path="/profile" exact component={Profile} />
      <Route path="/" exact component={Landing} />
      {/* add redirect for first page */}
      <Redirect from="*" to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
