
import React, {useState, useEffect} from "react";
import axios from 'axios';
import "./input.css";
import { Button} from "@material-ui/core";
import moment from "moment";
//import Footer from "components/Footers/Footer.js";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
//import download from "downloadjs";
import { Loader } from '@mantine/core';
//import { createFalse } from "typescript";


const Success = () => {
    //const [isApproved, setisApproved] = useState('N'); 
    const [iLanNo, setiLanNo] = useState();
    const [isLoad, setisLoad] = useState(true);
    //const [lastUpdatedOn,setlastUpdatedOn ] = useState(new Date().toLocaleString());
    const [lastUpdatedOn,setlastUpdatedOn ] = useState(moment().format('ddd DD-MMM-YYYY, hh:mm:ss A'));
    
    const [processingStatus, setprocessingStatus] = useState('');
    const [sanctionletterstatus, setsanctionletterstatus] = useState('');
    //const [processingGST, setprocessingGST] = useState('5');
    //const [processingFinalAmt, setprocessingFinalAmt] = useState('205');
    //const [utrNumber, setutrNumber]=useState('YB001');
    //const [paymentStatus, setpaymentStatus]=useState('SUCCESSFUL');
    //const [amount, setamount]=useState(0);
    const [currentCount, setcurrentCount] = useState(0);
    const [bNachForm, setbNachForm] = useState('');
    const [uploadedfile, setuploadedfile] = useState(null);
    const [eKYCStatus, seteKYCStatus] = useState('');
    const [bankStatementStatus, setbankStatementStatus] = useState('');
    const [GSTCONSENTStatus, setGSTCONSENTStatus] = useState('');
    const [GSTANALYSISStatus, setGSTANALYSISStatus] = useState('');
    const [GSTCONSENTURL, setGSTCONSENTURL]=useState('');
    const [ESIGNStatus, setESIGNStatus] = useState('');
    const [LMSLIMITSETUPStatus, setLMSLIMITSETUPStatus] = useState('');
    const [LimitSetupType, setLimitSetupType] = useState('');
    const [finalLimit, setfinalLimit] = useState(0);
    const [orgname,setorgname] = useState('');
    //const [sanctionletterurl, setsanctionletterurl] = useState('https://los.statuscapitals.com/apigateway/statusapi/credit/sanction/details/');
    //const [processingfeeurl, setprocessingfeeurl] = useState('https://los.statuscapitals.com/apigateway/statusapi/yarnbiz/processingfees/');
    //const [nachurl, setnatchurl] = useState('');
    
    const [apibaseurl, setapibaseurl]=useState('https://los.statuscapitals.com/apigateway/statusapi/get/nachform?leadNo=');
    const [apiactivity, setapiactivity]=useState('https://los.statuscapitals.com/apigateway/statusapi/activitydashboard/activitydetails?buyerId=');


    <IndexNavbar fixed />


    let encoded = (window.location.href.substring(window.location.href.lastIndexOf('/') + 1))
    let locationpath = (window.location.protocol + "//" + window.location.host + "/");
    let mylan = Buffer.from(encoded,'base64').toString();
    //console.log(mylan);
    //let sanctionurl = "sanction/"+encoded;

    //setsanctionletterurl(sanctionurl);
    useEffect(()=> {
        if (currentCount < 0 ){
            return
        }

        const id = setInterval(timer, 5000);
        //setlastUpdatedOn(new Date().toLocaleString());
        setlastUpdatedOn(moment().format('ddd DD-MMM-YYYY, hh:mm:ss A'));
        return ()=>clearInterval(id);
    },[currentCount]);
    
    
    if (!Number.isInteger(parseInt(mylan, 10))) {
        if (isLoad) {
            alert("Something went wrong or wrong url, please try after sometime");
            return false;
        }
    } 
    if (isLoad) {
        setisLoad(false);
        setiLanNo(mylan);
        let url = apiactivity + mylan;
    
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: url,
  
        headers: { 
          'client-id': 'STATU0004200016', 
          'client-secret': 'QOetBBBHrU5jLGvSBE1QiuhVk65NhOEgt3a4qGcy32A=', 
          'Accept': 'application/json', 
          'Content-Type': 'application/json'
        }
      };
      //console.log(url);
      axios.request(config)
      .then((response) => {
        if (JSON.stringify(response.data.responseCode)===200){
            //console.log(response.data);

            seteKYCStatus(response.data.data[0].status);
            setbankStatementStatus(response.data.data[1].status);
            setGSTCONSENTStatus(response.data.data[2].status);
            setGSTANALYSISStatus(response.data.data[3].status);
            setESIGNStatus(response.data.data[4].status);
            setLMSLIMITSETUPStatus(response.data.data[5].status);
            setGSTCONSENTURL(response.data.data[2].links[0].url);
            setorgname(response.data.data[6].orgName);
            setsanctionletterstatus(response.data.data[7].status);
            setprocessingStatus(response.data.data[8].status);
            setLimitSetupType(response.data.data[9].activityName);
            setfinalLimit(response.data.data[9].finalLimit);
            //setsanctionletterurl(sanctionurl);
            //console.log(encoded);
            //setGSTCONSENTStatus('INI');
        }
      })
      .catch((error) => {
      });

      //sanction
    //   console.log("sanction");
    //   url = sanctionletterurl + mylan;
    
    //   config = {
    //       method: 'get',
    //       maxBodyLength: Infinity,
    //       url: url,
    
    //       headers: { 
    //         'client-id': 'STATU0004200016', 
    //         'client-secret': 'QOetBBBHrU5jLGvSBE1QiuhVk65NhOEgt3a4qGcy32A=', 
    //         'Accept': 'application/json', 
    //         'Content-Type': 'application/json'
    //       }
    //     };
    //     //console.log(url);
    //     axios.request(config)
    //     .then((response) => {
    //       if (JSON.stringify(response.data.responseCode)==200){
    //           //console.log(response.data);
    //           if (response.data.data.customerAcceptanceStatus === "APPR") {
    //                 setsanctionletterstatus(true);
    //             }  
    //       }
    //     })
    //     .catch((error) => {
    //     });
  
    //   //Processing
    //   console.log("processing");
    //   url = processingfeeurl + mylan;
    
    //   config = {
    //       method: 'get',
    //       maxBodyLength: Infinity,
    //       url: url,
    
    //       headers: { 
    //         'client-id': 'STATU0004200016', 
    //         'client-secret': 'QOetBBBHrU5jLGvSBE1QiuhVk65NhOEgt3a4qGcy32A=', 
    //         'Accept': 'application/json', 
    //         'Content-Type': 'application/json'
    //       }
    //     };
    //     console.log(url);
    //     axios.request(config)
    //     .then((response) => {
    //       if (JSON.stringify(response.data.responseCode)==200){
    //         console.log("A");
    //         console.log(response.data.data.status);
    //         console.log("B");
    //         if (response.data.data.status === "SUCCESSFUL") {
    //             setprocessingStatus(true);
    //          }  
    //       }
    //     })
    //     .catch((error) => {
    //     });
    }


    const timer =()=>{setcurrentCount(currentCount+1)
        // if (currentCount%2 == 0){
        //     setGSTCONSENTStatus('COMP');
        // }
        // else{
        //     setGSTCONSENTStatus('INI');
        // }
        let url = apiactivity + mylan;
    
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: url,
  
        headers: { 
          'client-id': 'STATU0004200016', 
          'client-secret': 'QOetBBBHrU5jLGvSBE1QiuhVk65NhOEgt3a4qGcy32A=', 
          'Accept': 'application/json', 
          'Content-Type': 'application/json'
        }
      };
      //console.log(url);
      axios.request(config)
      .then((response) => {
        if (JSON.stringify(response.data.responseCode)===200){
           // console.log(response.data);

            seteKYCStatus(response.data.data[0].status);
            setbankStatementStatus(response.data.data[1].status);
            setGSTCONSENTStatus(response.data.data[2].status);
            setGSTANALYSISStatus(response.data.data[3].status);
            setESIGNStatus(response.data.data[4].status);
            setLMSLIMITSETUPStatus(response.data.data[5].status);
            setGSTCONSENTURL(response.data.data[2].links[0].url);
            setorgname(response.data.data[6].orgName);
            setsanctionletterstatus(response.data.data[7].status);
            setprocessingStatus(response.data.data[8].status);
            setLimitSetupType(response.data.data[9].activityName);
            setfinalLimit(response.data.data[9].finalLimit);
            //setfinalLimit(0);
            //console.log(orgname);
            //setGSTCONSENTStatus('INI');
            //console.log(sanctionurl);
            //console.log(sanctionletterurl);

        }
      })
      .catch((error) => {
      });

    };


    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
      }

    const handleFileChange = (event) => {
        let selectedFile = event.target.files;
        //let file = null;
        let fileName = "";
        let filetype = "";
        //Check File is not Empty
        //console.log("hello");
        if (selectedFile.length > 0) {
            // Select the very first file from list
            let fileToLoad = selectedFile[0];
            
            fileName = fileToLoad.name;
            filetype = (fileName.split(".").at(-1));
            //console.log("file type:" + filetype);
            //console.log(fileName);
            // if ((filetype.toUpperCase()) !== "PDF"){
            //   alert("You have not uplodaed 'PDF' file, you have uploaded '"+ filetype.toUpperCase() +"'\n Please upload bank statement in 'PDF' format");
            //   event.target.value=null;
            //   return false;
            // }
            
            // FileReader function for read the file.
            let fileReader = new FileReader();
            // Onload of file read the file content
            fileReader.onload = function(fileLoadedEvent) {
                setuploadedfile(fileLoadedEvent.target.result);
                //setbStatement(fileLoadedEvent.target.result);
                // Print data in console
                //console.log(uploadedfile);
            };
            // Convert data to base64
            fileReader.readAsDataURL(fileToLoad);
        }
      //setbStatement(uploadedfile);
      setbNachForm(uploadedfile);
      //console.log("hello");
      //console.log(uploadedfile);
      //console.log(file);
      //console.log('hello world');
      //console.log(bNachForm);
      //console.log('hello again');
      //console.log(file);
      //console.log('hello over');
      //this.setState({
      //  fileData: file,
      //  fileName: fileName
      //})
      if (uploadedfile!==null){
        //ready to share with API
        

      }
      }
      


    // function handleSubmit () {
    //     let data = JSON.stringify({
    //         "utrNumber": utrNumber,
    //         "paymentStatus": paymentStatus,
    //         "amount": amount
    //       });
          
    //     var url = apibaseurl + iLanNo
    //     let config = {
    //       method: 'post',
    //       maxBodyLength: Infinity,
    //       url: url,
    //       headers: { 
    //         'client-id': 'STATU0004200016', 
    //         'client-secret': 'QOetBBBHrU5jLGvSBE1QiuhVk65NhOEgt3a4qGcy32A=', 
    //         'Accept': 'application/json', 
    //         'Content-Type': 'application/json'
    //       },
    //       data : data
    //     };
        
    //     axios.request(config)
    //     .then((response) => {
    //       //console.log(JSON.stringify(response.data));

    //         if (JSON.stringify(response.data.responseCode)==200){
    //             alert("Your response submitted successfully, please close this windows");
    //             return null;
            
    //         }else{
    //             alert("Facing some issue in getting details, please retry again!!!")
    //             return false;
    //         }
    //     })
    //     .catch((error) => {
    //       alert("Facing some issue in getting details, please retry again!!!")
    //       return false;  
    //     });
    // }

    function downloadnash () {
        let data = JSON.stringify({
            //"utrNumber": utrNumber,
            //"paymentStatus": paymentStatus,
            //"amount": amount
          });
        var url = apibaseurl + iLanNo
        //alert(url);  
        let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: url,
          headers: { 
            'client-id': 'STATU0004200016', 
            'client-secret': 'QOetBBBHrU5jLGvSBE1QiuhVk65NhOEgt3a4qGcy32A=', 
            'Accept': 'application/json', 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJyaXRlc2gucGFuZGV5QDRmaW4uaW4iLCJleHAiOjE2OTA1MjA3NDUsImlhdCI6MTY4NzkyODc0NX0.mwiWCvDoMdwrjH9cIqXQpxdNNSQWT9ZkhfHAehVHP5M'
          },
          //data : data
        };
        
        axios.request(config)
        .then((response) => {
          //console.log(JSON.stringify(response.data));

            if (JSON.stringify(response.data.responseCode)===200){
                //alert("Your response submitted successfully, please close this windows");
                return null;
            
            }else{
                alert("Facing some issue in getting details, please retry again!!!")
                return false;
            }
        })
        .catch((error) => {
          alert("Facing some issue in getting details, please retry again!!!")
          return false;  
        });
    }



    return(
        <>
            <br></br>
            {(orgname!=="" || orgname!==null)&&<div style={{display: 'flex', alignItems: 'center',justifyContent: 'center', }}>Thank you very much for submitting OTP and details for &nbsp;<b>{orgname}</b></div> }
            <br></br>

            {/* <div style={{display: 'flex', alignItems: 'center',justifyContent: 'center', }}><b> Thank you very much for submitting details and OTP.</b></div> 
            <br></br>
            <div style={{display: 'flex', alignItems: 'center',justifyContent: 'center', }}>Your OTP Submitted successfully.</div> 
            <br></br>
            <div style={{display: 'flex', alignItems: 'center',justifyContent: 'center', }}>SMS and email communication has been sent to you for:</div> 
            <div style={{display: 'flex', alignItems: 'center',justifyContent: 'center', }}>1. Bank statement submission - <b><u>Please select your bank account and confirm with the OTP</u></b></div> 
            <div style={{display: 'flex', alignItems: 'center',justifyContent: 'center', }}>2. GST data submission (<u>Please be ready with your <b>GST Portal login details</b> to <b>generate consent OTP</b></u>) and</div> 
            <div style={{display: 'flex', alignItems: 'center',justifyContent: 'center', }}>3. E KYC  (<u>Please be ready with your <b>AADHAR No.</b> to generate <b>EKYC OTP</b></u>)</div>  
            <br></br>
            <div style={{display: 'flex', alignItems: 'center',justifyContent: 'center', }}><b> Wait for 30 minutes </b>to process credit limit after submitting the above details. </div> 
            <br></br>*/}

{/*             <table style={{ 'width':'20%',  'margin-left': 'auto', 'margin-right': 'auto','alignItems': 'center', 'justifyContent': 'center', 'border': '1px solid black','borderCollapse': 'collapse' }}>
                        <tbody>
                            <tr>
                                <td colSpan = '2' style={{ 'border': '1px solid black','borderCollapse': 'collapse' }} hidden={processingStatus!=="SUCCESSFUL"}>
                                    <font color="red"> You have already paid the processing fee!!!
                                    </font>  
                                </td>
                            </tr>
                            <tr>
                                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}></td>
                                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>Amount in (₹)</td>
                            </tr>
                            <tr>
                                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>Processing Fee </td>
                                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>{processingAmt} </td>
                            </tr>
                            <tr>
                                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>GST </td>
                                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>{processingGST} </td>
                            </tr>
                            <tr>
                                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>Total </td>
                                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>{processingFinalAmt} </td>
                            </tr>
                            <tr>
                                <td colSpan='2' style={{ 'textAlign': 'center'}}> 
                                    <Button
                                        style={{ 'height': 'fit-content', 'background': '#12b886', 'height':'1.9em','color':'#fff', 'border': '1px outset #ccc', 'borderRadius': '.3em'}}
                                        disabled={isApproved==="Y"}
                                        onClick={() => {
                                            //handleSubmit();
                                        }}
                                    >
                                        Pay Now
                                    </Button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
 */}

    <table>
        <tbody>
            <tr>
                <th style={{'width':'4%', 'textAlign': 'center', 'fontSize':'14'}}>Sr. No.</th>
                <th style={{'width':'60%', 'fontSize':'10'}}>Step</th>
                <th style={{'width':'18%', 'fontSize':'10'}}>Status</th>
                <th style={{'width':'18%', 'fontSize':'10'}}>Action</th>
            </tr>
            <tr>
                <td style={{'textAlign': 'center'}}>1</td>
                <td>Status Capital Registration OTP Consent</td>
                <td>Done</td>
                <td></td>
            </tr>
            <tr>
                <td style={{'textAlign': 'center'}}>2</td>
                <td>GST Consent<br/>(Use your GST Portal login details to generate consent OTP. Make sure you have enabled API access on GST portal for your GST no)<br/>You can also initiate the consent request by clicking on button provided in Action column</td>
                <td>
                    {(GSTCONSENTStatus==="COMP")&&<p>Done</p>}
                    {(GSTCONSENTStatus==="INI")&&<Loader color="teal"/>}
                    {(GSTCONSENTStatus==="ERROR")&&<p>Error in process</p>} 
                </td>
                <td>
                    {(GSTCONSENTStatus==="INI")&&<Button style={{ 'background': '#12b886', 'height':'2.0em','color':'#fff', 'border': '1px outset #ccc', 'borderRadius': '.3em'}}   
                      onClick={() => openInNewTab(GSTCONSENTURL)}
                    >
                    Get GST Consent
                    </Button>
                    }
                </td>
            </tr>
            <tr>
                <td style={{'textAlign': 'center'}}>
                    3
                </td>
                <td>
                    GST Analysis
                </td>
                <td>
                    {(GSTANALYSISStatus==="INI")&&<Loader color="teal"/>}
                    {(GSTANALYSISStatus==="COMP")&&<p>Done</p>}
                    {(GSTANALYSISStatus==="ERROR")&&<p>Error in process</p>} 
                </td>
                <td>
                </td>
            </tr>
            <tr>
                <td style={{'textAlign': 'center'}}>
                    4
                </td>
                <td>
                    Bank Statement
                </td>
                <td>
                    {(bankStatementStatus==="INI")&&<Loader color="teal"/>}
                    {(bankStatementStatus==="COMP")&&<p>Done</p>}
                    {(bankStatementStatus==="ERROR")&&<p>Error in process</p>} 
                </td>
                <td>
                </td>
            </tr>
            <tr>
                <td style={{'textAlign': 'center'}}>
                    5
                </td>
                <td>
                    E KYC <br/>(Use your AADHAR No.to generate EKYC OTP)
                </td>
                <td>
                    {(eKYCStatus==="INI")&&<Loader color="teal"/>}
                    {(eKYCStatus==="COMP")&&<p>Done</p>}
                    {(eKYCStatus==="ERROR")&&<p>Error in process</p>} 
                </td>
                <td>
                </td>
            </tr>
            <tr>
                <td style={{'textAlign': 'center'}}>
                    6
                </td>
                <td>
                    E Sign <br/>(Use your AADHAR No.to generate EKYC OTP)
                </td>
                <td>
                    {(ESIGNStatus==="INI")&&<Loader color="teal"/>}
                    {(ESIGNStatus==="COMP")&&<p>Done</p>}
                    {(ESIGNStatus==="NOT INITIATED")&&<p>Not yet initiated</p>}
                    {(ESIGNStatus==="ERROR")&&<p>Error in process</p>} 
                </td>
                <td>
                </td>
            </tr>
            <tr>
                <td style={{'textAlign': 'center'}}>
                    7
                </td>
                <td>
                    Credit Limit Approval
                </td>
                <td>
                    {(LMSLIMITSETUPStatus==="INI")&&<Loader color="teal"/>}
                    {(LMSLIMITSETUPStatus==="COMP")&&<p>Done</p>}
                    {(LMSLIMITSETUPStatus==="NOT INITIATED" && finalLimit <1)&&<p>Not yet initiated</p>}
                    {(LMSLIMITSETUPStatus==="ERROR")&&<p>Error in process</p>} 
                    {(LimitSetupType==="FINALLIMIT" && finalLimit >0)&&<p>Approved, check details below</p>}
                </td>
                <td>
                </td>
            </tr>
            {(LimitSetupType==="FINALLIMIT" && finalLimit >0)&&(<tr>
                <td colSpan={4} style={{'paddingLeft': '35px'}}>
                    Congratulations <b>Rs. {finalLimit}</b> is approved as a credit limit provided by Status Capitals Private Limited.<br></br> <br></br>To utilize this limit you need to complete following steps: 
                </td>
            </tr>
             )}    
            {(LimitSetupType==="FINALLIMIT" && finalLimit >0)&&(<tr>
                <td style={{'textAlign': 'center'}}>
                    8
                </td>
                <td>
                    Sanction Letter Acceptance
                </td>
                <td>
                    {(sanctionletterstatus==="COMP")&&(<p>Accepted</p>)}
                    {(sanctionletterstatus!=="COMP")&&(<p>Not yet Accepted</p>)}
                </td>
                <td>
                {(sanctionletterstatus!=="COMP")&&(<Button style={{ 'background': '#12b886', 'height':'2.0em','color':'#fff', 'border': '1px outset #ccc', 'borderRadius': '.3em'}}   
                      onClick={() => openInNewTab(locationpath+'sanction/'+encoded)}
                    >
                    Sanction Letter
                    </Button>
                )}    
                </td>
            </tr>
            )}
            {(LimitSetupType==="FINALLIMIT" && finalLimit >0)&&(<tr>
                <td style={{'textAlign': 'center'}}>
                    9
                </td>
                <td>
                    Processing Fee Payment
                </td>
                <td>
                    {(processingStatus==="COMP")&&(<p>Paid</p>)}
                    {(processingStatus!=="COMP")&&(<p>Not yet Paid</p>)}
                </td>
                <td>
                {(processingStatus!=="COMP")&&(<Button style={{ 'background': '#12b886', 'height':'2.0em','color':'#fff', 'border': '1px outset #ccc', 'borderRadius': '.3em'}}   
                      onClick={() => openInNewTab(locationpath+'processing/'+encoded)}
                    >
                    Pay Processing Fee
                    </Button>
                )}    
                </td>
            </tr>
            )}
            {(LimitSetupType==="FINALLIMIT" && finalLimit >0)&&(<tr>
                <td style={{'textAlign': 'center'}}>
                    10
                </td>
                <td>
                    Download Nach Form
                </td>
                <td>
                </td>
                <td>
                    <Button style={{ 'background': '#12b886', 'height':'2.0em','color':'#fff', 'border': '1px outset #ccc', 'borderRadius': '.3em'}}   
                      onClick={() => openInNewTab(locationpath+'processing/'+encoded)}
                    >
                    Download Nach Form
                    </Button>
                </td>
            </tr>
            )}
            {(LimitSetupType==="FINALLIMIT" && finalLimit >0)&&(<tr>
                <td style={{'textAlign': 'center'}}>
                    11
                </td>
                <td>
                    Upload Filled Nach Form
                </td>
                <td>
                </td>
                <td>
                    <Button style={{ 'background': '#12b886', 'height':'2.0em','color':'#fff', 'border': '1px outset #ccc', 'borderRadius': '.3em'}}   
                      onClick={() => openInNewTab(locationpath+'processing/'+encoded)}
                    >
                    Upload Filled Nach Form
                    </Button>
                </td>
            </tr>
            )}
        </tbody>
    </table>

                    
                   




                    <div style={{display: 'flex', alignItems: 'center',justifyContent: 'center', }}>In case any support is required please get in touch with us on - <b>022 6605 0000(between 9 AM to 7 PM)</b> </div> 



        </>







    );

}
export default Success;