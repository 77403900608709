import React from "react";
import { Link } from "react-router-dom";
import { createPopper } from "@popperjs/core";
//import { faListDropdown } from  "react-icons/fa";
//import { Products } from "company/Products";


const IndexDropdown = () => {
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();
  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-start",
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };


  const loggedin = sessionStorage.getItem("registeruser");

  if (loggedin){
      let username = sessionStorage.getItem("username");
      return (
        
        <>
          <a
            className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
            href="#pablo"
            ref={btnDropdownRef}
            onClick={(e) => {
              e.preventDefault();
              dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
            }}
          >
            <i className="text-blueGray-400 fa fa-user-circle text-lg leading-lg " /> 
    {/*         &nbsp;&nbsp;sdljfldjlfdjl
            <i className="text-blueGray-400 fa fa-user-circle-o text-lg leading-lg " /> */}
            {/* Menu */}
          </a>
          <div
            ref={popoverDropdownRef}
            className={
              (dropdownPopoverShow ? "block " : "hidden ") +
              "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
            }
          >
            <span
              className={
                "text-sm pt-2 pb-0 px-4 font-bold block w-full whitespace-nowrap bg-transparent text-blueGray-400"
              }
            >
              {/* Admin Layout */}
            </span>
            <Link
              to="/auth/logout"
              className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
            >
              Sign out
            </Link>

            <Link
              to="/admin/dashboard"
              className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
            >
              Dashboard
            </Link>
            <Link
              to="/Transactions"
              className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
            >
              Transactions
            </Link>
            <Link
              to="/Payment"
              className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
            >
              Payments
            </Link>
{/*             <Link
              to="/Company"
              className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
            >
              Company
            </Link> */}
            <Link 
              to="/CompanyDetails"
              className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
            >
              Company
            </Link>
            <Link
              to="/profile"
              className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
            >
              Profile
            </Link>        
      
  {/*          <Link
              to="/admin/tables"
              className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
            >
              Tables
            </Link>
             <Link
              to="/admin/settings"
              className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
            >
              Settings
            </Link>

            <Link
              to="/admin/maps"
              className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
            >
              Maps
            </Link> */}
    {/*           <div className="h-0 mx-4 my-2 border border-solid border-blueGray-100" />
            <span
              className={
                "text-sm pt-2 pb-0 px-4 font-bold block w-full whitespace-nowrap bg-transparent text-blueGray-400"
              }
            >
              Auth Layout
            </span>

            <Link
              to="/auth/register"
              className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
            >
              Register
            </Link> */}
    {/*         <div className="h-0 mx-4 my-2 border border-solid border-blueGray-100" />
            <span
              className={
                "text-sm pt-2 pb-0 px-4 font-bold block w-full whitespace-nowrap bg-transparent text-blueGray-400"
              }
            >
              No Layout
            </span> */}

            <div className="h-0 mx-4 my-2 border border-solid border-blueGray-100" />
            <span
              className={
                "text-sm pt-2 pb-0 px-4 font-bold block w-full whitespace-nowrap bg-transparent text-blueGray-400"
              }
            >
              Company
            </span>
            <Link
              to="/"
              className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
            >
              Home
            </Link>
            <Link
              to="/products"
              className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-bluegray-700"
            >
            Products
            </Link>
            <Link
              to="/about"
              className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
            >
              About
            </Link>

            <Link
              to="/contact"
              className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
            >
              Contact
            </Link>

          </div>
          <div> 
              <label
              className="text-sm py-2 px-4 font-bold block w-full whitespace-nowrap bg-transparent text-blueGray-700"
              >
                {username}  
              </label>
          </div>
        </>
        



        
      );
    }
  else{
    return (
    <>
        <a
          className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
          href="#pablo"
          ref={btnDropdownRef}
          onClick={(e) => {
            e.preventDefault();
            dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
          }}
        >
          <i className="text-blueGray-400 fa fa-user-circle text-lg leading-lg " /> 
  {/*         &nbsp;&nbsp;sdljfldjlfdjl
          <i className="text-blueGray-400 fa fa-user-circle-o text-lg leading-lg " /> */}
          {/* Menu */}
        </a>
        <div
          ref={popoverDropdownRef}
          className={
            (dropdownPopoverShow ? "block " : "hidden ") +
            "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
          }
        >
          <span
            className={
              "text-sm pt-2 pb-0 px-4 font-bold block w-full whitespace-nowrap bg-transparent text-blueGray-400"
            }
          >
            {/* Admin Layout */}
          </span>
          {/* <Link
            //to="/auth/login"
            className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          >
            Sign in
          </Link> */}
          Sign in
          <div className="h-0 mx-4 my-2 border border-solid border-blueGray-100" />
          <span
            className={
              "text-sm pt-2 pb-0 px-4 font-bold block w-full whitespace-nowrap bg-transparent text-blueGray-400"
            }
          >
            Company
          </span>
          <Link
            to="/landing"
            className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          >
            Home
          </Link>
          <Link
            to="products"
            className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-bluegray-700"
          >
          Products
          </Link>
          <Link
            to="about"
            className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          >
            About
          </Link>

          <Link
            to="contact"
            className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          >
            Contact
          </Link>

          {/* <Link 
            to="/duedates"
            className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          >
            Reschedule Payment Due Dates
          </Link>   
            <Link
              to="/admin/tables"
              className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
            >
              Tables
            </Link>*/}
        </div>
      </>
      



      
    );
  }      
};

export default IndexDropdown;
