import React, {useState} from "react";

import axios from 'axios';
// import { Button } from "@material-ui/core";
//import txtfile from "files/buyr.txt";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from "moment";
import { jsPDF } from "jspdf";
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import Search from '@mui/icons-material/Search';



const ByPassFi = () => {
  //const [isRender, setisRender] = useState("Y"); 
  const [pdffile, setpdffile] = useState(null);
  const [uploadedfile, setuploadedfile] = useState(null);
  const [fiStatOFfice, setfiStatOffice] = useState('N');
  const [fiStatFact, setfiStatFact] = useState('N');
  const [fiStatResi, setfiStatResi] = useState('N');
  const [offlocation, setofflocation] = useState("Y");
  const [factlocation, setfactlocation] = useState("Y");
  const [offlocationComent, setofflocationComent] = useState('');
  const [factlocationComent, setfactlocationComent] = useState('');
  const [resilocationComent, setresilocationComent] = useState('');
  const [offCity, setOffCity] = useState('');
  const [iLanNo, setiLanNo ] = useState('');
  const [buyerid, setbuyerid] = useState('');
  const [validbuyerid, setvalidbuyerid] = useState(false);
  const [rmName, setrmName] = useState('');
  const [orgName, setorgName] = useState('');
  const [orName, setorName] = useState("Y");
  const [orNameF,setorNameF] = useState("Y");
  const [orNameR,setorNameR] = useState("Y");
  const [orNamComment, setorNamComment] = useState('');
  const [orNamFComment, setorNamFComment] = useState('');
  const [orNamRComment, setorNamRComment] = useState('');
  //const [product, setproduct] = useState('');
  const [offAddress, setoffAddress] = useState('');
  const [offAddressComment, setoffAddressComment] = useState('');
  const [resiAddress, setresiAddress] = useState('');
  const [resiAddressComment, setresiAddressComment] = useState('');

  
  const [ownOffice, setownOffice] = useState('');
  const [ownResi, setownResi] = useState('');
  const [orgRes, setorgRes] = useState("Y");
  const [commaddRes, setcommaddRes]= useState("Y");
  const [offAdrres, setoffAdrres] = useState("Y");
  const [ShipAddRes, setShipAddRes] = useState("Y");
  const [Mobile, setMobile] = useState("Y");
  const [Email, setEmail] = useState("Y");
  const [First, setFirst] = useState("Y");
  const [Last, setLast] = useState("Y");
  const [Dob, setDob] = useState("Y");
  const [dMobile, setdMobile] = useState("Y");
  const [dEmail, setdEmail] = useState("Y");
  const [dAdd1, setdAdd1] = useState("Y");
  const [dAdd, setdAdd] = useState("Y");
  const [dCity, setdCity] = useState("Y");
  const [dState, setdState] = useState("Y");
  const [dPin, setdPin] = useState("Y");
  const [Recom, setRecom] = useState("Y");
  const [isLoad, setisLoad] = useState(true);
  const [todaydate, settodaydate] = useState(moment(new Date()).format('DD-MMMM-YYYY'));
  const [todaytime, settodaytime] = useState(moment(new Date()).format('hh:mm A'));
  
  const [geoLocation, setgeoLocation] = useState('');
  const [lati, setlati] = useState('');
  const [long, setlong] = useState('');
  const [recoff, setrecoff] = useState('Y');
  const [recoffF, setrecoffF] = useState('Y');
  const [recoffr, setrecoffr] = useState('Y');
  const [recoffcomment, setrecoffcomment] = useState('');
  const [recoffcommentf, setrecoffcommentf] = useState('');
  const [recoffcommentr, setrecoffcommentr] = useState('');
  const [imgData1, setImgData1] = useState(null);
  const [imgData2, setImgData2] = useState(null);
  const [imgData3, setImgData3] = useState(null);
  const [imgData4, setImgData4] = useState(null);
  const [title1,settitle1] = useState('');
  const [title2,settitle2] = useState('');
  const [title3,settitle3] = useState('');
  const [title4,settitle4] = useState('');
  const [ftype1, setftype1] = useState('');
  const [ftype2, setftype2] = useState('');
  const [ftype3, setftype3] = useState('');
  const [ftype4, setftype4] = useState('');
  
  const [imgData1f, setImgData1f] = useState(null);
  const [imgData2f, setImgData2f] = useState(null);
  const [imgData3f, setImgData3f] = useState(null);
  const [imgData4f, setImgData4f] = useState(null);
  const [title1f,settitle1f] = useState('');
  const [title2f,settitle2f] = useState('');
  const [title3f,settitle3f] = useState('');
  const [title4f,settitle4f] = useState('');
  const [ftype1f, setftype1f] = useState('');
  const [ftype2f, setftype2f] = useState('');
  const [ftype3f, setftype3f] = useState('');
  const [ftype4f, setftype4f] = useState('');
  
  const [imgData1r, setImgData1r] = useState(null);
  const [imgData2r, setImgData2r] = useState(null);
  const [imgData3r, setImgData3r] = useState(null);
  const [imgData4r, setImgData4r] = useState(null);
  const [title1r,settitle1r] = useState('');
  const [title2r,settitle2r] = useState('');
  const [title3r,settitle3r] = useState('');
  const [title4r,settitle4r] = useState('');
  const [ftype1r, setftype1r] = useState('');
  const [ftype2r, setftype2r] = useState('');
  const [ftype3r, setftype3r] = useState('');
  const [ftype4r, setftype4r] = useState('');
    
  
  const [personmet, setpersonmet] = useState('');
  const [designationmet, setdesignationmet] = useState('');
  const [personmetf, setpersonmetf] = useState('');
  const [personmetr, setpersonmetr] = useState('');
  const [designationmetf, setdesignationmetf] = useState('');
  const [noofemployee, setnoofemployee] = useState(0);
  const [noofemployeef, setnoofemployeef] = useState(0);
  const [locationofoffice, setlocationofoffice] = useState('');
  const [locationofofficef, setlocationofofficef] = useState('');
  const [locationofofficer, setlocationofofficer] = useState('');

  const [generalappearnce,setgeneralappearnce] = useState('');
  const [generalappearncef, setgeneralappearncef] = useState('');
  const [generalappearncer, setgeneralappearncer] = useState('');

  const [verifiercomment, setverifiercomment] = useState('');
  const [verifiercommentf, setverifiercommentf] = useState('');
  const [verifiercommentr, setverifiercommentr] = useState('');

  const [statusheader, setstatusheader] = useState('Status Capitals Private Limited');
  const [facAddress, setfacAddress] = useState('Y');
  const [resAddress, setresAddress] = useState('Y');

  const [facAddresss, setfacAddresss] = useState('');
  const [facAddressComment, setfacAddressComment] = useState('');
  const [factownlocation, setfactownlocation] = useState("Y");
  const [resiownlocation, setresiownlocation] = useState("Y");
  const [dirName, setdirName] = useState('');
  const [drName, setdrName] = useState('Y');
  const [drNameComment, setdrNameComment] = useState('');

  const [apibaseurl, setapibaseurl]=useState('https://los.statuscapitals.com/apigateway/statusapi/yarnbiz/getfidetails?leadNo=');
  const [saveofficeapi, setsaveofficeapi]=useState('https://los.statuscapitals.com/apigateway/statusapi/yarnbiz/saveofffidetails'); 
  const [savefactapi, setsavefactapi]=useState('https://los.statuscapitals.com/apigateway/statusapi/yarnbiz/savefacfidetails');
  const [saveresiapi, setsaveresiapi]=useState('https://los.statuscapitals.com/apigateway/statusapi/yarnbiz/saveresfidetails');
  const [apiactivity, setapiactivity]=useState('https://los.statuscapitals.com/apigateway/statusapi/activitydashboard/activitydetails?buyerId=');

  const [mylan, setmylan] = useState(0);
  const [errorMessage, seterrorMessage] = useState('');
  const [errortype, seterrortype] = useState('success');
  const [erroropen, seterroropen] = useState(false);
  const [errorTimer, seterrorTimer] = useState(20000);
  const [validreferredBy, setvalidreferredBy] = useState(false);
  const [referredBy, setreferredBy] = useState('');
  const [equifaxScore, setequifaxScore] = useState('');
  const [validequifaxScore, setvalidequifaxScore] = useState(false);
  const [crifScore, setcrifScore] = useState('');
  const [validcrifScore, setvalidcrifScore] = useState(false);
  const [validrelationship, setvalidrelationship] = useState(false);
  const [relationship, setrelationship] = useState('');
  const [validfidoneby, setvalidfidoneby] = useState(false);
  const [fidoneby, setfidoneby] = useState('');
  const [allvalid, setallvalid] = useState(false);






  const handleBuyerIdValidation = (e) => {
    setbuyerid(e.target.value);
  
    let regex = new RegExp(/^[0-9]{1,6}$/);
    
    setvalidbuyerid(regex.test(e.target.value));
    if (((e.target.value).substring(0,1))==0){
      setvalidbuyerid(false);
    }
  };

  const handlereferredBy = (e) => {
    setreferredBy(e.target.value);
    
    if ((e.target.value).length > 0 ) {
      setvalidreferredBy(true);
    }
    else{
      setvalidreferredBy(false);
    } 
  };

  const handlefidoneby = (e) => {
    setfidoneby(e.target.value);
    
    if ((e.target.value).length > 0 ) {
      setvalidfidoneby(true);
    }
    else{
      setvalidfidoneby(false);
    } 
  };

  const handlerelationship = (e) => {
    setrelationship(e.target.value);
    
    if ((e.target.value).length > 0 ) {
      setvalidrelationship(true);
    }
    else{
      setvalidrelationship(false);
    } 
  };



  const handleequifaxScore = (e) =>{
    setequifaxScore(e.target.value);

    let regex = new RegExp(/^[0-9]{3}$/);
    setvalidequifaxScore(regex.test(e.target.value));
    if ((e.target.value.length) < 0){
      setvalidequifaxScore(false);
    }
    if (e.target.value < 700) {
      setvalidequifaxScore(false);
    }
  }

  const handlecrifScore = (e) =>{
    setcrifScore(e.target.value);

    let regex = new RegExp(/^[0-9]{3}$/);
    setvalidcrifScore(regex.test(e.target.value));
    if ((e.target.value.length) < 0){
      setvalidcrifScore(false);
    }
    if (e.target.value < 700) {
      setvalidcrifScore(false);
    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }    
    seterroropen(false);
    seterrorMessage('');
    seterrortype('success');
  };

const getBuyerDetails = e => {
    let url = apiactivity+buyerid;
    let fistatusarray;
    setallvalid(false);      
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: url,
  
        headers: { 
          'client-id': 'STATU0004200016', 
          'client-secret': 'QOetBBBHrU5jLGvSBE1QiuhVk65NhOEgt3a4qGcy32A=', 
          'Accept': 'application/json', 
          'Content-Type': 'application/json'
        }
      };
      axios.request(config)
      .then((response) => {
        if (JSON.stringify(response.data.responseCode)==200){

            let lanNo =(response.data.data[1].leadNo);

            setmylan(response.data.data[1].leadNo);
            setmylan(lanNo);
            
            let url = apibaseurl + lanNo;
            let config = {
              method: 'get',
              maxBodyLength: Infinity,
              url: url,
      
              headers: { 
                'client-id': 'STATU0004200016', 
                'client-secret': 'QOetBBBHrU5jLGvSBE1QiuhVk65NhOEgt3a4qGcy32A=', 
                'Accept': 'application/json', 
                'Content-Type': 'application/json'
              }
            };
            axios.request(config)
            .then((response) => {
              if (JSON.stringify(response.data.responseCode)==200){
                setorgName(' - ' + response.data.data.fiorgDetails.orgName);

                fistatusarray = (response.data.data.fiStatus);
                var fistatlen = fistatusarray.length;
                
                if (fistatlen > 0){
                   for (let fii = 0; fii < fistatlen; fii++){
                      switch (fistatusarray[fii].fiType){
                        case 'FACTORY':
                          {
                            setfiStatFact(fistatusarray[fii].fiStatus);  
                            //console.log(fiStatFact);
                            break;
                          }
                        case 'OFFICE':
                          {
                            setfiStatOffice(fistatusarray[fii].fiStatus);
                            //console.log(fiStatOFfice);
                            break;
                          }
                        case 'RESIDENCE':
                          {
                            setfiStatResi(fistatusarray[fii].fiStatus);
                            //console.log(fiStatResi);  
                            break;
                          }
                      }
                  } 
                  seterrorMessage('Sorry! FI for this Buyer is already done');     
                  seterroropen(true);
                  seterrorTimer(8000);
                  seterrortype('error');
                  setallvalid(false);
                } else
                {
                  setallvalid(true);
                }
                }
            
            })
            .catch((error) => {
              seterrorMessage('Sorry! Error in fetching FI details for this Buyer, please check after some time');     
              seterroropen(true);
              seterrorTimer(8000);
              seterrortype('error');
              
            });
        }
      })
      .catch((error) => {
        seterrorMessage('Sorry! Details not found for this Buyer, please check the Buyer ID');     
        seterroropen(true);
        seterrorTimer(8000);
        seterrortype('error');
      });
  };

//return false;

  //let encoded = (window.location.href.substring(window.location.href.lastIndexOf('/') + 1))

  //let mylan = Buffer.from(encoded,'base64').toString();
   
  let fistatusarray;

  // if (!Number.isInteger(parseInt(mylan, 10))) {
  //     if (isLoad) {
  //         alert("Something went wrong or wrong url, please try after sometime1");
  //         return false;
  //     }
  // } 
  // if (isLoad) {
  //     setisLoad(false);
  //     setiLanNo(mylan);
       //settodaydate(moment(new Date()).format('DD-MMMM-YYYY'));
       //settodaytime(moment(new Date()).format('hh:mm A'));

  //     let url = apibaseurl + mylan;
  //     let config = {
  //       method: 'get',
  //       maxBodyLength: Infinity,
  //       url: url,

  //       headers: { 
  //         'client-id': 'STATU0004200016', 
  //         'client-secret': 'QOetBBBHrU5jLGvSBE1QiuhVk65NhOEgt3a4qGcy32A=', 
  //         'Accept': 'application/json', 
  //         'Content-Type': 'application/json'
  //       }
  //     };
  //     axios.request(config)
  //     .then((response) => {
  //       //console.log(JSON.stringify(response.data.responseCode));
  //       //console.log(response.data.data);
  //       if (JSON.stringify(response.data.responseCode)==200){
  //         //console.log(response.data.data);
  //         setorgName(response.data.data.fiorgDetails.orgName);
  //         setownOffice(response.data.data.fiorgDetails.officeAddress.ownedYN);
  //         //console.log(orgName);
  //         //console.log(ownOffice);
  //         let officeAdd =    'Address: ' + response.data.data.fiorgDetails.officeAddress.line1 + '\n       ' + response.data.data.fiorgDetails.officeAddress.line2;
  //         officeAdd += '\n'+ 'Landmark:' + response.data.data.fiorgDetails.officeAddress.landmark; 
  //         officeAdd += '\n'+ 'City:    ' +  response.data.data.fiorgDetails.officeAddress.city;
  //         officeAdd += '\n'+ 'Area:    ' + response.data.data.fiorgDetails.officeAddress.areaName; 
  //         officeAdd += '\n'+ 'State:   ' +  response.data.data.fiorgDetails.officeAddress.state;
  //         officeAdd += '\n'+ 'Pincode: ' + response.data.data.fiorgDetails.officeAddress.pinCode; 
  //         setoffAddress(officeAdd);

  //         setownResi(response.data.data.fiStakeholderDetails.stakeholderAddress.ownedYN);
  //         let resiAdd =    'Address: ' + response.data.data.fiStakeholderDetails.stakeholderAddress.line1 + '\n       ' + response.data.data.fiStakeholderDetails.stakeholderAddress.line2;
  //         resiAdd += '\n'+ 'Landmark:' + response.data.data.fiStakeholderDetails.stakeholderAddress.landmark; 
  //         resiAdd += '\n'+ 'City:    ' +  response.data.data.fiStakeholderDetails.stakeholderAddress.city;
  //         resiAdd += '\n'+ 'Area:    ' + response.data.data.fiStakeholderDetails.stakeholderAddress.areaName; 
  //         resiAdd += '\n'+ 'State:   ' +  response.data.data.fiStakeholderDetails.stakeholderAddress.state;
  //         resiAdd += '\n'+ 'Pincode: ' + response.data.data.fiStakeholderDetails.stakeholderAddress.pinCode; 
  //         setresiAddress(resiAdd);
  //         setdirName( response.data.data.fiStakeholderDetails.firstName + " " + response.data.data.fiStakeholderDetails.lastName );
  //         getLocation();

  //         fistatusarray = (response.data.data.fiStatus);
  //         var fistatlen = fistatusarray.length;
          
  //         if (fistatlen > 0){
  //            for (let fii = 0; fii < fistatlen; fii++){
  //               switch (fistatusarray[fii].fiType){
  //                 case 'FACTORY':
  //                   {
  //                     setfiStatFact(fistatusarray[fii].fiStatus);  
  //                     //console.log(fiStatFact);
  //                     break;
  //                   }
  //                 case 'OFFICE':
  //                   {
  //                     setfiStatOffice(fistatusarray[fii].fiStatus);
  //                     //console.log(fiStatOFfice);
  //                     break;
  //                   }
  //                 case 'RESIDENCE':
  //                   {
  //                     setfiStatResi(fistatusarray[fii].fiStatus);
  //                     //console.log(fiStatResi);  
  //                     break;
  //                   }
  //               }
  //           } 
  //       } 
  //     }
  //     })
  //     .catch((error) => {
  //     });

  // }


  function removefiler(fileno) {
    switch (fileno){
      case 1:
        {
          setImgData1r(null);
          settitle1r('');
          document.getElementById('img1Titler').value='';          
          break;
        }
      case 2:
        {
          setImgData2r(null);
          settitle2r('');
          document.getElementById('img2Titler').value='';   
          break;
        }
      case 3:
        {
          setImgData3r(null);
          settitle3r('');
          document.getElementById('img3Titler').value='';   
          break;
        }
      case 4:
        {
          setImgData4r(null);
          settitle4r('');
          document.getElementById('img4Titler').value='';   
          break;
        }
      default:
        return false;
    }
  }


  function removefilef(fileno) {
    switch (fileno){
      case 1:
        {
          setImgData1f(null);
          settitle1f('');
          document.getElementById('img1Titlef').value='';          
          break;
        }
      case 2:
        {
          setImgData2f(null);
          settitle2f('');
          document.getElementById('img2Titlef').value='';   
          break;
        }
      case 3:
        {
          setImgData3f(null);
          settitle3f('');
          document.getElementById('img3Titlef').value='';   
          break;
        }
      case 4:
        {
          setImgData4f(null);
          settitle4f('');
          document.getElementById('img4Titlef').value='';   
          break;
        }
      default:
        return false;
    }
  }



  function removefile(fileno) {
    switch (fileno){
      case 1:
        {
          setImgData1(null);
          settitle1('');
          document.getElementById('img1Title').value='';          
          break;
        }
      case 2:
        {
          setImgData2(null);
          settitle2('');
          document.getElementById('img2Title').value='';   
          break;
        }
      case 3:
        {
          setImgData3(null);
          settitle3('');
          document.getElementById('img3Title').value='';   
          break;
        }
      case 4:
        {
          setImgData4(null);
          settitle4('');
          document.getElementById('img4Title').value='';   
          break;
        }
      default:
        return false;
    }
  }

const changetitle1  = (e) => {
  settitle1(e.target.value);
}

const changetitle2  = (e) => {
  settitle2(e.target.value);
}
const changetitle3  = (e) => {
  settitle3(e.target.value);
}

const changetitle4  = (e) => {
  settitle4(e.target.value);
}

const changetitlef1  = (e) => {
  settitle1f(e.target.value);
}

const changetitlef2  = (e) => {
  settitle2f(e.target.value);
}
const changetitlef3  = (e) => {
  settitle3f(e.target.value);
}

const changetitlef4  = (e) => {
  settitle4f(e.target.value);
}


const changetitler1  = (e) => {
  settitle1r(e.target.value);
}

const changetitler2  = (e) => {
  settitle2r(e.target.value);
}
const changetitler3  = (e) => {
  settitle3r(e.target.value);
}

const changetitler4  = (e) => {
  settitle4r(e.target.value);
}



const onChangePicture1 = e => {
  var filtype;
  if (e.target.files[0]) {
    filtype = e.target.files[0].type;
    const filetype = (filtype.split('/'));
    setftype1(filetype[1]);
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      setImgData1(reader.result);
    });
    reader.readAsDataURL(e.target.files[0]);
  }
};

const onChangePicturef1 = e => {
  var filtype;
  if (e.target.files[0]) {
    filtype = e.target.files[0].type;
    const filetype = (filtype.split('/'));
    setftype1f(filetype[1]);
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      setImgData1f(reader.result);
    });
    reader.readAsDataURL(e.target.files[0]);
  }
};

const onChangePicturer1 = e => {
  var filtype;
  if (e.target.files[0]) {
    filtype = e.target.files[0].type;
    const filetype = (filtype.split('/'));
    setftype1r(filetype[1]);
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      setImgData1r(reader.result);
    });
    reader.readAsDataURL(e.target.files[0]);
  }
};



const onChangePicture2 = e => {
  var filtype;
  if (e.target.files[0]) {
    filtype = e.target.files[0].type;
    const filetype = (filtype.split('/'));
    setftype2(filetype[1]);
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      setImgData2(reader.result);
    });
    reader.readAsDataURL(e.target.files[0]);
  }
};

const onChangePicturef2 = e => {
  var filtype;
  if (e.target.files[0]) {
    filtype = e.target.files[0].type;
    const filetype = (filtype.split('/'));
    setftype2f(filetype[1]);
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      setImgData2f(reader.result);
    });
    reader.readAsDataURL(e.target.files[0]);
  }
};

const onChangePicturer2 = e => {
  var filtype;
  if (e.target.files[0]) {
    filtype = e.target.files[0].type;
    const filetype = (filtype.split('/'));
    setftype2r(filetype[1]);
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      setImgData2r(reader.result);
    });
    reader.readAsDataURL(e.target.files[0]);
  }
};

const onChangePicture3 = e => {
  var filtype;
  if (e.target.files[0]) {
    filtype = e.target.files[0].type;
    const filetype = (filtype.split('/'));
    setftype3(filetype[1]);
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      setImgData3(reader.result);
    });
    reader.readAsDataURL(e.target.files[0]);
  }
};

const onChangePicturef3 = e => {
  var filtype;
  if (e.target.files[0]) {
    filtype = e.target.files[0].type;
    const filetype = (filtype.split('/'));
    setftype3f(filetype[1]);
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      setImgData3f(reader.result);
    });
    reader.readAsDataURL(e.target.files[0]);
  }
};

const onChangePicturer3 = e => {
  var filtype;
  if (e.target.files[0]) {
    filtype = e.target.files[0].type;
    const filetype = (filtype.split('/'));
    setftype3r(filetype[1]);
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      setImgData3r(reader.result);
    });
    reader.readAsDataURL(e.target.files[0]);
  }
};


const onChangePicture4 = e => {
  var filtype;
  if (e.target.files[0]) {
    filtype = e.target.files[0].type;
    const filetype = (filtype.split('/'));
    setftype4(filetype[1]);
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      setImgData4(reader.result);
    });
    reader.readAsDataURL(e.target.files[0]);
  }
};


const onChangePicturef4 = e => {
  var filtype;
  if (e.target.files[0]) {
    filtype = e.target.files[0].type;
    const filetype = (filtype.split('/'));
    setftype4f(filetype[1]);
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      setImgData4f(reader.result);
    });
    reader.readAsDataURL(e.target.files[0]);
  }
};

const onChangePicturer4 = e => {
  var filtype;
  if (e.target.files[0]) {
    filtype = e.target.files[0].type;
    const filetype = (filtype.split('/'));
    setftype4r(filetype[1]);
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      setImgData4r(reader.result);
    });
    reader.readAsDataURL(e.target.files[0]);
  }
};

const changenameofpersonmetf = (e) => {
  setpersonmetf(e.target.value);
}


const changenameofpersonmetr = (e) => {
  setpersonmetr(e.target.value);
}

const changenameofpersonmet = (e) => {
  setpersonmet(e.target.value);
}

const changeofflocationofoffice = (e) => {
  setlocationofoffice(e.target.value);
}

const changeofflocationofofficef = (e) => {
  setlocationofofficef(e.target.value);
}

const changeofflocationofofficer = (e) => {
  setlocationofofficer(e.target.value);
}


const changenoofemployee = (e) => {
  setnoofemployee(e.target.value);
}

const changenoofemployeef = (e) => {
  setnoofemployeef(e.target.value);
}


const changedesigofpersonmetf = (e) => {
  setdesignationmetf(e.target.value);
}


const changedesigofpersonmet = (e) => {
  setdesignationmet(e.target.value);
}


const handleChangefactownlocation = (e) => {
  setfactownlocation(e.target.value);
  
  document.getElementById("factlocationcomment").placeholder = "";
  if ((document.getElementById("factlocationcomment").value).trim() ===""){ 
    if (e.target.value === "N") {
      document.getElementById("factlocationcomment").placeholder = "This is mandatory if your response is 'No'";
    }
  }
}

const handleChangeresiownlocation = (e) => {
  setresiownlocation(e.target.value);
  
  document.getElementById("resilocationcomment").placeholder = "";
  if ((document.getElementById("resilocationcomment").value).trim() ===""){ 
    if (e.target.value === "N") {
      document.getElementById("resilocationcomment").placeholder = "This is mandatory if your response is 'No'";
    }
  }
}

 const handleChangeofflocation = (e) => {
  setofflocation(e.target.value);
  document.getElementById("offlocationcomment").placeholder = "";
  if ((document.getElementById("offlocationcomment").value).trim() ===""){ 
    if (e.target.value === "N") {
      document.getElementById("offlocationcomment").placeholder = "This is mandatory if your response is 'No'";
    }
  }
}

const handleChangerecoffF = (e) => {
  setrecoffF(e.target.value);
  document.getElementById("recoffcommentf").placeholder = "";
  if ((document.getElementById("recoffcommentf").value).trim() ===""){ 
    if (e.target.value === "N") {
      document.getElementById("recoffcommentf").placeholder = "This is mandatory if your response is 'No'";
    }
  }
}

const handleChangerecoffr = (e) => {
  setrecoffr(e.target.value);
  document.getElementById("recoffcommentr").placeholder = "";
  if ((document.getElementById("recoffcommentr").value).trim() ===""){ 
    if (e.target.value === "N") {
      document.getElementById("recoffcommentr").placeholder = "This is mandatory if your response is 'No'";
    }
  }
}


 const handleChangerecoff = (e) => {
  setrecoff(e.target.value);
  document.getElementById("recoffcomment").placeholder = "";
  if ((document.getElementById("recoffcomment").value).trim() ===""){ 
    if (e.target.value === "N") {
      document.getElementById("recoffcomment").placeholder = "This is mandatory if your response is 'No'";
    }
  }
}

const changegeneralappearance =(e) =>{
  setgeneralappearnce(e.target.value);
}


const changegeneralappearancef =(e) =>{
  setgeneralappearncef(e.target.value);
}

const changegeneralappearancer =(e) =>{
  setgeneralappearncer(e.target.value);
}


const changeverifiercomment =(e) =>{
  setverifiercomment(e.target.value);
}

const changeverifiercommentf =(e) =>{
  setverifiercommentf(e.target.value);
}

const changeverifiercommentr =(e) =>{
  setverifiercommentr(e.target.value);
}

const changeorgnamecomment =(e) =>{
  setorNamComment(e.target.value);
}


const changeorgnameFcomment =(e) =>{
  setorNamFComment(e.target.value);
}
const changeorgnameRcomment =(e) =>{
  setorNamRComment(e.target.value);
}

const changeoffaddcomment=(e)=>{
  setoffAddressComment(e.target.value);
}

const changedrNamecomment=(e)=>{
  setdrNameComment(e.target.value);
}

const changefacaddcomment=(e)=>{
  setfacAddressComment(e.target.value);
}

const changeresaddcomment=(e)=>{
  setresiAddressComment(e.target.value);
}

const changeofflocationcomment=(e)=>{
  setofflocationComent(e.target.value);
}

const changefactlocationcomment=(e)=>{
  setfactlocationComent(e.target.value);
}

const changeresilocationcomment=(e)=>{
  setresilocationComent(e.target.value);
}



const changerecoffcomment=(e)=>{
  setrecoffcomment(e.target.value);
}

const changerecoffcommentf=(e)=>{
  setrecoffcommentf(e.target.value);
}

const changerecoffcommentr=(e)=>{
  setrecoffcommentr(e.target.value);
}

const handleChangeorNameF = (e) => {
  setorNameF(e.target.value);
  document.getElementById("orgnameFcomment").placeholder = "";
  if ((document.getElementById("orgnameFcomment").value).trim() ===""){ 
    if (e.target.value === "N") {
      document.getElementById("orgnameFcomment").placeholder = "This is mandatory if your response is 'No'";
    }
  }
}

const handleChangeorNameR = (e) => {
  setorNameR(e.target.value);
  document.getElementById("orgnameRcomment").placeholder = "";
  if ((document.getElementById("orgnameRcomment").value).trim() ===""){ 
    if (e.target.value === "N") {
      document.getElementById("orgnameRcomment").placeholder = "This is mandatory if your response is 'No'";
    }
  }
}


const handleChangeorName = (e) => {
  setorName(e.target.value);
  document.getElementById("orgnamecomment").placeholder = "";
  if ((document.getElementById("orgnamecomment").value).trim() ===""){ 
    if (e.target.value === "N") {
      document.getElementById("orgnamecomment").placeholder = "This is mandatory if your response is 'No'";
    }
  }
}

  // this function will be called when a radio button is checked
  const handleChangeOrgRes = (e) => {
    setorgRes(e.target.value);
    document.getElementById("orgcomment").placeholder = "";
    if ((document.getElementById("orgcomment").value).trim() ===""){ 
      if (e.target.value === "N") {
        document.getElementById("orgcomment").placeholder = "This is mandatory if your response is 'No'";
      }
    }
  }

  // this function will be called when a radio button is checked
  const handleChangecommadd = (e) => {
    setcommaddRes(e.target.value);
    document.getElementById("comaddcomment").placeholder = "";
    if ((document.getElementById("comaddcomment").value).trim() ===""){ 
      if (e.target.value === "N") {
        document.getElementById("comaddcomment").placeholder = "This is mandatory if your response is 'No'";
      }
    }
  }

  // this function will be called when a radio button is checked
  const handleChangeoffadd = (e) => {
    setoffAdrres(e.target.value);
    document.getElementById("offaddcomment").placeholder = "";
    if ((document.getElementById("offaddcomment").value).trim() ===""){ 
      if (e.target.value === "N") {
        document.getElementById("offaddcomment").placeholder = "This is mandatory if your response is 'No'";
      }
    }
  }

  
  const handleChangedName = (e) => {
      setdrName(e.target.value);
    document.getElementById("drNameComment").placeholder = "";
    if ((document.getElementById("drNameComment").value).trim() ===""){ 
      if (e.target.value === "N") {
        document.getElementById("drNameComment").placeholder = "This is mandatory if your response is 'No'";
      }
    }
  }

  
  const handleChangeresiadd = (e) => {
    //setresAddress(e.target.value);
    setresAddress(e.target.value);
    //console.log(e.target.value);
    document.getElementById("resAddressComment").placeholder = "";
    if ((document.getElementById("resAddressComment").value).trim() ===""){ 
      if (e.target.value === "N") {
        document.getElementById("resAddressComment").placeholder = "This is mandatory if your response is 'No'";
      }
    }
  }

  
  const handleChangefacadd = (e) => {
    setfacAddress(e.target.value);
    document.getElementById("facAddressComment").placeholder = "";
    if ((document.getElementById("facAddressComment").value).trim() ===""){ 
      if (e.target.value === "N") {
        document.getElementById("facAddressComment").placeholder = "This is mandatory if your response is 'No'";
      }
    }
  }

  // this function will be called when a radio button is checked
  const handleChangeShipadd = (e) => {
    setShipAddRes(e.target.value);
    document.getElementById("shipaddcomment").placeholder = "";
    if ((document.getElementById("shipaddcomment").value).trim() ===""){ 
      if (e.target.value === "N") {
        document.getElementById("shipaddcomment").placeholder = "This is mandatory if your response is 'No'";
      }
    }
  }
  const handleChangeMobile = (e) => {
    setMobile(e.target.value);
    document.getElementById("mobcomment").placeholder = "";
    if ((document.getElementById("mobcomment").value).trim() ===""){ 
      if (e.target.value === "N") {
        document.getElementById("mobcomment").placeholder = "This is mandatory if your response is 'No'";
      }
    }
  }
  
  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
    document.getElementById("emailcomment").placeholder = "";
    if ((document.getElementById("emailcomment").value).trim() ===""){ 
      if (e.target.value === "N") {
        document.getElementById("emailcomment").placeholder = "This is mandatory if your response is 'No'";
      }
    }
  }
  
  const handleChangeFirst = (e) => {
    setFirst(e.target.value);
    document.getElementById("firstcomment").placeholder = "";
    if ((document.getElementById("firstcomment").value).trim() ===""){ 
      if (e.target.value === "N") {
        document.getElementById("firstcomment").placeholder = "This is mandatory if your response is 'No'";
      }
    }
  }
  
  const handleChangeLast = (e) => {
    setLast(e.target.value);
    document.getElementById("lastcomment").placeholder = "";
    if ((document.getElementById("lastcomment").value).trim() ===""){ 
      if (e.target.value === "N") {
        document.getElementById("lastcomment").placeholder = "This is mandatory if your response is 'No'";
      }
    }
  }
  const handleChangedob = (e) => {
    setDob(e.target.value);
    document.getElementById("dobcomment").placeholder = "";
    if ((document.getElementById("dobcomment").value).trim() ===""){ 
      if (e.target.value === "N") {
        document.getElementById("dobcomment").placeholder = "This is mandatory if your response is 'No'";
      }
    }
  }
  const handleChangedMobile = (e) => {
    setdMobile(e.target.value);
    document.getElementById("dmobilecomment").placeholder = "";
    if ((document.getElementById("dmobilecomment").value).trim() ===""){ 
      if (e.target.value === "N") {
        document.getElementById("dmobilecomment").placeholder = "This is mandatory if your response is 'No'";
      }
    }
  }
  const handleChangedEmail = (e) => {
    setdEmail(e.target.value);
    document.getElementById("demailcomment").placeholder = "";
    if ((document.getElementById("demailcomment").value).trim() ===""){ 
      if (e.target.value === "N") {
        document.getElementById("demailcomment").placeholder = "This is mandatory if your response is 'No'";
      }
    }
  }

  const handleChangedAdd1 = (e) => {
    setdAdd1(e.target.value);
    document.getElementById("dadd1comment").placeholder = "";
    if ((document.getElementById("dadd1comment").value).trim() ===""){ 
      if (e.target.value === "N") {
        document.getElementById("dadd1comment").placeholder = "This is mandatory if your response is 'No'";
      }
    }
  }
  
  const handleChangedAdd = (e) => {
    setdAdd(e.target.value);
    document.getElementById("daddcomment").placeholder = "";
    if ((document.getElementById("daddcomment").value).trim() ===""){ 
      if (e.target.value === "N") {
        document.getElementById("daddcomment").placeholder = "This is mandatory if your response is 'No'";
      }
    }
  }
  
  const handleChangedCity = (e) => {
    setdCity(e.target.value);
    document.getElementById("dcitycomment").placeholder = "";
    if ((document.getElementById("dcitycomment").value).trim() ===""){ 
      if (e.target.value === "N") {
        document.getElementById("dcitycomment").placeholder = "This is mandatory if your response is 'No'";
      }
    }
  }

  const handleChangedState = (e) => {
    setdState(e.target.value);
    document.getElementById("dstatecomment").placeholder = "";
    if ((document.getElementById("dstatecomment").value).trim() ===""){ 
      if (e.target.value === "N") {
        document.getElementById("dstatecomment").placeholder = "This is mandatory if your response is 'No'";
      }
    }
  }
  
  const handleChangedPin = (e) => {
    setdPin(e.target.value);
    document.getElementById("dpincomment").placeholder = "";
    if ((document.getElementById("dpincomment").value).trim() ===""){ 
      if (e.target.value === "N") {
        document.getElementById("dpincomment").placeholder = "This is mandatory if your response is 'No'";
      }
    }
  }

  const handleChangeRecom = (e) => {
    setRecom(e.target.value);
  }
  const handleSubmit = () => {
      var errorText = "" ;

      if ((offlocation === "N") && (document.getElementById('offlocationcomment').value.trim()==="")) {
        errorText = "Office Location : 'Observation' is mandatory if your response is 'No'\n\n";
      }
        
      if ((orgRes === "N") && (document.getElementById('orgcomment').value.trim()==="")) {
          errorText = "Organization Name : 'Observation' is mandatory if your response is 'No'\n\n";
      }
      if ((commaddRes === "N") && (document.getElementById('comaddcomment').value.trim()==="")) {
        errorText += "Communication Address : 'Observation' is mandatory if your response is 'No'\n\n";
      }
      if ((offAdrres === "N") && (document.getElementById('offaddcomment').value.trim()==="")) {
        errorText += "Office Address : 'Observation' is mandatory if your response is 'No'\n\n";
      }
      if ((ShipAddRes === "N") && (document.getElementById('shipaddcomment').value.trim()==="")) {
        errorText += "Shipping Address : 'Observation' is mandatory if your response is 'No'\n\n";
      }
    
      if ((Mobile === "N") && (document.getElementById('mobcomment').value.trim()==="")) {
        errorText += "Mobile : 'Observation' is mandatory if your response is 'No'\n\n";
      }
      if ((Email === "N") && (document.getElementById('emailcomment').value.trim()==="")) {
        errorText += "Email : 'Observation' is mandatory if your response is 'No'\n\n";
      }

      if ((First === "N") && (document.getElementById('firstcomment').value.trim()==="")) {
        errorText += "First Name : 'Observation' is mandatory if your response is 'No'\n\n";
      }

      if ((Last === "N") && (document.getElementById('lastcomment').value.trim()==="")) {
        errorText += "Last Name : 'Observation' is mandatory if your response is 'No'\n\n";
      }
      if ((Dob === "N") && (document.getElementById('dobcomment').value.trim()==="")) {
        errorText += "Date of Birth : 'Observation' is mandatory if your response is 'No'\n\n";
      }
      if ((dMobile === "N") && (document.getElementById('dmobilecomment').value.trim()==="")) {
        errorText += "Director Mobile : 'Observation' is mandatory if your response is 'No'\n\n";
      }

      if ((dEmail === "N") && (document.getElementById('demailcomment').value.trim()==="")) {
        errorText += "Director Email : 'Observation' is mandatory if your response is 'No'\n\n";
      }
      if ((dAdd1 === "N") && (document.getElementById('dadd1comment').value.trim()==="")) {
        errorText += "Director Address Line1 : 'Observation' is mandatory if your response is 'No'\n\n";
      }
      if ((dAdd === "N") && (document.getElementById('daddcomment').value.trim()==="")) {
        errorText += "Director Address : 'Observation' is mandatory if your response is 'No'\n\n";
      } 
      if ((dCity === "N") && (document.getElementById('dcitycomment').value.trim()==="")) {
        errorText += "Director Address City: 'Observation' is mandatory if your response is 'No'\n\n";
      } 

      if ((dState === "N") && (document.getElementById('dstatecomment').value.trim()==="")) {
        errorText += "Director Address State: 'Observation' is mandatory if your response is 'No'\n\n";
      } 

      if ((dPin === "N") && (document.getElementById('dpincomment').value.trim()==="")) {
        errorText += "Director Address Pin: 'Observation' is mandatory if your response is 'No'\n\n";
      } 
      if (document.getElementById('observations').value.trim()==="") {
        errorText += "'Your Observation' is mandatory\n\n";
      } 

      if (document.getElementById('reccomments').value.trim()==="") {
        errorText += "'Comment for recommendation' is mandatory\n\n";
      } 
      if (document.getElementById('answer1').value.trim()==="") {
        errorText += "'Answer for question 1' is mandatory\n\n";
      } 
      if (document.getElementById('answer2').value.trim()==="") {
        errorText += "'Answer for question 2' is mandatory\n\n";
      } 
      if (document.getElementById('answer3').value.trim()==="") {
        errorText += "'Answer for question 3' is mandatory\n\n";
      } 
      if (document.getElementById('answer4').value.trim()==="") {
        errorText += "'Answer for question 4' is mandatory\n\n";
      } 
      if (document.getElementById('answer5').value.trim()==="") {
        errorText += "'Answer for question 5' is mandatory\n\n";
      } 
      if (errorText.trim() !== "") {
          alert(errorText);
          return false;
      }
      //alert(orgResponse);

      //alert(useraction);
      //alert(userreason);
/*         if ((userreason == "") && (useraction == "REJ" || useraction=="QRY"))  {
          alert("Reason for Rejection or Query details is required");   
          return false;  
      }

      let data = JSON.stringify({
        "query": ""+userreason+""
      });
      
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://yarnbizqa.4fin.in/apigateway/statusapi/credit/sanction/details/539?status='+useraction,
        headers: { 
          'client-id': 'STATU0004200016', 
          'client-secret': 'QOetBBBHrU5jLGvSBE1QiuhVk65NhOEgt3a4qGcy32A=', 
          'Accept': 'application/json', 
          'Content-Type': 'application/json'
        },
        data : data
      };
      
      axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));

        if (JSON.stringify(response.data.responseCode)==200){
          //sanctionLetter = response.data.sanctionLetter;
          alert("Your response submitted successfully, please close this windows");
          
      }else{
        alert("Facing some issue in getting details, please retry again!!!")
      }
      })
      .catch((error) => {
        console.log(error);
        alert("Facing some issue in getting details, please retry again!!!")
      });
*/        
      
      //Push details to API
      
    //history.push('/');
    //store session details
  }

  function getLocation() {
    //console.log('inside navigator');
    if (navigator.geolocation) {
      //console.log('inside function');
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      setgeoLocation('Geolocation is not supported by this browser.');
    }
   // console.log('outside function');
  }

  function showPosition(position) {
    setgeoLocation('Latitude: ' + position.coords.latitude + ' and Longitude: ' + position.coords.longitude);
    setlati(position.coords.latitude );
    setlong(position.coords.longitude );
  }


  function generate() {  
    var doc = new jsPDF('p', 'pt', 'letter');  
    var htmlstring = '';  
    var tempVarToCheckPageHeight = 0;  
    var pageHeight = 0;  
    pageHeight = doc.internal.pageSize.height; 
    var pageWidth = doc.internal.pageSize.width; 
    var specialElementHandlers = {  
        // element with id of "bypass" - jQuery style selector  
        '#bypassme': function(element, renderer) {  
            // true = "handled elsewhere, bypass text extraction"  
            return true  
        }  
    };  
    var margins = {  
        top: 150,  
        bottom: 60,  
        left: 40,  
        right: 40,  
        width: 600  
    };  
    var y = 20;  
    doc.setLineWidth(2);  
    doc.setFontSize(14);
    doc.text(40, y = y + 10, statusheader);  
    doc.setFontSize(12);
    doc.text(40, y = y + 30, "Field Investigation Report for '" + orgName + "'- Office");  
    doc.autoTable({  
        html: '#reportOffice',  
        startY: 70,  
        theme: 'grid',  
        columnStyles: {  
            0: {  
                cellWidth: 23,  
            },  
            1: {  
                cellWidth: 80,  
            },  
            2: {  
                cellWidth: 180,  
            },  
            3: {  
                cellWidth: 70,  
            },
            4: {  
                cellWidth: 180,  
            }    
        },  
        styles: {  
            minCellHeight: 30,
            fontSize:8
        }  
    })  

    if (imgData1!=null){
      doc.addPage();
      doc.setFontSize(14);
      //doc.text('First Image', 0, 0);
      doc.text(30, 30, 'Images:');  

      doc.text(30, 50, title1);  
      //console.log(ftype1);
      if (ftype1 ===''){
          doc.addImage(imgData1,'png', 30, 70, (pageWidth-margins.right-margins.left), 300);
      }else{
        doc.addImage(imgData1,ftype1, 30, 70, (pageWidth-margins.right-margins.left), 300);
      }
    }
    if (imgData2!=null){
      doc.addPage();
      doc.setFontSize(14);
      //doc.text('First Image', 0, 0);
    
      doc.text(30, 30, title2);  
      //console.log(ftype1);
      if (ftype1 ===''){
          doc.addImage(imgData2,'png', 30, 50, (pageWidth-margins.right-margins.left), 300);
      }else{
          doc.addImage(imgData2,ftype1, 30, 50, (pageWidth-margins.right-margins.left), 300);
      }
    }
    if (imgData3!=null){
      doc.addPage();
      doc.setFontSize(14);
      //doc.text('First Image', 0, 0);
    
      doc.text(30, 30, title3);  
      //console.log(ftype1);
      if (ftype1 ===''){
          doc.addImage(imgData3,'png', 30, 50, (pageWidth-margins.right-margins.left), 300);
      }else{
          doc.addImage(imgData3,ftype1, 30, 50, (pageWidth-margins.right-margins.left), 300);
      }
    }
    if (imgData4!=null){
      doc.addPage();
      doc.setFontSize(14);
      //doc.text('First Image', 0, 0);
    
      doc.text(30, 30, title4);  
      //console.log(ftype1);
      if (ftype1 ===''){
          doc.addImage(imgData4,'png', 30, 50, (pageWidth-margins.right-margins.left), 300);
      }else{
          doc.addImage(imgData4,ftype1, 30, 50, (pageWidth-margins.right-margins.left), 300);
      }
    }


    
    var pageCount = doc.internal.getNumberOfPages(); //Total Page Number
    var width = doc.internal.pageSize.getWidth();

    for(var i = 0; i < pageCount; i++) { 
      doc.setPage(i); 
      let pageCurrent = doc.internal.getCurrentPageInfo().pageNumber; //Current Page
      doc.setFontSize(9);
      //console.log(width);
      //console.log(statusheader.length);
      doc.text(30,doc.internal.pageSize.height - 20, "FI Report for '" + orgName  + "'- Office");
      doc.text(width -(statusheader.length + 140),doc.internal.pageSize.height - 20, statusheader);
      doc.text(width/2,  doc.internal.pageSize.height - 20, 'Page: ' + pageCurrent + ' of ' + pageCount,{align: 'center'});

    }
    setpdffile(doc.output('dataurlstring'));

    var base = doc.output('dataurlstring'); // base64 string

    let splitfile =base.split(',');
    let filetouplod = splitfile[1];
    //console.log("base64 is ", base);
    setpdffile(filetouplod);
    setuploadedfile(filetouplod);
    //console.log(splitfile[1]);
    //console.log(splitfile[0]);
    //console.log("pdf");
    //console.log(pdffile);
    //console.log(uploadedfile);
    doc.save('Field_Invest_Office.pdf');  
    //console.log('2');



}  

const savefactoryfi = () => {
  var errorText = "" ;
  var imageupload = false;
  
  if ((orNameF === "N") && (document.getElementById('orgnamefcomment').value.trim()==="")) {
    errorText += "'Organization Name' : 'Observation' is mandatory if your response is 'No'\n\n";
  }    

  if ((facAddress === "N") && (document.getElementById('facAddressComment').value.trim()==="")) {
    errorText += "'Factory Address' : 'Observation' is mandatory if your response is 'No'\n\n";
  }
  

  if ((factownlocation === "N") && (document.getElementById('factlocationcomment').value.trim()==="")) {
    errorText += "'Factory on Owned Premises?': 'Observation' is mandatory if your response is 'No'\n\n";
  }

  if ((personmetf==="")) {
    errorText += "'Person Met' : is mandatory\n\n";
  }    

  if ((designationmetf==="")) {
    errorText += "'Designation' : of person met is mandatory\n\n";
  }    

  if ((noofemployeef==="")) {
    errorText += "'Number of employees working' working in factory : is mandatory\n\n";
  }    

  if ((noofemployeef < 1)) {
    errorText += "'Number of employees working' working in factory : is mandatory and should be more than zero(0)\n\n";
  }    

  if ((locationofofficef==="")) {
    errorText += "'Landmark of Office' : is mandatory\n\n";
  }

  if ((generalappearncef==="")) {
    errorText += "'General Appearance' : is mandatory\n\n";
  }

  if ((verifiercommentf==="")) {
    errorText += "'Verifiers Remarks' : is mandatory\n\n";
  }
  
  if ((recoffF === "N") && (document.getElementById('recoffcommentf').value.trim()==="")) {
    errorText += "'Recommending for Credit?': 'Observation' is mandatory if your response is 'No'\n\n";
  }

  if ((imgData1f !=null) && (title1f==='')){
    errorText += "'Image 1': 'Title' for 'First' image is mandatory as you have uploaded image\n\n";
  }

  if ((imgData2f !=null) && (title2f==='')){
    errorText += "'Image 2': 'Title' for 'Second' image is mandatory as you have uploaded image\n\n";
  }

  if ((imgData3f !=null) && (title3f==='')){
    errorText += "'Image 3': 'Title' for 'Third' image is mandatory as you have uploaded image\n\n";
  }

  if ((imgData4f !=null) && (title4f==='')){
    errorText += "'Image 4': 'Title' for 'Forth' image is mandatory as you have uploaded image\n\n";
  }

  if (imgData1f != null) {
    imageupload = true;
  }

  if (imgData2f != null) {
    imageupload = true;
  }
  if (imgData3f != null) {
    imageupload = true;
  }
  if (imgData4f != null) {
    imageupload = true;
  }


  if (imageupload === false){
    errorText += "'Images': you have not uploaded any image, you need to upload atleast one image\n\n";
  }

  if (errorText.trim() !== "") {
    alert("Please close following errors\n\n"+ errorText);
    return false;
  } 
 
  var doc = new jsPDF('p', 'pt', 'letter');  
  var htmlstring = '';  
  var tempVarToCheckPageHeight = 0;  
  var pageHeight = 0;  
  pageHeight = doc.internal.pageSize.height; 
  var pageWidth = doc.internal.pageSize.width; 
  var specialElementHandlers = {  
      // element with id of "bypass" - jQuery style selector  
      '#bypassme': function(element, renderer) {  
          // true = "handled elsewhere, bypass text extraction"  
          return true  
      }  
  };  
  var margins = {  
      top: 150,  
      bottom: 60,  
      left: 40,  
      right: 40,  
      width: 600  
  };  
  var y = 20;  
  doc.setLineWidth(2);  
  doc.setFontSize(14);
  doc.text(40, y = y + 10, statusheader);  
  doc.setFontSize(12);
  doc.text(40, y = y + 30, "Field Investigation Report for '" + orgName + "'- factory ");  
  doc.autoTable({  
      html: '#reportfactory',  
      startY: 70,  
      theme: 'grid',  
      columnStyles: {  
          0: {  
              cellWidth: 23,  
          },  
          1: {  
              cellWidth: 80,  
          },  
          2: {  
              cellWidth: 180,  
          },  
          3: {  
              cellWidth: 70,  
          },
          4: {  
              cellWidth: 180,  
          }    
      },  
      styles: {  
          minCellHeight: 30,
          fontSize:8
      }  
  })  

  if (imgData1f!=null){
    doc.addPage();
    doc.setFontSize(14);
    //doc.text('First Image', 0, 0);
    doc.text(30, 30, 'Images:');  

    doc.text(30, 50, title1);  
    if (ftype1 ===''){
        doc.addImage(imgData1f,'png', 30, 70, (pageWidth-margins.right-margins.left), 300);
    }else{
      doc.addImage(imgData1f,ftype1, 30, 70, (pageWidth-margins.right-margins.left), 300);
    }
  }
  if (imgData2f!=null){
    doc.addPage();
    doc.setFontSize(14);
    //doc.text('First Image', 0, 0);
  
    doc.text(30, 30, title2);  
    if (ftype1 ===''){
        doc.addImage(imgData2f,'png', 30, 50, (pageWidth-margins.right-margins.left), 300);
    }else{
        doc.addImage(imgData2f,ftype1, 30, 50, (pageWidth-margins.right-margins.left), 300);
    }
  }
  if (imgData3f!=null){
    doc.addPage();
    doc.setFontSize(14);
    //doc.text('First Image', 0, 0);
  
    doc.text(30, 30, title3);  

    if (ftype1 ===''){
        doc.addImage(imgData3f,'png', 30, 50, (pageWidth-margins.right-margins.left), 300);
    }else{
        doc.addImage(imgData3f,ftype1, 30, 50, (pageWidth-margins.right-margins.left), 300);
    }
  }
  if (imgData4f!=null){
    doc.addPage();
    doc.setFontSize(14);
    //doc.text('First Image', 0, 0);
  
    doc.text(30, 30, title4);  

    if (ftype1 ===''){
        doc.addImage(imgData4f,'png', 30, 50, (pageWidth-margins.right-margins.left), 300);
    }else{
        doc.addImage(imgData4f,ftype1, 30, 50, (pageWidth-margins.right-margins.left), 300);
    }
  }


  
  var pageCount = doc.internal.getNumberOfPages(); //Total Page Number
  var width = doc.internal.pageSize.getWidth();

  for(var i = 0; i < pageCount; i++) { 
    doc.setPage(i); 
    let pageCurrent = doc.internal.getCurrentPageInfo().pageNumber; //Current Page
    doc.setFontSize(9);
    doc.text(30,doc.internal.pageSize.height - 20, "FI Report for '" + orgName  + "'- factory");
    doc.text(width -(statusheader.length + 140),doc.internal.pageSize.height - 20, statusheader);
    doc.text(width/2,  doc.internal.pageSize.height - 20, 'Page: ' + pageCurrent + ' of ' + pageCount,{align: 'center'});

  }
  setpdffile(doc.output('dataurlstring'));

  var base = doc.output('dataurlstring'); // base64 string

  let splitfile =base.split(',');
  let filetouplod = splitfile[1];
  doc.save('Field_Invest_factory.pdf');  

  let recom = "N";
  if (recoffF === "Y") {
    recom = "P";
  }
  
  let data = JSON.stringify({
    "leadNo": iLanNo,
    "fiType": "FACTORY",
    "recommStatus": recom,
    "fiStatus": "C",
    "initiateDate": moment(new Date()).format('YYYY-MM-DD'),
    "completeDate": moment(new Date()).format('YYYY-MM-DD'),
    "remark": verifiercommentf,
    "longitude":long,
    "latitude": lati,
    "fiFile": {
      "fileContent": splitfile[1]
     
    }
  });
  

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: savefactapi,
      headers: { 
        'client-id': 'STATU0004200016', 
        'client-secret': 'QOetBBBHrU5jLGvSBE1QiuhVk65NhOEgt3a4qGcy32A=', 
        'Accept': 'application/json', 
        'Content-Type': 'application/json'
      },
      data : data
    };
    axios.request(config)
    .then((response) => {
      //console.log(JSON.stringify(response.data));

      if (JSON.stringify(response.data.responseCode)==200){
        //sanctionLetter = response.data.sanctionLetter;
        alert("Your response submitted successfully, please close this windows");
        
    }else{
      alert("Facing some issue in getting details, please retry again!!!")
    }
    })
    .catch((error) => {
      alert("Facing some issue in getting details, please retry again!!!")
    });
}




const saveresifi = () => {
  var errorText = "" ;
  var imageupload = false;
  
  if ((orNameR === "N") && (document.getElementById('orgnameRcomment').value.trim()==="")) {
    errorText += "'Organization Name' : 'Observation' is mandatory if your response is 'No'\n\n";
  }    

  if ((drName === "N") && (document.getElementById('drNameComment').value.trim()==="")) {
    errorText += "'Director Name' : 'Observation' is mandatory if your response is 'No'\n\n";
  }

  if ((resiAddress === "N") && (document.getElementById('resAddressComment').value.trim()==="")) {
    errorText += "'Residence Address' : 'Observation' is mandatory if your response is 'No'\n\n";
  }
  

  if ((resiownlocation=== "N") && (document.getElementById('resAddressComment').value.trim()==="")) {
    errorText += "'Residence - Owned Premises?': 'Observation' is mandatory if your response is 'No'\n\n";
  }

  if ((personmetr==="")) {
    errorText += "'Person Met' : is mandatory\n\n";
  }    

  if ((locationofofficer==="")) {
    errorText += "'Landmark of Residence' : is mandatory\n\n";
  }

  if ((generalappearncer==="")) {
    errorText += "'General Appearance' : is mandatory\n\n";
  }

  if ((verifiercommentr==="")) {
    errorText += "'Verifiers Remarks' : is mandatory\n\n";
  }
  
  if ((recoffr === "N") && (document.getElementById('recoffcommentr').value.trim()==="")) {
    errorText += "'Recommending for Credit?': 'Observation' is mandatory if your response is 'No'\n\n";
  }

  if ((imgData1r !=null) && (title1r==='')){
    errorText += "'Image 1': 'Title' for 'First' image is mandatory as you have uploaded image\n\n";
  }

  if ((imgData2r !=null) && (title2r==='')){
    errorText += "'Image 2': 'Title' for 'Second' image is mandatory as you have uploaded image\n\n";
  }

  if ((imgData3r !=null) && (title3r==='')){
    errorText += "'Image 3': 'Title' for 'Third' image is mandatory as you have uploaded image\n\n";
  }

  if ((imgData4r !=null) && (title4r==='')){
    errorText += "'Image 4': 'Title' for 'Forth' image is mandatory as you have uploaded image\n\n";
  }

  if (imgData1r != null) {
    imageupload = true;
  }

  if (imgData2r != null) {
    imageupload = true;
  }
  if (imgData3r != null) {
    imageupload = true;
  }
  if (imgData4r != null) {
    imageupload = true;
  }


  if (imageupload === false){
    errorText += "'Images': you have not uploaded any image, you need to upload atleast one image\n\n";
  }

  if (errorText.trim() !== "") {
    alert("Please close following errors\n\n"+ errorText);
    return false;
  } 
 
  var doc = new jsPDF('p', 'pt', 'letter');  
  var htmlstring = '';  
  var tempVarToCheckPageHeight = 0;  
  var pageHeight = 0;  
  pageHeight = doc.internal.pageSize.height; 
  var pageWidth = doc.internal.pageSize.width; 
  var specialElementHandlers = {  
      // element with id of "bypass" - jQuery style selector  
      '#bypassme': function(element, renderer) {  
          // true = "handled elsewhere, bypass text extraction"  
          return true  
      }  
  };  
  var margins = {  
      top: 150,  
      bottom: 60,  
      left: 40,  
      right: 40,  
      width: 600  
  };  
  var y = 20;  
  doc.setLineWidth(2);  
  doc.setFontSize(14);
  doc.text(40, y = y + 10, statusheader);  
  doc.setFontSize(12);
  doc.text(40, y = y + 30, "Field Investigation Report for '" + orgName + "'- Director Residence ");  
  doc.autoTable({  
      html: '#reportresi',  
      startY: 70,  
      theme: 'grid',  
      columnStyles: {  
          0: {  
              cellWidth: 23,  
          },  
          1: {  
              cellWidth: 80,  
          },  
          2: {  
              cellWidth: 180,  
          },  
          3: {  
              cellWidth: 70,  
          },
          4: {  
              cellWidth: 180,  
          }    
      },  
      styles: {  
          minCellHeight: 30,
          fontSize:8
      }  
  })  

  if (imgData1r!=null){
    doc.addPage();
    doc.setFontSize(14);
    //doc.text('First Image', 0, 0);
    doc.text(30, 30, 'Images:');  

    doc.text(30, 50, title1);  
    if (ftype1 ===''){
        doc.addImage(imgData1r,'png', 30, 70, (pageWidth-margins.right-margins.left), 300);
    }else{
      doc.addImage(imgData1r,ftype1, 30, 70, (pageWidth-margins.right-margins.left), 300);
    }
  }
  if (imgData2r!=null){
    doc.addPage();
    doc.setFontSize(14);
    //doc.text('First Image', 0, 0);
  
    doc.text(30, 30, title2);  
    if (ftype1 ===''){
        doc.addImage(imgData2r,'png', 30, 50, (pageWidth-margins.right-margins.left), 300);
    }else{
        doc.addImage(imgData2r,ftype1, 30, 50, (pageWidth-margins.right-margins.left), 300);
    }
  }
  if (imgData3r!=null){
    doc.addPage();
    doc.setFontSize(14);
    //doc.text('First Image', 0, 0);
  
    doc.text(30, 30, title3);  

    if (ftype1 ===''){
        doc.addImage(imgData3r,'png', 30, 50, (pageWidth-margins.right-margins.left), 300);
    }else{
        doc.addImage(imgData3r,ftype1, 30, 50, (pageWidth-margins.right-margins.left), 300);
    }
  }
  if (imgData4r!=null){
    doc.addPage();
    doc.setFontSize(14);
    //doc.text('First Image', 0, 0);
  
    doc.text(30, 30, title4);  

    if (ftype1 ===''){
        doc.addImage(imgData4r,'png', 30, 50, (pageWidth-margins.right-margins.left), 300);
    }else{
        doc.addImage(imgData4r,ftype1, 30, 50, (pageWidth-margins.right-margins.left), 300);
    }
  }


  
  var pageCount = doc.internal.getNumberOfPages(); //Total Page Number
  var width = doc.internal.pageSize.getWidth();

  for(var i = 0; i < pageCount; i++) { 
    doc.setPage(i); 
    let pageCurrent = doc.internal.getCurrentPageInfo().pageNumber; //Current Page
    doc.setFontSize(9);
    doc.text(30,doc.internal.pageSize.height - 20, "FI Report for '" + orgName  + "'- Director Residence");
    doc.text(width -(statusheader.length + 140),doc.internal.pageSize.height - 20, statusheader);
    doc.text(width/2,  doc.internal.pageSize.height - 20, 'Page: ' + pageCurrent + ' of ' + pageCount,{align: 'center'});

  }
  setpdffile(doc.output('dataurlstring'));

  var base = doc.output('dataurlstring'); // base64 string

  let splitfile =base.split(',');
  let filetouplod = splitfile[1];
  doc.save('Field_Invest_residence.pdf');  
  
  let recom = "N";
  if (recoffr === "Y") {
    recom = "P";
  }
  let data = JSON.stringify({
    "leadNo": iLanNo,
    "fiType": "RES",
    "recommStatus": recom,
    "fiStatus": "C",
    "initiateDate": moment(new Date()).format('YYYY-MM-DD'),
    "completeDate": moment(new Date()).format('YYYY-MM-DD'),
    "remark": verifiercommentr,
    "longitude":long,
    "latitude": lati,
    "fiFile": {
      "fileContent": splitfile[1]
     
    }
  });
  

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: saveresiapi,
      headers: { 
        'client-id': 'STATU0004200016', 
        'client-secret': 'QOetBBBHrU5jLGvSBE1QiuhVk65NhOEgt3a4qGcy32A=', 
        'Accept': 'application/json', 
        'Content-Type': 'application/json'
      },
      data : data
    };
    axios.request(config)
    .then((response) => {
      if (JSON.stringify(response.data.responseCode)==200){
        alert("Your response submitted successfully, please close this windows");
        
    }else{
      alert("Facing some issue in getting details, please retry again!!!")
    }
    })
    .catch((error) => {
      alert("Facing some issue in getting details, please retry again!!!")
    });
}

const handleSubmitFI = e => {
  var doc = new jsPDF('p', 'pt', 'letter');  
  var htmlstring = '';  
  var tempVarToCheckPageHeight = 0;  
  var pageHeight = 0;  
  pageHeight = doc.internal.pageSize.height; 
  var pageWidth = doc.internal.pageSize.width; 
  var specialElementHandlers = {  
      // element with id of "bypass" - jQuery style selector  
      '#bypassme': function(element, renderer) {  
          // true = "handled elsewhere, bypass text extraction"  
          return true  
      }  
  };  
  var margins = {  
      top: 150,  
      bottom: 60,  
      left: 40,  
      right: 40,  
      width: 600  
  };  
  var y = 20;  
  doc.setLineWidth(2);  
  doc.setFontSize(14);
  doc.text(40, y = y + 10, statusheader);  
  doc.setFontSize(12);
  doc.text(40, y = y + 30, "Bypass Field Investigation Report for " + orgName);  
  doc.autoTable({  
      html: '#byreport',  
      startY: 70,  
      theme: 'grid',  
      columnStyles: {  
          0: {  
              cellWidth: 23,  
          },  
          1: {  
              cellWidth: 80,  
          },  
          2: {  
              cellWidth: 180,  
           },  
          // 3: {  
          //     cellWidth: 70,  
          // },
          // 4: {  
          //     cellWidth: 180,  
          // }    
      },  
      styles: {  
          minCellHeight: 30,
          fontSize:8
      }  
  })  
    

  var pageCount = doc.internal.getNumberOfPages(); //Total Page Number
  var width = doc.internal.pageSize.getWidth();

  for(var i = 0; i < pageCount; i++) { 
    doc.setPage(i); 
    let pageCurrent = doc.internal.getCurrentPageInfo().pageNumber; //Current Page
    doc.setFontSize(8);
    doc.text(30,doc.internal.pageSize.height - 20, "Bypass FI Report for " + orgName  );
    doc.text(width -(statusheader.length + 140),doc.internal.pageSize.height - 20, statusheader);
    doc.text(width/2,  doc.internal.pageSize.height - 20, 'Page: ' + pageCurrent + ' of ' + pageCount,{align: 'center'});

  }
  setpdffile(doc.output('dataurlstring'));

  var base = doc.output('dataurlstring'); // base64 string

  let splitfile =base.split(',');
  let filetouplod = splitfile[1];
  doc.save('BypassField_Invest_Office.pdf');  

  let fisuccess = false;
  let recom = "P";
  let data;
  let config;

  data = JSON.stringify({
    "leadNo": mylan,
    "fiType": "OFFICE",
    "recommStatus": recom,
    "fiStatus": "C",
    "initiateDate": moment(new Date()).format('YYYY-MM-DD'),
    "completeDate": moment(new Date()).format('YYYY-MM-DD'),
    "remark": 'ByPass FI For Office',
    "longitude":long,
    "latitude": lati,
    "fiFile": {
      "fileContent": splitfile[1]
    }
  });
  

  config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: saveofficeapi,
      headers: { 
        'client-id': 'STATU0004200016', 
        'client-secret': 'QOetBBBHrU5jLGvSBE1QiuhVk65NhOEgt3a4qGcy32A=', 
        'Accept': 'application/json', 
        'Content-Type': 'application/json'
      },
      data : data
    };
    
    axios.request(config)
    .then((response) => {

      if (JSON.stringify(response.data.responseCode)==200){
        seterrorMessage('Bypass FI report submitted successfully, please close this window');     
        seterroropen(true);
        seterrorTimer(8000);
        seterrortype('success');
        fisuccess = true;

        data = JSON.stringify({
          "leadNo": mylan,
          "fiType": "RES",
          "recommStatus": recom,
          "fiStatus": "C",
          "initiateDate": moment(new Date()).format('YYYY-MM-DD'),
          "completeDate": moment(new Date()).format('YYYY-MM-DD'),
          "remark": 'ByPass FI For Residence',
          "longitude":long,
          "latitude": lati,
          "fiFile": {
            "fileContent": splitfile[1]
          }
        });
        
      
        config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: saveresiapi,
            headers: { 
              'client-id': 'STATU0004200016', 
              'client-secret': 'QOetBBBHrU5jLGvSBE1QiuhVk65NhOEgt3a4qGcy32A=', 
              'Accept': 'application/json', 
              'Content-Type': 'application/json'
            },
            data : data
          };
          
          axios.request(config)
          .then((response) => {
      
            if (JSON.stringify(response.data.responseCode)==200){
              seterrorMessage('Bypass FI report submitted successfully, please close this window');     
              seterroropen(true);
              seterrorTimer(8000);
              seterrortype('success');
              fisuccess = true;




              data = JSON.stringify({
                "leadNo": mylan,
                "fiType": "FACTORY",
                "recommStatus": recom,
                "fiStatus": "C",
                "initiateDate": moment(new Date()).format('YYYY-MM-DD'),
                "completeDate": moment(new Date()).format('YYYY-MM-DD'),
                "remark": 'ByPass FI For FACTORY',
                "longitude":long,
                "latitude": lati,
                "fiFile": {
                  "fileContent": splitfile[1]
                }
              });
              
            
              config = {
                  method: 'post',
                  maxBodyLength: Infinity,
                  url: savefactapi,
                  headers: { 
                    'client-id': 'STATU0004200016', 
                    'client-secret': 'QOetBBBHrU5jLGvSBE1QiuhVk65NhOEgt3a4qGcy32A=', 
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json'
                  },
                  data : data
                };
                
                axios.request(config)
                .then((response) => {
            
                  if (JSON.stringify(response.data.responseCode)==200){
                    seterrorMessage('Bypass FI report submitted successfully, please close this window');     
                    seterroropen(true);
                    seterrorTimer(8000);
                    seterrortype('success');
                    fisuccess = true;
                    }else{
                      seterrorMessage('Facing some issue in getting details, please retry again after sometime!!!123');     
                      seterroropen(true);
                      seterrorTimer(8000);
                      seterrortype('error');
                      fisuccess = false;
                   }
                })
                .catch((error) => {
                  seterrorMessage('Facing some issue in getting details, please retry again after sometime!!!');     
                  seterroropen(true);
                  seterrorTimer(8000);
                  seterrortype('error');
                  fisuccess = false;
                });
          
















            }else{
                seterrorMessage('Facing some issue in getting details, please retry again after sometime!!!123');     
                seterroropen(true);
                seterrorTimer(8000);
                seterrortype('error');
                fisuccess = false;
             }
          })
          .catch((error) => {
            seterrorMessage('Facing some issue in getting details, please retry again after sometime!!!');     
            seterroropen(true);
            seterrorTimer(8000);
            seterrortype('error');
            fisuccess = false;
          });








        }else{
          seterrorMessage('Facing some issue in getting details, please retry again after sometime!!!123');     
          seterroropen(true);
          seterrorTimer(8000);
          seterrortype('error');
          fisuccess = false;
          alert(fisuccess);
        }
    })
    .catch((error) => {
      seterrorMessage('Facing some issue in getting details, please retry again after sometime!!!');     
      seterroropen(true);
      seterrorTimer(8000);
      seterrortype('error');
      fisuccess = false;
    });



};



  const saveofficefi = () => {
    var errorText = "" ;
    var imageupload = false;
    
    if ((orName === "N") && (document.getElementById('orgnamecomment').value.trim()==="")) {
      errorText += "'Organization Name' : 'Observation' is mandatory if your response is 'No'\n\n";
    }    

    if ((offAdrres === "N") && (document.getElementById('offaddcomment').value.trim()==="")) {
      errorText += "'Office Address' : 'Observation' is mandatory if your response is 'No'\n\n";
    }
    

    if ((offlocation === "N") && (document.getElementById('offlocationcomment').value.trim()==="")) {
      errorText += "'Owned Premises?': 'Observation' is mandatory if your response is 'No'\n\n";
    }

    if ((personmet==="")) {
      errorText += "'Person Met' : is mandatory\n\n";
    }    

    if ((designationmet==="")) {
      errorText += "'Designation' : of person met is mandatory\n\n";
    }    

    if ((noofemployee==="")) {
      errorText += "'Number of employees working' : is mandatory\n\n";
    }    

    if ((noofemployee < 1)) {
      errorText += "'Number of employees working' : is mandatory and should be more than zero(0)\n\n";
    }    

    if ((locationofoffice==="")) {
      errorText += "'Landmark of Office' : is mandatory\n\n";
    }

    if ((generalappearnce==="")) {
      errorText += "'General Appearance' : is mandatory\n\n";
    }

    if ((verifiercomment==="")) {
      errorText += "'Verifiers Remarks' : is mandatory\n\n";
    }
    
    if ((recoff === "N") && (document.getElementById('recoffcomment').value.trim()==="")) {
      errorText += "'Recommending for Credit?': 'Observation' is mandatory if your response is 'No'\n\n";
    }

    if ((imgData1 !=null) && (title1==='')){
      errorText += "'Image 1': 'Title' for 'First' image is mandatory as you have uploaded image\n\n";
    }

    if ((imgData2 !=null) && (title2==='')){
      errorText += "'Image 2': 'Title' for 'Second' image is mandatory as you have uploaded image\n\n";
    }

    if ((imgData3 !=null) && (title3==='')){
      errorText += "'Image 3': 'Title' for 'Third' image is mandatory as you have uploaded image\n\n";
    }

    if ((imgData4 !=null) && (title4==='')){
      errorText += "'Image 4': 'Title' for 'Forth' image is mandatory as you have uploaded image\n\n";
    }

    if (imgData1 != null) {
      imageupload = true;
    }

    if (imgData2 != null) {
      imageupload = true;
    }
    if (imgData3 != null) {
      imageupload = true;
    }
    if (imgData4 != null) {
      imageupload = true;
    }


    if (imageupload === false){
      errorText += "'Images': you have not uploaded any image, you need to upload atleast one image\n\n";
    }

    if (errorText.trim() !== "") {
      alert("Please close following errors\n\n"+ errorText);
      return false;
    } 
   
    var doc = new jsPDF('p', 'pt', 'letter');  
    var htmlstring = '';  
    var tempVarToCheckPageHeight = 0;  
    var pageHeight = 0;  
    pageHeight = doc.internal.pageSize.height; 
    var pageWidth = doc.internal.pageSize.width; 
    var specialElementHandlers = {  
        // element with id of "bypass" - jQuery style selector  
        '#bypassme': function(element, renderer) {  
            // true = "handled elsewhere, bypass text extraction"  
            return true  
        }  
    };  
    var margins = {  
        top: 150,  
        bottom: 60,  
        left: 40,  
        right: 40,  
        width: 600  
    };  
    var y = 20;  
    doc.setLineWidth(2);  
    doc.setFontSize(14);
    doc.text(40, y = y + 10, statusheader);  
    doc.setFontSize(12);
    doc.text(40, y = y + 30, "Field Investigation Report for '" + orgName + "'- Office");  
    doc.autoTable({  
        html: '#reportOffice',  
        startY: 70,  
        theme: 'grid',  
        columnStyles: {  
            0: {  
                cellWidth: 23,  
            },  
            1: {  
                cellWidth: 80,  
            },  
            2: {  
                cellWidth: 180,  
            },  
            3: {  
                cellWidth: 70,  
            },
            4: {  
                cellWidth: 180,  
            }    
        },  
        styles: {  
            minCellHeight: 30,
            fontSize:8
        }  
    })  

    if (imgData1!=null){
      doc.addPage();
      doc.setFontSize(14);
      //doc.text('First Image', 0, 0);
      doc.text(30, 30, 'Images:');  

      doc.text(30, 50, title1);  
      //console.log(ftype1);
      if (ftype1 ===''){
          doc.addImage(imgData1,'png', 30, 70, (pageWidth-margins.right-margins.left), 300);
      }else{
        doc.addImage(imgData1,ftype1, 30, 70, (pageWidth-margins.right-margins.left), 300);
      }
    }
    if (imgData2!=null){
      doc.addPage();
      doc.setFontSize(14);
      //doc.text('First Image', 0, 0);
    
      doc.text(30, 30, title2);  
      //console.log(ftype1);
      if (ftype1 ===''){
          doc.addImage(imgData2,'png', 30, 50, (pageWidth-margins.right-margins.left), 300);
      }else{
          doc.addImage(imgData2,ftype1, 30, 50, (pageWidth-margins.right-margins.left), 300);
      }
    }
    if (imgData3!=null){
      doc.addPage();
      doc.setFontSize(14);
      //doc.text('First Image', 0, 0);
    
      doc.text(30, 30, title3);  
      //console.log(ftype1);
      if (ftype1 ===''){
          doc.addImage(imgData3,'png', 30, 50, (pageWidth-margins.right-margins.left), 300);
      }else{
          doc.addImage(imgData3,ftype1, 30, 50, (pageWidth-margins.right-margins.left), 300);
      }
    }
    if (imgData4!=null){
      doc.addPage();
      doc.setFontSize(14);
      //doc.text('First Image', 0, 0);
    
      doc.text(30, 30, title4);  
      //console.log(ftype1);
      if (ftype1 ===''){
          doc.addImage(imgData4,'png', 30, 50, (pageWidth-margins.right-margins.left), 300);
      }else{
          doc.addImage(imgData4,ftype1, 30, 50, (pageWidth-margins.right-margins.left), 300);
      }
    }


    
    var pageCount = doc.internal.getNumberOfPages(); //Total Page Number
    var width = doc.internal.pageSize.getWidth();

    for(var i = 0; i < pageCount; i++) { 
      doc.setPage(i); 
      let pageCurrent = doc.internal.getCurrentPageInfo().pageNumber; //Current Page
      doc.setFontSize(9);
      doc.text(30,doc.internal.pageSize.height - 20, "FI Report for '" + orgName  + "'- Office");
      doc.text(width -(statusheader.length + 140),doc.internal.pageSize.height - 20, statusheader);
      doc.text(width/2,  doc.internal.pageSize.height - 20, 'Page: ' + pageCurrent + ' of ' + pageCount,{align: 'center'});

    }
    setpdffile(doc.output('dataurlstring'));

    var base = doc.output('dataurlstring'); // base64 string

    let splitfile =base.split(',');
    let filetouplod = splitfile[1];
    doc.save('Field_Invest_Office.pdf');  
    let recom = "P";

    let data = JSON.stringify({
      "leadNo": iLanNo,
      "fiType": "OFFICE",
      "recommStatus": recom,
      "fiStatus": "C",
      "initiateDate": moment(new Date()).format('YYYY-MM-DD'),
      "completeDate": moment(new Date()).format('YYYY-MM-DD'),
      "remark": verifiercomment,
      "longitude":long,
      "latitude": lati,
      "fiFile": {
        "fileContent": splitfile[1]
       
      }
    });
    
  
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: saveofficeapi,
        headers: { 
          'client-id': 'STATU0004200016', 
          'client-secret': 'QOetBBBHrU5jLGvSBE1QiuhVk65NhOEgt3a4qGcy32A=', 
          'Accept': 'application/json', 
          'Content-Type': 'application/json'
        },
        data : data
      };
      
      axios.request(config)
      .then((response) => {
        //console.log(JSON.stringify(response.data));
  
        if (JSON.stringify(response.data.responseCode)==200){
          //sanctionLetter = response.data.sanctionLetter;
          alert("Your response submitted successfully, please close this windows");
          
      }else{
        alert("Facing some issue in getting details, please retry again!!!")
      }
      })
      .catch((error) => {
        //console.log(error);
        alert("Facing some issue in getting details, please retry again!!!")
      });
}


   return (
       
      <>


      {/* <div style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop:'1rem',
}}>
    <b>Field Investigation{orgName}</b>
</div> */}

{/* <TextField
    //fullWidth
    label="Enter Buyer ID"
    placeholder='Enter Buyer ID'
    variant="outlined"
    value={buyerid}
    pattern='(([0-9]){6}'
    inputProps={{ maxLength: 6 }}
    required
    type='text'
    onChange={(e) => handleBuyerIdValidation(e)}
    size='small'          
    error={!validbuyerid}
    helperText={validbuyerid?"✓":"Buyer ID is required/Invalid"}
/> */}
{/* 
<Button title='' 
  size="md"
  color='teal'
  disabled={(!validbuyerid)}
  onClick={() => {
    getBuyerDetails();
  }}
  >
  Get Buyer Details
</Button> */}


<Snackbar autoHideDuration={errorTimer} anchorOrigin={{ vertical: "top", horizontal: "right" }} open={erroropen}  onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={errortype}
          variant="filled"
          sx={{ width: '100%' }}
          >
          {errorMessage}  
        </Alert>
      </Snackbar>
      <Box
      width="50%"
      my={1}
      display="flex"
      alignItems="center"
      justifyContent="center"
      marginLeft="25%"
      gap={1}
      p={2}
      sx={{ border: '2px solid grey',  borderRadius: 2, }}
    >
      <Grid container spacing={3}>
 
        <Grid item xs={12}>
          <b>Field Investigation{orgName}</b>
        </Grid>
      </Grid>
  </Box>




{/* 
      <Box display="flex"
      alignItems="center"
      width='50%'
      sx={{ flexGrow: 1, border: '2px solid black',marginTop:'10px' }}> */}
  <Box
      width="50%"
      my={1}
      display="flex"
      alignItems="center"
      justifyContent="center"
      marginLeft="25%"
      gap={1}
      p={2}
      sx={{ border: '2px solid grey',  borderRadius: 2, }}
    >
      <Grid container spacing={3}>
        {/* <Grid item xs={4.4}> */}
        <Grid item xs={8}>
          <TextField
              fullWidth
              label="Enter Buyer ID"
              placeholder='Enter Buyer ID'
              variant="outlined"
              value={buyerid}
              pattern='(([0-9]){6}'
              inputProps={{ maxLength: 6 }}
              required
              type='text'
              onChange={(e) => handleBuyerIdValidation(e)}
              size='small'          
              error={!validbuyerid}
              helperText={validbuyerid?"✓":"Buyer ID is required/Invalid"}
          />
        </Grid>
        <Grid item xs={4}> 
          <Button variant="contained" color='secondary' endIcon={<Search/>} onClick={getBuyerDetails} disabled={!validbuyerid}>
            Get Buyer FI Details
         </Button>
        </Grid>
      </Grid>
  </Box>

  <Box
      width="50%"
      my={1}
      display={(allvalid ? 'flex' : 'none')}
      alignItems="center"
      justifyContent="center"
      marginLeft="25%"
      gap={1}
      p={2}
      sx={{ border: '2px solid grey',  borderRadius: 2, }}
      //visibility={!validbuyerid}
      //disabled={!validbuyerid}
    >
      <Grid container spacing={1}>
        {/* <Grid item xs={4.4}> */}
        <Grid xs={12}>
          <TextField
            label="Referred By"
            placeholder='Enter the Name of the Referral'  variant="outlined"
            value={referredBy}
            inputProps={{ maxLength: 50 }}
            required
            onChange={(e) => handlereferredBy(e)}
            size='small'          
            error={!validreferredBy}
            fullWidth
            helperText={validreferredBy?"✓":"Referred By is required/Invalid"}
            margin="normal"
          />
        </Grid>
        <Grid xs={12}>
          <TextField
            label="Equifax Score"
            placeholder='Enter the Equifax Score'  variant="outlined"
            value={equifaxScore}
            inputProps={{ maxLength: 3 }}
            required
            onChange={(e) => handleequifaxScore(e)}
            size='small'          
            error={!validequifaxScore}
            //fullWidth
            margin="normal"
            helperText={validequifaxScore?"✓":"Equifax Score schore should be more than 700"}
          />
        </Grid>
        <Grid xs={12}>
        <TextField
            label="CRIF Score"
            placeholder='Enter the CRIF Score'  variant="outlined"
            value={crifScore}
            inputProps={{ maxLength: 3 }}
            required
            onChange={(e) => handlecrifScore(e)}
            size='small'          
            error={!validcrifScore}
            //fullWidth
            margin="normal"
            helperText={validcrifScore?"✓":"Crif Score schore should be more than 700.."}
          />
        </Grid>
        <Grid xs={12}>
          <TextField
            label="Relationship with Anchor"
            placeholder='Enter the Relationship with Ancher'  
            variant="outlined"
            value={relationship}
            inputProps={{ maxLength: 50 }}
            required
            onChange={(e) => handlerelationship(e)}
            size='small'          
            error={!validrelationship}
            fullWidth
            margin="normal"
            helperText={validrelationship?"✓":"Relationship with Anchor is required/Invalid"}
          />
        </Grid>

        <Grid xs={12}>
          <TextField
            label="Fi done by"
            placeholder='Enter the your name'  
            variant="outlined"
            value={fidoneby}
            inputProps={{ maxLength: 50 }}
            required
            onChange={(e) => handlefidoneby(e)}
            size='small'          
            error={!validfidoneby}
            fullWidth
            margin="normal"
            helperText={validfidoneby?"✓":"Fi done by is required/Invalid"}
          />
        </Grid>

        <Grid item xs={4}>
          <Button variant="contained" color="success" endIcon={<SendIcon />} onClick={handleSubmitFI} disabled={!(validreferredBy && validcrifScore && validequifaxScore && validrelationship && validfidoneby)}>
        Submit FI Details
      </Button>
        </Grid>
      </Grid>
  </Box>



<div hidden>
      <Accordion disabled={(fiStatOFfice === 'C')? true:false}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >

        <Typography style={{ color: (fiStatOFfice === 'C')? 'DarkGreen':'red'}} >Office : FI Status - { (fiStatOFfice === 'C')? 'Completed':'Not yet done'}</Typography>
        </AccordionSummary>
        <AccordionDetails >
          <div disabled={(fiStatOFfice === 'C')? true:false}>
          <table id="fitable" style={{ 'width':'100%', 'border': '1px solid black','borderCollapse': 'collapse' }}>
            <thead>
              <tr>
                <th style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'width':'2%','textAlign': 'right' }}>#</th>
                <th style={{ 'border': '1px solid black','borderCollapse': 'collapse','width':'25%' }}>Title</th>
                <th style={{ 'border': '1px solid black','borderCollapse': 'collapse','width':'58%' }}>Details</th>
                <th style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'width':'15%' }}>Is information correct?</th>
              </tr>
            </thead>
            <tbody>
              <tr>     
                  <td colSpan={4}><b>Organization Details</b></td>
              </tr>
              <tr>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>1</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>Organisation Name </td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>{orgName}</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'align':'center'}}>
                  <input type="radio" name="orName" id='orName-1' value="Y" onChange={handleChangeorName} checked={orName === 'Y'} />
                      <label htmlFor="orName-1">Yes</label>
                  <input type="radio" name="orName" id='orName-2' value="N" onChange={handleChangeorName} checked={orName === 'N'} />
                      <label htmlFor="orName-2">No</label>
                </td>
              </tr>
              <tr>     
                  <td colSpan={4}>Observations:<input type="text" id="orgnamecomment" onBlur={changeorgnamecomment} size="165"></input></td>
              </tr>  
              <tr>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>2</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>Office address </td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}><div style={{'whiteSpace': 'pre-line'}}>{offAddress}</div></td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'align':'center'}}>
                  <input type="radio" name="offadd" id='offadd-1' value="Y" onChange={handleChangeoffadd} checked={offAdrres === 'Y'} />
                      <label htmlFor="offadd-1">Yes</label>
                  <input type="radio" name="offadd" id='offadd-2' value="N" onChange={handleChangeoffadd} checked={offAdrres === 'N'} />
                      <label htmlFor="offadd-2">No</label>
                </td>
              </tr>
              <tr>     
                  <td colSpan={4}>Observations:<input type="text" id="offaddcomment" onBlur={changeoffaddcomment} size="165"></input></td>
              </tr>   
              <tr>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>3</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>GEO Location </td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>{geoLocation}</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'align':'center'}}>
                </td>
              </tr>
              <tr>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>4</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>Is this Owned Premises? </td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>{ownOffice}</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'align':'center'}}>
                  <input type="radio" name="offlocation" id='offlocation-1' value="Y" onChange={handleChangeofflocation} checked={offlocation === 'Y'} />
                      <label htmlFor="offlocation-1">Yes</label>
                  <input type="radio" name="offlocation" id='offlocation-2' value="N" onChange={handleChangeofflocation} checked={offlocation === 'N'} />
                      <label htmlFor="offlocation-2">No</label>
                </td>
              </tr>
              <tr>     
                  <td colSpan={4}>Observations:<input type="text" id="offlocationcomment" onBlur={changeofflocationcomment}  size="165"></input></td>
              </tr>           
              <tr>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>5</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>Name and designation of person met </td>
                <td colSpan={2} style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}><input type="text" id="officenameofpersonmet" placeholder="Name of person met" onBlur={changenameofpersonmet} maxLength={70} size="70"></input>&nbsp;<input type="text" id="officedesignationofpersonmet" placeholder="Designation of person met" onBlur={changedesigofpersonmet} size="45"></input></td>

              </tr>
              <tr>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>6</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>Number of employees working </td>
                <td colSpan={2} style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}><input type="text" id="officenoofemployee" onBlur={changenoofemployee} size="6" maxLength={6}></input></td>

              </tr>
              <tr>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>7</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>Landmark of Office </td>
                <td colSpan={2} style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}><input type="text" id="locationofoffice" onBlur={changeofflocationofoffice}   size="40" maxLength={40}></input></td>

              </tr>
              <tr>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>8</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>General Appearance </td>
                <td colSpan={2} style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}><input type="text" id="officegeneralappearance" onBlur={changegeneralappearance} size="40"></input></td>

              </tr>

              <tr>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>9</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>Reference No. </td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>{iLanNo}</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'align':'center'}}>
                </td>
              </tr>
              <tr>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>10</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>Verifiers Remarks </td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}></td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'align':'center'}}></td>

              </tr>
              <tr>
                <td colSpan={4} style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}><input type="text" id="officeverifiercomment" onBlur={changeverifiercomment} size="160"></input></td>
              </tr>
              <tr>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>11</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>Recommending for Credit? </td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>{}</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'align':'center'}}>
                  <input type="radio" name="recoff" id='recoff-1' value="Y" onChange={handleChangerecoff} checked={recoff === 'Y'} />
                      <label htmlFor="recoff-1">Yes</label>
                  <input type="radio" name="recoff" id='recoff-2' value="N" onChange={handleChangerecoff} checked={recoff === 'N'} />
                      <label htmlFor="recoff-2">No</label>
                </td>
              </tr>
              <tr>     
                  <td colSpan={4}>Observations:<input type="text" id="recoffcomment" onBlur={changerecoffcomment}  size="165"></input></td>
              </tr> 

              <tr>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>12</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>FI Done by </td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>{rmName}</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'align':'center'}}>
                </td>
              </tr>


{/*               <tr>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>5</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>Product </td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>{product}</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'align':'center'}}>
                </td>
              </tr> */}


              <tr>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>13</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>Date of Visit </td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>{todaydate}</td>

                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'align':'center'}}>
                </td>
              </tr>
              <tr>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>14</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>Time of Visit </td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>{todaytime}</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'align':'center'}}>
                </td>
              </tr>
            </tbody>
          </table>  
          <br></br>
          <table>
            <tbody>
              <tr>     
                  <td colSpan={4}><b>Images</b></td>
              </tr> 

              <tr>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'width':'25%'}}>
                  <b>Image 1:</b>&nbsp;
                  <label htmlFor="profilePic1" style={{ 'display':'inline-block','background': '#12b886', 'height':'1.7em','color':'#fff', 'border': '1px outset #ccc', 'borderRadius': '.3em'}}>
                    <input name="" type="file" id="profilePic1" hidden onChange={onChangePicture1} style={{ 'background': '#12b886', 'height':'2.7em','color':'#fff', 'border': '1px outset #ccc', 'borderRadius': '.3em'}}/>
                    Select Image
                  </label>
                  &nbsp;
                  <label htmlFor="removePic1" style={{ 'display':'inline-block','background': '#12b886', 'height':'1.7em','color':'#fff', 'border': '1px outset #ccc', 'borderRadius': '.3em'}}>
                    <input type = "button" id="removePic1" value = "Remove image" hidden={true} onClick={() => {
                      removefile(1);
                      }}/>
                    Remove Image    
                  </label>  
                </td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'width':'25%'}}>
                  <b>Image 2:</b>&nbsp;
                  <label htmlFor="profilePic2" style={{ 'display':'inline-block','background': '#12b886', 'height':'1.7em','color':'#fff', 'border': '1px outset #ccc', 'borderRadius': '.3em'}}>
                    <input name="" type="file" id="profilePic2" hidden onChange={onChangePicture2} style={{ 'background': '#12b886', 'height':'2.7em','color':'#fff', 'border': '1px outset #ccc', 'borderRadius': '.3em'}}/>
                    Select Image
                  </label>
                  &nbsp;
                  <label htmlFor="removePic2" style={{ 'display':'inline-block','background': '#12b886', 'height':'1.7em','color':'#fff', 'border': '1px outset #ccc', 'borderRadius': '.3em'}}>
                    <input type = "button" id="removePic2" value = "Remove image" hidden={true} onClick={() => {
                      removefile(2);
                      }}/>
                    Remove Image    
                  </label>  
                </td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'width':'25%'}}>
                <b>Image 3:</b>&nbsp;
                  <label htmlFor="profilePic3" style={{ 'display':'inline-block','background': '#12b886', 'height':'1.7em','color':'#fff', 'border': '1px outset #ccc', 'borderRadius': '.3em'}}>
                    <input name="" type="file" id="profilePic3" hidden onChange={onChangePicture3} style={{ 'background': '#12b886', 'height':'2.7em','color':'#fff', 'border': '1px outset #ccc', 'borderRadius': '.3em'}}/>
                    Select Image
                  </label>
                  &nbsp;
                  <label htmlFor="removePic3" style={{ 'display':'inline-block','background': '#12b886', 'height':'1.7em','color':'#fff', 'border': '1px outset #ccc', 'borderRadius': '.3em'}}>
                    <input type = "button" id="removePic3" value = "Remove image" hidden={true} onClick={() => {
                      removefile(3);
                      }}/>
                    Remove Image    
                  </label>  
                </td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'width':'25%'}}>
                  <b>Image 4:</b>&nbsp;
                  <label htmlFor="profilePic4" style={{ 'display':'inline-block','background': '#12b886', 'height':'1.7em','color':'#fff', 'border': '1px outset #ccc', 'borderRadius': '.3em'}}>
                    <input name="" type="file" id="profilePic4" hidden onChange={onChangePicture4} style={{ 'background': '#12b886', 'height':'2.7em','color':'#fff', 'border': '1px outset #ccc', 'borderRadius': '.3em'}}/>
                    Select Image
                  </label>
                  &nbsp;
                  <label htmlFor="removePic4" style={{ 'display':'inline-block','background': '#12b886', 'height':'1.7em','color':'#fff', 'border': '1px outset #ccc', 'borderRadius': '.3em'}}>
                    <input type = "button" id="removePic4" value = "Remove image" hidden={true} onClick={() => {
                      removefile(4);
                      }}/>
                    Remove Image    
                  </label>  
                </td>
              </tr>
              <tr>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'width':'25%'}}><input type="text" id="img1Title" size="35" placeholder="Please enter image title" onChange={changetitle1}/></td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'width':'25%'}}><input type="text" id="img2Title" size="35" placeholder="Please enter image title" onChange={changetitle2}/></td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'width':'25%'}}><input type="text" id="img3Title" size="35" placeholder="Please enter image title" onChange={changetitle3}/></td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'width':'25%'}}><input type="text" id="img4Title" size="35" placeholder="Please enter image title" onChange={changetitle4}/></td>
              </tr>
              <tr>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'width':'25%'}}><img  alt='' src={imgData1} /></td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'width':'25%'}}><img  alt='' src={imgData2} /></td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'width':'25%'}}><img  alt='' src={imgData3} /></td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'width':'25%'}}><img  alt='' src={imgData4} /></td>
              </tr>
            </tbody>
          </table>  
          <br></br>
          {/* <Button
              style={{ 'background': '#12b886', 'height':'2.7em','color':'#fff', 'border': '1px outset #ccc', 'borderRadius': '.3em'}}
              onClick={() => {
                //generate();
                saveofficefi();
              }}
          >
              Submit Office FI Details
          </Button> */}
          </div>
        </AccordionDetails>
      </Accordion>
      
      <Accordion disabled={(fiStatFact === 'C')? true:false}>


        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography style={{ color: (fiStatFact === 'C')? 'DarkGreen':'red'}} >Factory : FI Status - { (fiStatFact === 'C')? 'Completed':'Not yet done'}</Typography>

        </AccordionSummary>
        <AccordionDetails>
          <table id="fitable" style={{ 'width':'100%', 'border': '1px solid black','borderCollapse': 'collapse' }}>
            <thead>
              <tr>
                <th style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'width':'2%','textAlign': 'right' }}>#</th>
                <th style={{ 'border': '1px solid black','borderCollapse': 'collapse','width':'25%' }}>Title</th>
                <th style={{ 'border': '1px solid black','borderCollapse': 'collapse','width':'58%' }}>Details</th>
                <th style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'width':'15%' }}>Is information correct?</th>
              </tr>
            </thead>
            <tbody>
              <tr>     
                  <td colSpan={4}><b>Organization Details</b></td>
              </tr>
              <tr>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>1</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>Organisation Name </td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>{orgName}</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'align':'center'}}>
                  <input type="radio" name="orNameF" id='orNameF-1' value="Y" onChange={handleChangeorNameF} checked={orNameF === 'Y'} />
                      <label htmlFor="orNameF-1">Yes</label>
                  <input type="radio" name="orNameF" id='orNameF-2' value="N" onChange={handleChangeorNameF} checked={orNameF === 'N'} />
                      <label htmlFor="orNameF-2">No</label>
                </td>
              </tr>
              <tr>     
                  <td colSpan={4}>Observations:<input type="text" id="orgnameFcomment" onBlur={changeorgnameFcomment} size="165"></input></td>
              </tr>  
              <tr>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>2</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>Factory address </td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}><div style={{'whiteSpace': 'pre-line'}}>{facAddresss}</div></td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'align':'center'}}>
                  <input type="radio" name="facadd" id='facadd-1' value="Y" onChange={handleChangefacadd} checked={facAddress === 'Y'} />
                      <label htmlFor="facadd-1">Yes</label>
                  <input type="radio" name="facadd" id='facadd-2' value="N" onChange={handleChangefacadd} checked={facAddress === 'N'} />
                      <label htmlFor="facadd-2">No</label>
                </td>
              </tr>
              <tr>     
                  <td colSpan={4}>Observations:<input type="text" id="facAddressComment" onBlur={changefacaddcomment} size="165"></input></td>
              </tr>   
              <tr>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>3</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>GEO Location </td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>{geoLocation}</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'align':'center'}}>
                </td>
              </tr>
              <tr>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>4</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>Is this Owned Premises? </td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>{}</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'align':'center'}}>
                  <input type="radio" name="factownlocat" id='factownlocat-1' value="Y" onChange={handleChangefactownlocation} checked={factownlocation === 'Y'} />
                      <label htmlFor="factownlocat-1">Yes</label>
                  <input type="radio" name="factownlocat" id='factownlocat-2' value="N" onChange={handleChangefactownlocation} checked={factownlocation === 'N'} />
                      <label htmlFor="factownlocat-2">No</label>
                </td>
              </tr>
              <tr>     
                  <td colSpan={4}>Observations:<input type="text" id="factlocationcomment" onBlur={changefactlocationcomment}  size="165"></input></td>
              </tr>           
              <tr>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>5</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>Name and designation of person met </td>
                <td colSpan={2} style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}><input type="text" id="officenameofpersonmetf" placeholder="Name of person met" onBlur={changenameofpersonmetf} maxLength={70} size="70"></input>&nbsp;<input type="text" id="officedesignationofpersonmetf" placeholder="Designation of person met" onBlur={changedesigofpersonmetf} size="45"></input></td>

              </tr>
              <tr>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>6</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>Number of employees working </td>
                <td colSpan={2} style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}><input type="text" id="officenoofemployeef" onBlur={changenoofemployeef} size="6" maxLength={6}></input></td>

              </tr>
              <tr>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>7</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>Landmark of Factory </td>
                <td colSpan={2} style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}><input type="text" id="locationofofficef" onBlur={changeofflocationofofficef}   size="40" maxLength={40}></input></td>

              </tr>
              <tr>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>8</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>General Appearance </td>
                <td colSpan={2} style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}><input type="text" id="officegeneralappearancef" onBlur={changegeneralappearancef} size="40"></input></td>

              </tr>

              <tr>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>9</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>Reference No. </td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>{iLanNo}</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'align':'center'}}>
                </td>
              </tr>
              <tr>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>10</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>Verifiers Remarks </td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}></td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'align':'center'}}></td>

              </tr>
              <tr>
                <td colSpan={4} style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}><input type="text" id="officeverifiercomment" onBlur={changeverifiercommentf} size="160"></input></td>
              </tr>
              <tr>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>11</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>Recommending for Credit? </td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>{}</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'align':'center'}}>
                  <input type="radio" name="recoffF" id='recoffF-1' value="Y" onChange={handleChangerecoffF} checked={recoffF === 'Y'} />
                      <label htmlFor="recoffF-1">Yes</label>
                  <input type="radio" name="recoffF" id='recoffF-2' value="N" onChange={handleChangerecoffF} checked={recoffF === 'N'} />
                      <label htmlFor="recoffF-2">No</label>
                </td>
              </tr>
              <tr>     
                  <td colSpan={4}>Observations:<input type="text" id="recoffcommentf" onBlur={changerecoffcommentf}  size="165"></input></td>
              </tr> 

              <tr>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>12</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>FI Done by </td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>{rmName}</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'align':'center'}}>
                </td>
              </tr>


{/*               <tr>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>5</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>Product </td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>{product}</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'align':'center'}}>
                </td>
              </tr> */}


              <tr>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>13</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>Date of Visit </td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>{todaydate}</td>

                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'align':'center'}}>
                </td>
              </tr>
              <tr>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>14</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>Time of Visit </td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>{todaytime}</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'align':'center'}}>
                </td>
              </tr>
            </tbody>
          </table>  
          <br></br>
          <table>
            <tbody>
              <tr>     
                  <td colSpan={4}><b>Images</b></td>
              </tr> 

              <tr>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'width':'25%'}}>
                  <b>Image 1:</b>&nbsp;
                  <label htmlFor="profilePic1f" style={{ 'display':'inline-block','background': '#12b886', 'height':'1.7em','color':'#fff', 'border': '1px outset #ccc', 'borderRadius': '.3em'}}>
                    <input name="" type="file" id="profilePic1f" hidden onChange={onChangePicturef1} style={{ 'background': '#12b886', 'height':'2.7em','color':'#fff', 'border': '1px outset #ccc', 'borderRadius': '.3em'}}/>
                    Select Image
                  </label>
                  &nbsp;
                  <label htmlFor="removePic1f" style={{ 'display':'inline-block','background': '#12b886', 'height':'1.7em','color':'#fff', 'border': '1px outset #ccc', 'borderRadius': '.3em'}}>
                    <input type = "button" id="removePic1f" value = "Remove image" hidden={true} onClick={() => {
                      removefilef(1);
                      }}/>
                    Remove Image    
                  </label>  
                </td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'width':'25%'}}>
                  <b>Image 2:</b>&nbsp;
                  <label htmlFor="profilePic2f" style={{ 'display':'inline-block','background': '#12b886', 'height':'1.7em','color':'#fff', 'border': '1px outset #ccc', 'borderRadius': '.3em'}}>
                    <input name="" type="file" id="profilePic2f" hidden onChange={onChangePicturef2} style={{ 'background': '#12b886', 'height':'2.7em','color':'#fff', 'border': '1px outset #ccc', 'borderRadius': '.3em'}}/>
                    Select Image
                  </label>
                  &nbsp;
                  <label htmlFor="removePic2f" style={{ 'display':'inline-block','background': '#12b886', 'height':'1.7em','color':'#fff', 'border': '1px outset #ccc', 'borderRadius': '.3em'}}>
                    <input type = "button" id="removePic2f" value = "Remove image" hidden={true} onClick={() => {
                      removefilef(2);
                      }}/>
                    Remove Image    
                  </label>  
                </td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'width':'25%'}}>
                <b>Image 3:</b>&nbsp;
                  <label htmlFor="profilePic3f" style={{ 'display':'inline-block','background': '#12b886', 'height':'1.7em','color':'#fff', 'border': '1px outset #ccc', 'borderRadius': '.3em'}}>
                    <input name="" type="file" id="profilePic3f" hidden onChange={onChangePicturef3} style={{ 'background': '#12b886', 'height':'2.7em','color':'#fff', 'border': '1px outset #ccc', 'borderRadius': '.3em'}}/>
                    Select Image
                  </label>
                  &nbsp;
                  <label htmlFor="removePic3f" style={{ 'display':'inline-block','background': '#12b886', 'height':'1.7em','color':'#fff', 'border': '1px outset #ccc', 'borderRadius': '.3em'}}>
                    <input type = "button" id="removePic3f" value = "Remove image" hidden={true} onClick={() => {
                      removefilef(3);
                      }}/>
                    Remove Image    
                  </label>  
                </td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'width':'25%'}}>
                  <b>Image 4:</b>&nbsp;
                  <label htmlFor="profilePic4f" style={{ 'display':'inline-block','background': '#12b886', 'height':'1.7em','color':'#fff', 'border': '1px outset #ccc', 'borderRadius': '.3em'}}>
                    <input name="" type="file" id="profilePic4f" hidden onChange={onChangePicturef4} style={{ 'background': '#12b886', 'height':'2.7em','color':'#fff', 'border': '1px outset #ccc', 'borderRadius': '.3em'}}/>
                    Select Image
                  </label>
                  &nbsp;
                  <label htmlFor="removePic4f" style={{ 'display':'inline-block','background': '#12b886', 'height':'1.7em','color':'#fff', 'border': '1px outset #ccc', 'borderRadius': '.3em'}}>
                    <input type = "button" id="removePic4f" value = "Remove image" hidden={true} onClick={() => {
                      removefilef(4);
                      }}/>
                    Remove Image    
                  </label>  
                </td>
              </tr>
              <tr>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'width':'25%'}}><input type="text" id="img1Titlef" size="35" placeholder="Please enter image title" onChange={changetitlef1}/></td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'width':'25%'}}><input type="text" id="img2Titlef" size="35" placeholder="Please enter image title" onChange={changetitlef2}/></td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'width':'25%'}}><input type="text" id="img3Titlef" size="35" placeholder="Please enter image title" onChange={changetitlef3}/></td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'width':'25%'}}><input type="text" id="img4Titlef" size="35" placeholder="Please enter image title" onChange={changetitlef4}/></td>
              </tr>
              <tr>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'width':'25%'}}><img  alt='' src={imgData1f} /></td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'width':'25%'}}><img  alt='' src={imgData2f} /></td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'width':'25%'}}><img  alt='' src={imgData3f} /></td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'width':'25%'}}><img  alt='' src={imgData4f} /></td>
              </tr>
            </tbody>
          </table>  
          <br></br>
          {/* <Button
              style={{ 'background': '#12b886', 'height':'2.7em','color':'#fff', 'border': '1px outset #ccc', 'borderRadius': '.3em'}}
              onClick={() => {
                //generate();
                savefactoryfi();
              }}
          >
              Submit Factory FI Details
          </Button> */}
        </AccordionDetails>
      </Accordion>

      <Accordion disabled={(fiStatResi === 'C')? true:false}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography style={{ color: (fiStatResi === 'C')? 'DarkGreen':'red'}} >Residence : FI Status - { (fiStatResi === 'C')? 'Completed':'Not yet done'}</Typography>

          </AccordionSummary>
          <AccordionDetails>
            <table id="fitable" style={{ 'width':'100%', 'border': '1px solid black','borderCollapse': 'collapse' }}>
              <thead>
                <tr>
                  <th style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'width':'2%','textAlign': 'right' }}>#</th>
                  <th style={{ 'border': '1px solid black','borderCollapse': 'collapse','width':'25%' }}>Title</th>
                  <th style={{ 'border': '1px solid black','borderCollapse': 'collapse','width':'58%' }}>Details</th>
                  <th style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'width':'15%' }}>Is information correct?</th>
                </tr>
              </thead>
              <tbody>
                <tr>     
                    <td colSpan={4}><b>Organization Details</b></td>
                </tr>
                <tr>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>1</td>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>Organisation Name </td>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>{orgName}</td>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'align':'center'}}>
                    <input type="radio" name="orNameR" id='orNameR-1' value="Y" onChange={handleChangeorNameR} checked={orNameR === 'Y'} />
                        <label htmlFor="orNameR-1">Yes</label>
                    <input type="radio" name="orNameR" id='orNameR-2' value="N" onChange={handleChangeorNameR} checked={orNameR === 'N'} />
                        <label htmlFor="orNameR-2">No</label>
                  </td>
                </tr>
                <tr>     
                    <td colSpan={4}>Observations:<input type="text" id="orgnameRcomment" onBlur={changeorgnameRcomment} size="165"></input></td>
                </tr>  
                <tr>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>2</td>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>Director Name </td>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}><div style={{'whiteSpace': 'pre-line'}}>{dirName}</div></td>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'align':'center'}}>
                    <input type="radio" name="dName" id='dName-1' value="Y" onChange={handleChangedName} checked={drName === 'Y'} />
                        <label htmlFor="dName-1">Yes</label>
                    <input type="radio" name="dName" id='dName-2' value="N" onChange={handleChangedName} checked={drName === 'N'} />
                        <label htmlFor="dName-2">No</label>
                  </td>
                </tr>
                <tr>     
                    <td colSpan={4}>Observations:<input type="text" id="drNameComment" onBlur={changedrNamecomment} size="165"></input></td>
                </tr>   
                <tr>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>3</td>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>Residence address </td>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}><div style={{'whiteSpace': 'pre-line'}}>{resiAddress}</div></td>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'align':'center'}}>
                    <input type="radio" name="resiadd" id='resiadd-1' value="Y" onChange={handleChangeresiadd} checked={resAddress === 'Y'} />
                        <label htmlFor="resiadd-1">Yes</label>
                    <input type="radio" name="resiadd" id='resiadd-2' value="N" onChange={handleChangeresiadd} checked={resAddress === 'N'} />
                        <label htmlFor="resiadd-2">No</label>
                  </td>
                </tr>
                <tr>     
                    <td colSpan={4}>Observations:<input type="text" id="resAddressComment" onBlur={changeresaddcomment} size="165"></input></td>
                </tr>   
                <tr>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>4</td>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>GEO Location </td>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>{geoLocation}</td>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'align':'center'}}>
                  </td>
                </tr>
                <tr>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>5</td>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>Is this Owned Premises? </td>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>{}</td>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'align':'center'}}>
                    <input type="radio" name="resiownlocat" id='resiownlocat-1' value="Y" onChange={handleChangeresiownlocation} checked={resiownlocation === 'Y'} />
                        <label htmlFor="resiownlocat-1">Yes</label>
                    <input type="radio" name="resiownlocat" id='resiownlocat-2' value="N" onChange={handleChangeresiownlocation} checked={resiownlocation === 'N'} />
                        <label htmlFor="resiownlocat-2">No</label>
                  </td>
                </tr>
                <tr>     
                    <td colSpan={4}>Observations:<input type="text" id="resilocationcomment" onBlur={changeresilocationcomment}  size="165"></input></td>
                </tr>           
                <tr>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>6</td>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>Name of person met </td>
                  <td colSpan={2} style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}><input type="text" id="officenameofpersonmetf" placeholder="Name of person met" onBlur={changenameofpersonmetr} maxLength={70} size="70"></input></td>

                </tr>
                {/* <tr>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>6</td>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>Number of employees working </td>
                  <td colSpan={2} style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}><input type="text" id="officenoofemployeef" onBlur={changenoofemployeef} size="6" maxLength={6}></input></td>

                </tr> */}
                <tr>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>7</td>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>Landmark of Residence </td>
                  <td colSpan={2} style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}><input type="text" id="locationofofficer" onBlur={changeofflocationofofficer}   size="40" maxLength={40}></input></td>

                </tr>
                <tr>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>8</td>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>General Appearance </td>
                  <td colSpan={2} style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}><input type="text" id="officegeneralappearancer" onBlur={changegeneralappearancer} size="40"></input></td>

                </tr>

                <tr>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>9</td>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>Reference No. </td>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>{iLanNo}</td>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'align':'center'}}>
                  </td>
                </tr>
                <tr>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>10</td>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>Verifiers Remarks </td>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}></td>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'align':'center'}}></td>

                </tr>
                <tr>
                  <td colSpan={4} style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}><input type="text" id="officeverifiercomment" onBlur={changeverifiercommentr} size="160"></input></td>
                </tr>
                <tr>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>11</td>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>Recommending for Credit? </td>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>{}</td>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'align':'center'}}>
                    <input type="radio" name="recoffr" id='recoffr-1' value="Y" onChange={handleChangerecoffr} checked={recoffr === 'Y'} />
                        <label htmlFor="recoffr-1">Yes</label>
                    <input type="radio" name="recoffr" id='recoffr-2' value="N" onChange={handleChangerecoffr} checked={recoffr === 'N'} />
                        <label htmlFor="recoffr-2">No</label>
                  </td>
                </tr>
                <tr>     
                    <td colSpan={4}>Observations:<input type="text" id="recoffcommentr" onBlur={changerecoffcommentr}  size="165"></input></td>
                </tr> 

                <tr>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>12</td>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>FI Done by </td>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>{rmName}</td>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'align':'center'}}>
                  </td>
                </tr>


          {/*               <tr>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>5</td>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>Product </td>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>{product}</td>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'align':'center'}}>
                  </td>
                </tr> */}


                <tr>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>13</td>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>Date of Visit </td>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>{todaydate}</td>

                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'align':'center'}}>
                  </td>
                </tr>
                <tr>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>14</td>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>Time of Visit </td>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>{todaytime}</td>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'align':'center'}}>
                  </td>
                </tr>
              </tbody>
            </table>  
            <br></br>
            <table>
              <tbody>
                <tr>     
                    <td colSpan={4}><b>Images</b></td>
                </tr> 

                <tr>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'width':'25%'}}>
                    <b>Image 1:</b>&nbsp;
                    <label htmlFor="profilePic1r" style={{ 'display':'inline-block','background': '#12b886', 'height':'1.7em','color':'#fff', 'border': '1px outset #ccc', 'borderRadius': '.3em'}}>
                      <input name="" type="file" id="profilePic1r" hidden onChange={onChangePicturer1} style={{ 'background': '#12b886', 'height':'2.7em','color':'#fff', 'border': '1px outset #ccc', 'borderRadius': '.3em'}}/>
                      Select Image
                    </label>
                    &nbsp;
                    <label htmlFor="removePic1r" style={{ 'display':'inline-block','background': '#12b886', 'height':'1.7em','color':'#fff', 'border': '1px outset #ccc', 'borderRadius': '.3em'}}>
                      <input type = "button" id="removePic1r" value = "Remove image" hidden={true} onClick={() => {
                        removefiler(1);
                        }}/>
                      Remove Image    
                    </label>  
                  </td>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'width':'25%'}}>
                    <b>Image 2:</b>&nbsp;
                    <label htmlFor="profilePic2r" style={{ 'display':'inline-block','background': '#12b886', 'height':'1.7em','color':'#fff', 'border': '1px outset #ccc', 'borderRadius': '.3em'}}>
                      <input name="" type="file" id="profilePic2r" hidden onChange={onChangePicturer2} style={{ 'background': '#12b886', 'height':'2.7em','color':'#fff', 'border': '1px outset #ccc', 'borderRadius': '.3em'}}/>
                      Select Image
                    </label>
                    &nbsp;
                    <label htmlFor="removePic2r" style={{ 'display':'inline-block','background': '#12b886', 'height':'1.7em','color':'#fff', 'border': '1px outset #ccc', 'borderRadius': '.3em'}}>
                      <input type = "button" id="removePic2r" value = "Remove image" hidden={true} onClick={() => {
                        removefiler(2);
                        }}/>
                      Remove Image    
                    </label>  
                  </td>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'width':'25%'}}>
                  <b>Image 3:</b>&nbsp;
                    <label htmlFor="profilePic3r" style={{ 'display':'inline-block','background': '#12b886', 'height':'1.7em','color':'#fff', 'border': '1px outset #ccc', 'borderRadius': '.3em'}}>
                      <input name="" type="file" id="profilePic3r" hidden onChange={onChangePicturer3} style={{ 'background': '#12b886', 'height':'2.7em','color':'#fff', 'border': '1px outset #ccc', 'borderRadius': '.3em'}}/>
                      Select Image
                    </label>
                    &nbsp;
                    <label htmlFor="removePic3r" style={{ 'display':'inline-block','background': '#12b886', 'height':'1.7em','color':'#fff', 'border': '1px outset #ccc', 'borderRadius': '.3em'}}>
                      <input type = "button" id="removePic3r" value = "Remove image" hidden={true} onClick={() => {
                        removefiler(3);
                        }}/>
                      Remove Image    
                    </label>  
                  </td>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'width':'25%'}}>
                    <b>Image 4:</b>&nbsp;
                    <label htmlFor="profilePic4r" style={{ 'display':'inline-block','background': '#12b886', 'height':'1.7em','color':'#fff', 'border': '1px outset #ccc', 'borderRadius': '.3em'}}>
                      <input name="" type="file" id="profilePic4r" hidden onChange={onChangePicturer4} style={{ 'background': '#12b886', 'height':'2.7em','color':'#fff', 'border': '1px outset #ccc', 'borderRadius': '.3em'}}/>
                      Select Image
                    </label>
                    &nbsp;
                    <label htmlFor="removePic4r" style={{ 'display':'inline-block','background': '#12b886', 'height':'1.7em','color':'#fff', 'border': '1px outset #ccc', 'borderRadius': '.3em'}}>
                      <input type = "button" id="removePic4r" value = "Remove image" hidden={true} onClick={() => {
                        removefiler(4);
                        }}/>
                      Remove Image    
                    </label>  
                  </td>
                </tr>
                <tr>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'width':'25%'}}><input type="text" id="img1Titler" size="35" placeholder="Please enter image title" onChange={changetitler1}/></td>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'width':'25%'}}><input type="text" id="img2Titler" size="35" placeholder="Please enter image title" onChange={changetitler2}/></td>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'width':'25%'}}><input type="text" id="img3Titler" size="35" placeholder="Please enter image title" onChange={changetitler3}/></td>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'width':'25%'}}><input type="text" id="img4Titler" size="35" placeholder="Please enter image title" onChange={changetitler4}/></td>
                </tr>
                <tr>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'width':'25%'}}><img  alt='' src={imgData1r} /></td>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'width':'25%'}}><img  alt='' src={imgData2r} /></td>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'width':'25%'}}><img  alt='' src={imgData3r} /></td>
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'width':'25%'}}><img  alt='' src={imgData4r} /></td>
                </tr>
              </tbody>
            </table>  
            <br></br>
            {/* <Button
                style={{ 'background': '#12b886', 'height':'2.7em','color':'#fff', 'border': '1px outset #ccc', 'borderRadius': '.3em'}}
                onClick={() => {
                  //generate();
                  saveresifi();
                }}
            >
                Submit Residence FI Details
            </Button> */}
          </AccordionDetails>
      </Accordion>

    </div>
    <div>

    </div>


{/* <h6>
          <b>Field Investigation</b>
      </h6>   

      <b>Your verification:</b>
      <br></br>
       <form autoComplete="off" >
      <div id="report" style={{'visibility':false}}>
        <table id="fitable" style={{ 'width':'100%', 'border': '1px solid black','borderCollapse': 'collapse'}}>
            <thead>
              <tr>
                <th style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'width':'2%','textAlign': 'right' }}>#</th>
                <th style={{ 'border': '1px solid black','borderCollapse': 'collapse','width':'25%' }}>Title</th>
                <th style={{ 'border': '1px solid black','borderCollapse': 'collapse','width':'58%' }}>Details</th>
                <th style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'width':'15%' }}>Is information correct?</th>
              </tr>
            </thead>
            <tbody>
              <tr>     
                  <td colSpan={4}><b>Organization Details</b></td>
              </tr>
              <tr>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>1</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>Organization Name </td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>'ORG Name'</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'align':'center'}}>
                  <input type="radio" name="orgRad" id='orgRad-1' value="Y" onChange={handleChangeOrgRes} checked={orgRes === 'Y'} />
                      <label htmlFor="orgRad-1">Yes</label>
                  <input type="radio" name="orgRad" id='orgRad-2' value="N" onChange={handleChangeOrgRes} checked={orgRes === 'N'} />
                      <label htmlFor="orgRad-2">No</label>
                </td>
              </tr>

              <tr>
              <td colSpan={4}>Observations:<input type="text" id="orgcomment" size="165"></input></td>
              </tr>
              <tr>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>2</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>Communication Address </td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>'Communication Address'</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'align':'center'}}>
                  <input type="radio" name="commadd" id='commadd-1' value="Y" onChange={handleChangecommadd} checked={commaddRes === 'Y'} />
                      <label htmlFor="commadd-1">Yes</label>
                  <input type="radio" name="commadd" id='commadd-2' value="N" onChange={handleChangecommadd} checked={commaddRes === 'N'} />
                      <label htmlFor="commadd-2">No</label>
                </td>
              </tr>
              <tr>     
                  <td colSpan={4}>Observations:<input type="text" id="comaddcomment" size="165"></input></td>
              </tr>

              <tr>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>4</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>Shipping/Factory address </td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>'Shipping/Factory address'</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'align':'center'}}>
                  <input type="radio" name="Shipadd" id='Shipadd-1' value="Y" onChange={handleChangeShipadd} checked={ShipAddRes === 'Y'} />
                      <label htmlFor="Shipadd-1">Yes</label>
                  <input type="radio" name="Shipadd" id='Shipadd-2' value="N" onChange={handleChangeShipadd} checked={ShipAddRes === 'N'} />
                      <label htmlFor="Shipadd-2">No</label>
                </td>
              </tr>
              <tr>     
                  <td colSpan={4}>Observations:<input type="text" id="shipaddcomment" size="165"></input></td>
              </tr>
              <tr>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right'}}>5</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>Mobile </td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>'Mobile'</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'align':'center'}}>
                  <input type="radio" name="Mobile" id='Mobile-1' value="Y" onChange={handleChangeMobile} checked={Mobile === 'Y'} />
                      <label htmlFor="Mobile-1">Yes</label>
                  <input type="radio" name="Mobile" id='Mobile-2' value="N" onChange={handleChangeMobile} checked={Mobile === 'N'} />
                      <label htmlFor="Mobile-2">No</label>
                </td>
              </tr>
              <tr>     
                  <td colSpan={4}>Observations:<input type="text" id="mobcomment" size="165"></input></td>
              </tr>
              <tr>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>6</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>Email id </td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>'Email id'</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'align':'center'}}>
                  <input type="radio" name="Email" id='Email-1' value="Y" onChange={handleChangeEmail} checked={Email === 'Y'} />
                      <label htmlFor="Email-1">Yes</label>
                  <input type="radio" name="Email" id='Email-2' value="N" onChange={handleChangeEmail} checked={Email === 'N'} />
                      <label htmlFor="Email-2">No</label>
                </td>
              </tr>
              <tr>     
                  <td colSpan={4}>Observations:<input type="text" id="emailcomment" size="165"></input></td>
              </tr>
              <tr></tr> 
              <tr>     
                  <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }} colSpan={4}><b>Director Details</b></td>
              </tr>
              <tr>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>7</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>First Name </td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>'First Name'</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'align':'center'}}>
                  <input type="radio" name="First" id='First-1' value="Y" onChange={handleChangeFirst} checked={First === 'Y'} />
                      <label htmlFor="First-1">Yes</label>
                  <input type="radio" name="First" id='First-2' value="N" onChange={handleChangeFirst} checked={First === 'N'} />
                      <label htmlFor="First-2">No</label>
                </td>
              </tr>
              <tr>     
                  <td colSpan={4}>Observations:<input type="text" id="firstcomment" size="165"></input></td>
              </tr>
              <tr>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>8</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>Last Name </td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>'Last Name'</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'align':'center'}}>
                  <input type="radio" name="Last" id='Last-1' value="Y" onChange={handleChangeLast} checked={Last === 'Y'} />
                      <label htmlFor="Last-1">Yes</label>
                  <input type="radio" name="Last" id='Last-2' value="N" onChange={handleChangeLast} checked={Last === 'N'} />
                      <label htmlFor="Last-2">No</label>
                </td>
              </tr>
              <tr>     
                  <td colSpan={4}>Observations:<input type="text" id="lastcomment" size="165"></input></td>
              </tr>
              <tr>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>9</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>Date of Birth </td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>'Date of Birth'</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'align':'center'}}>
                  <input type="radio" name="dob" id='dob-1' value="Y" onChange={handleChangedob} checked={Dob === 'Y'} />
                      <label htmlFor="dob-1">Yes</label>
                  <input type="radio" name="dob" id='dob-2' value="N" onChange={handleChangedob} checked={Dob === 'N'} />
                      <label htmlFor="dob-2">No</label>
                </td>
              </tr>
              <tr>     
                  <td colSpan={4}>Observations:<input type="text" id="dobcomment" size="165"></input></td>
              </tr>
              <tr>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>10</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>Mobile </td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>'Mobile'</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'align':'center'}}>
                  <input type="radio" name="dMobile" id='dMobile-1' value="Y" onChange={handleChangedMobile} checked={dMobile === 'Y'} />
                      <label htmlFor="dMobile-1">Yes</label>
                  <input type="radio" name="dMobile" id='dMobile-2' value="N" onChange={handleChangedMobile} checked={dMobile === 'N'} />
                      <label htmlFor="dMobile-2">No</label>
                </td>
              </tr>
              <tr>     
                  <td colSpan={4}>Observations:<input type="text" id="dmobilecomment" size="165"></input></td>
              </tr>
              <tr>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>11</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>Email id </td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>'Email id'</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'align':'center'}}>
                  <input type="radio" name="dEmail" id='dEmail-1' value="Y" onChange={handleChangedEmail} checked={dEmail === 'Y'} />
                      <label htmlFor="dEmail-1">Yes</label>
                  <input type="radio" name="dEmail" id='dEmail-2' value="N" onChange={handleChangedEmail} checked={dEmail === 'N'} />
                      <label htmlFor="dEmail-2">No</label>
                </td>
              </tr>
              <tr>     
                  <td colSpan={4}>Observations:<input type="text" id="demailcomment" size="165"></input></td>
              </tr>
              <tr>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>12</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>Residential Address Line 1 </td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>'Residential Address Line 1'</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'align':'center'}}>
                  <input type="radio" name="dAdd1" id='dAdd1-1' value="Y" onChange={handleChangedAdd1} checked={dAdd1 === 'Y'} />
                      <label htmlFor="dAdd1-1">Yes</label>
                  <input type="radio" name="dAdd1" id='dAdd1-2' value="N" onChange={handleChangedAdd1} checked={dAdd1 === 'N'} />
                      <label htmlFor="dAdd1-2">No</label>
                </td>
              </tr>
              <tr>     
                  <td colSpan={4}>Observations:<input type="text" id="dadd1comment" size="165"></input></td>
              </tr>
              <tr>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>13</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>Residential Address </td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>'Residential Address'</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'align':'center'}}>
                  <input type="radio" name="dAdd" id='dAdd-1' value="Y" onChange={handleChangedAdd} checked={dAdd === 'Y'} />
                      <label htmlFor="dAdd-1">Yes</label>
                  <input type="radio" name="dAdd" id='dAdd-2' value="N" onChange={handleChangedAdd} checked={dAdd === 'N'} />
                      <label htmlFor="dAdd-2">No</label>
                </td>
              </tr>
              <tr>     
                  <td colSpan={4}>Observations:<input type="text" id="daddcomment" size="165"></input></td>
              </tr>
              <tr>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>14</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>City </td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>'City'</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'align':'center'}}>
                  <input type="radio" name="dCity" id='dCity-1' value="Y" onChange={handleChangedCity} checked={dCity === 'Y'} />
                      <label htmlFor="dCity-1">Yes</label>
                  <input type="radio" name="dCity" id='dCity-2' value="N" onChange={handleChangedCity} checked={dCity === 'N'} />
                      <label htmlFor="dCity-2">No</label>
                </td>
              </tr>
              <tr>     
                  <td colSpan={4}>Observations:<input type="text" id="dcitycomment" size="165"></input></td>
              </tr>
              <tr>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>15</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>State </td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>'State'</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'align':'center'}}>
                  <input type="radio" name="dState" id='dState-1' value="Y" onChange={handleChangedState} checked={dState === 'Y'} />
                      <label htmlFor="dState-1">Yes</label>
                  <input type="radio" name="dState" id='dState-2' value="N" onChange={handleChangedState} checked={dState === 'N'} />
                      <label htmlFor="dState-2">No</label>
                </td>
              </tr>
              <tr>     
                  <td colSpan={4}>Observations:<input type="text" id="dstatecomment" size="165"></input></td>
              </tr>
              <tr>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse','textAlign': 'right' }}>16</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>Pin code </td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>'Pin code'</td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'align':'center'}}>
                  <input type="radio" name="dPin" id='dPin-1' value="Y" onChange={handleChangedPin} checked={dPin === 'Y'} />
                      <label htmlFor="dPin-1">Yes</label>
                  <input type="radio" name="dPin" id='dPin-2' value="N" onChange={handleChangedPin} checked={dPin === 'N'} />
                      <label htmlFor="dPin-2">No</label>
                </td>
              </tr>
              <tr>     
                  <td colSpan={4}>Observations:<input type="text" id="dpincomment" size="165"></input></td>
              </tr>
              <tr>     
                  <td colSpan={4} style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}><b>Geo Locations</b></td>
              </tr>
              <tr>     
                  <td colSpan={4} style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}><b>Your observations</b></td>
              </tr>
              <tr>     
                  <td colSpan={4} style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>                        <textarea 
                                id="observations"
                                cols="100"
                                rows="5"
                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                placeholder="Enter your comments"
                                
                            /></td></tr>

              <tr>     
                <td colSpan={3} style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}><b>Are you recommending credit to this organization?</b></td>
                <td style={{ 'border': '1px solid black','borderCollapse': 'collapse', 'align':'center'}}>
                  <input type="radio" name="Recom" id='Recom-1' value="Y" onChange={handleChangeRecom} checked={Recom === 'Y'} />
                      <label htmlFor="Recom-1">Yes</label>
                  <input type="radio" name="Recom" id='Recom-2' value="N" onChange={handleChangeRecom} checked={Recom === 'N'} />
                      <label htmlFor="Recom-2">No</label>
                </td>
              </tr>
              <tr>     
                  <td colSpan={4} style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>                        <textarea 
                                id="reccomments"
                                cols="100"
                                rows="5"
                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                placeholder="Enter your comments for recommendations"
                                
                            /></td></tr>
                                          <tr>     
                  <td colSpan={4} style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}><b>Question 1</b></td>
              </tr>
              <tr>     
                <td colSpan={4} style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>
                  <textarea 
                  id="answer1"
                  cols="100"
                  rows="2"
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Enter your answer here"
                  />
                </td>
              </tr>
              <tr>     
                  <td colSpan={4} style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}><b>Question 2</b></td>
              </tr>
              <tr>     
                <td colSpan={4} style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>
                  <textarea 
                  id="answer2"
                  cols="100"
                  rows="2"
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Enter your answer here"
                  />
                </td>
              </tr>

              <tr>     
                  <td colSpan={4} style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}><b>Question 3</b></td>
              </tr>
              <tr>     
                <td colSpan={4} style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>
                  <textarea 
                  id="answer3"
                  cols="100"
                  rows="2"
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Enter your answer here"
                  />
                </td>
              </tr>
              <tr>     
                  <td colSpan={4} style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}><b>Question 4</b></td>
              </tr>
              <tr>     
                <td colSpan={4} style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>
                  <textarea 
                  id="answer4"
                  cols="100"
                  rows="2"
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Enter your answer here"
                  />
                </td>
              </tr>
              <tr>     
                  <td colSpan={4} style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}><b>Question 5</b></td>
              </tr>
              <tr>     
                <td colSpan={4} style={{ 'border': '1px solid black','borderCollapse': 'collapse' }}>
                  <textarea 
                  id="answer5"
                  cols="100"
                  rows="2"
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Enter your answer here"
                  />
                </td>
              </tr>
              <tr></tr>
              <tr></tr>
              <tr>     
                  <td colSpan={3}>
                    <Button
                      style={{ height: "fit-content" }}
                      color = "primary"
                      //type="submit"
                      variant="contained"
                      //disabled={!savdetails}
                       onClick={() => {
                      handleSubmit();
                      }}
                      >
                      Save Details
                    </Button>
                  </td>
                </tr>
            </tbody>
        </table>
        </div>               
      </form>
    */}

    <div id="bypassreport" style={{'display':'none'}}>
      <table id='byreport' style={{'visibility':false}}>

        <thead>
            <tr>
              <td colSpan={3}> Bypass FI Report</td>
            </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{'width':'2%'}}><b>#</b></td>
            <td style={{'width':'25%'}}><b>Title</b></td>
            <td style={{'width':'65%'}}><b>Details</b></td>
          </tr>
            <tr>
              <td>1</td>
              <td>Organisation Name</td>
              <td>{orgName}</td>
            </tr>
            <tr>
              <td>2</td>
              <td>Referred By</td>
              <td>{referredBy}</td>
            </tr>
            <tr>
              <td>3</td>
              <td>Equifax Score</td>
              <td>{equifaxScore}</td>
            </tr>
            <tr>
              <td>4</td>
              <td>CRIF Score </td>
              <td>{crifScore}</td>
            </tr>
            <tr>
              <td>5</td>
              <td>Relationship with Ancher</td>
              <td>{relationship}</td>
            </tr>
            <tr>
              <td>6</td>
              <td>FI done by</td>
              <td>{fidoneby}</td>
            </tr>
            <tr>
              <td>7</td>
              <td>Date and Time of FI Report</td>
              <td>{todaydate} &nbsp;&nbsp; {todaytime}</td>
             </tr>
        </tbody>
      </table>
    </div>

       <div id="report" style={{'display':'none'}}>

        <table id="reportOffice" style={{'visibility':false}}>
          <thead>
            <tr>
              <td style={{'width':'2%'}}>#</td>
              <td style={{'width':'15%'}}>Title</td>
              <td style={{'width':'29%'}}>Details</td>
              <td style={{'width':'8%'}}>Is this Information Correct?</td>
              <td style={{'width':'46%'}}>Comments</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Organisation Name</td>
              <td>{orgName}</td>
              <td>{orName}</td>
              <td>{orNamComment}</td>
            </tr>
            <tr>
              <td>2</td>
              <td>Office address</td>
              <td><div style={{'whiteSpace': 'pre-line'}}>{offAddress}</div></td>
              <td>{offAdrres}</td>
              <td>{offAddressComment}</td>
             </tr>
             <tr>
              <td>3</td>
              <td>Is this Owned Premises?</td>
              <td>{offlocation}</td>
              <td>{orName}</td>
              <td>{offlocationComent}</td>
             </tr>
             <tr>
              <td>4</td>
              <td>GEO Location</td>
              <td>{geoLocation}</td>
              <td>{}</td>
              <td>{}</td>
             </tr>
             <tr>
              <td>5</td>
              <td>Name and designation of person met</td>
              <td>{personmet}, {designationmet}</td>
              <td></td>
              <td></td>
             </tr>
             <tr>
              <td>6</td>
              <td>Number of employees working</td>
              <td>{noofemployee}</td>
              <td>{}</td>
              <td></td>
             </tr>
             
             <tr>
              <td>7</td>
              <td>Landmark of Office</td>
              <td>{locationofoffice}</td>
              <td>{}</td>
              <td></td>
             </tr>
             <tr>
              <td>8</td>
              <td>General Appearance</td>
              <td>{generalappearnce}</td>
              <td></td>
              <td></td>
             </tr>
             <tr>
              <td>9</td>
              <td>Reference No.</td>
              <td>{iLanNo}</td>
              <td></td>
              <td></td>
             </tr>
             <tr>
              <td>10</td>
              <td>Verifiers Remarks</td>
              <td>{verifiercomment}</td>
              <td></td>
              <td></td>
             </tr>
             <tr>
              <td>11</td>
              <td>Credit recommendation</td>
              <td></td>
              <td>{recoff}</td>
              <td>{recoffcomment}</td>
             </tr>
            
             <tr>
              <td>12</td>
              <td>FI Done by</td>
              <td>{rmName}</td>
              <td>{}</td>
              <td></td>
             </tr>
             <tr>
              <td>13</td>
              <td>Date of Visit</td>
              <td>{todaydate}</td>
              <td>{}</td>
              <td></td>
             </tr>
             <tr>
              <td>14</td>
              <td>Time of Visit</td>
              <td>{todaytime}</td>
              <td>{}</td>
              <td></td>

              </tr>
            </tbody>
        </table>  

        <table id="reportfactory" style={{'visibility':false}}>
          <thead>
            <tr>
              <td style={{'width':'2%'}}>#</td>
              <td style={{'width':'15%'}}>Title</td>
              <td style={{'width':'29%'}}>Details</td>
              <td style={{'width':'8%'}}>Is this Information Correct?</td>
              <td style={{'width':'46%'}}>Comments</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Organisation Name</td>
              <td>{orgName}</td>
              <td>{orNameF}</td>
              <td>{orNamFComment}</td>
            </tr>
            <tr>
              <td>2</td>
              <td>Factory address</td>
              <td><div style={{'whiteSpace': 'pre-line'}}>{facAddresss}</div></td>
              <td>{facAddress}</td>
              <td>{facAddressComment}</td>
             </tr>
             <tr>
              <td>3</td>
              <td>Is this Owned Premises?</td>
              <td>{factownlocation}</td>
              <td>{orNameF}</td>
              <td>{factlocationComent}</td>
             </tr>
             <tr>
              <td>4</td>
              <td>GEO Location</td>
              <td>{geoLocation}</td>
              <td>{}</td>
              <td>{}</td>
             </tr>
             <tr>
              <td>5</td>
              <td>Name and designation of person met</td>
              <td>{personmetf}, {designationmetf}</td>
              <td></td>
              <td></td>
             </tr>
             <tr>
              <td>6</td>
              <td>Number of employees working</td>
              <td>{noofemployeef}</td>
              <td>{}</td>
              <td></td>
             </tr>
             
             <tr>
              <td>7</td>
              <td>Landmark of Office</td>
              <td>{locationofofficef}</td>
              <td>{}</td>
              <td></td>
             </tr>
             <tr>
              <td>8</td>
              <td>General Appearance</td>
              <td>{generalappearncef}</td>
              <td></td>
              <td></td>
             </tr>
             <tr>
              <td>9</td>
              <td>Reference No.</td>
              <td>{iLanNo}</td>
              <td></td>
              <td></td>
             </tr>
             <tr>
              <td>10</td>
              <td>Verifiers Remarks</td>
              <td>{verifiercommentf}</td>
              <td></td>
              <td></td>
             </tr>
             <tr>
              <td>11</td>
              <td>Credit recommendation</td>
              <td></td>
              <td>{recoffF}</td>
              <td>{recoffcommentf}</td>
             </tr>
            
             <tr>
              <td>12</td>
              <td>FI Done by</td>
              <td>{rmName}</td>
              <td>{}</td>
              <td></td>
             </tr>
             <tr>
              <td>13</td>
              <td>Date of Visit</td>
              <td>{todaydate}</td>
              <td>{}</td>
              <td></td>
             </tr>
             <tr>
              <td>14</td>
              <td>Time of Visit</td>
              <td>{todaytime}</td>
              <td>{}</td>
              <td></td>

              </tr>
            </tbody>
        </table>  

        <table id="reportresi" style={{'visibility':false}}>
          <thead>
            <tr>
              <td style={{'width':'2%'}}>#</td>
              <td style={{'width':'15%'}}>Title</td>
              <td style={{'width':'29%'}}>Details</td>
              <td style={{'width':'8%'}}>Is this Information Correct?</td>
              <td style={{'width':'46%'}}>Comments</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Organisation Name</td>
              <td>{orgName}</td>
              <td>{orNameR}</td>
              <td>{orNamRComment}</td>
            </tr>
            <tr>
              <td>2</td>
              <td>Director Name</td>
              <td>{drName}</td>
              <td>{dirName}</td>
              <td>{drNameComment}</td>
            </tr>
            <tr>
              <td>3</td>
              <td>Residence address</td>
              <td><div style={{'whiteSpace': 'pre-line'}}>{resiAddress}</div></td>
              <td>{resAddress}</td>
              <td>{resiAddressComment}</td>
             </tr>
             <tr>
              <td>4</td>
              <td>Is this Owned Premises?</td>
              <td>{resiownlocation}</td>
              <td>{orNameF}</td>
              <td>{resilocationComent}</td>
             </tr>
             <tr>
              <td>5</td>
              <td>GEO Location</td>
              <td>{geoLocation}</td>
              <td>{}</td>
              <td>{}</td>
             </tr>
             <tr>
              <td>6</td>
              <td>Name person met</td>
              <td>{personmetr}</td>
              <td></td>
              <td></td>
             </tr>
             <tr>
              <td>7</td>
              <td>Landmark of Office</td>
              <td>{locationofofficer}</td>
              <td>{}</td>
              <td></td>
             </tr>
             <tr>
              <td>8</td>
              <td>General Appearance</td>
              <td>{generalappearncer}</td>
              <td></td>
              <td></td>
             </tr>
             <tr>
              <td>9</td>
              <td>Reference No.</td>
              <td>{iLanNo}</td>
              <td></td>
              <td></td>
             </tr>
             <tr>
              <td>10</td>
              <td>Verifiers Remarks</td>
              <td>{verifiercommentr}</td>
              <td></td>
              <td></td>
             </tr>
             <tr>
              <td>11</td>
              <td>Credit recommendation</td>
              <td></td>
              <td>{recoffr}</td>
              <td>{recoffcommentr}</td>
             </tr>
            
             <tr>
              <td>12</td>
              <td>FI Done by</td>
              <td>{rmName}</td>
              <td>{}</td>
              <td></td>
             </tr>
             <tr>
              <td>13</td>
              <td>Date of Visit</td>
              <td>{todaydate}</td>
              <td>{}</td>
              <td></td>
             </tr>
             <tr>
              <td>14</td>
              <td>Time of Visit</td>
              <td>{todaytime}</td>
              <td>{}</td>
              <td></td>

              </tr>
            </tbody>
        </table>  
      </div>
      </>




          


  );
} 
export default ByPassFi;