
import React, {useState} from "react";
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';


const PfPayment = () => {
    const [isLoad, setisLoad] = useState(true);
    const [apiactivity, setapiactivity]=useState('https://los.statuscapitals.com/apigateway/statusapi/activitydashboard/activitydetails?buyerId=');
    const [errorMessage, seterrorMessage] = useState('');
    const [errortype, seterrortype] = useState('success');
    const [erroropen, seterroropen] = useState(false);
    const [errorTimer, seterrorTimer] = useState(20000);

    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }    
      seterroropen(false);
      seterrorMessage('');
      seterrortype('success');
    };

    let encoded = (window.location.href.substring(window.location.href.lastIndexOf('/') + 1))

    let mylan = Buffer.from(encoded,'base64').toString();
    if (!Number.isInteger(parseInt(mylan, 10))) {
       if (isLoad) {
            //alert('Not able to get the Processing Fee URL, please check the url and retry!!!');
          seterrorMessage('Not able to get the Processing Fee URL, please check the url and retry!!!');     
          seterroropen(true);
          seterrorTimer(8000);
          seterrortype('error');        
          setisLoad(false);
          return false;
        }
    } 
    if (isLoad) {
        setisLoad(false);
        let url = apiactivity+mylan;
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: url,
      
            headers: { 
              'client-id': 'STATU0004200016', 
              'client-secret': 'QOetBBBHrU5jLGvSBE1QiuhVk65NhOEgt3a4qGcy32A=', 
              'Accept': 'application/json', 
              'Content-Type': 'application/json'
            }
          };
          axios.request(config)
          .then((response) => {
            if (JSON.stringify(response.data.responseCode)==200){
              
                let pfurl ='';
                if (response.data.data[8].links!=null){
                  pfurl = (response.data.data[8].links[0].url);
                }
                //pfurl = (response.data.data[2].links[0].url);
                //console.log(pfurl);
                if (pfurl != '') {
                   window.open(pfurl, '_self', 'noopener,noreferrer');
                } else{
                  seterrorMessage('Not able to get the Processing Fee URL, please wait for some time and retry!!!');     
                  seterroropen(true);
                  seterrorTimer(8000);
                  seterrortype('error');        
                }
            }else
            {
              seterrorMessage('Not able to get the Processing Fee URL, please wait for some time and retry!!!');     
              seterroropen(true);
              seterrorTimer(8000);
              seterrortype('error');                
            }    
          })
          .catch((error) => {
            
            seterrorMessage('Not able to get the Processing Fee URL, please wait for some time and retry!!!');     
            seterroropen(true);
            seterrorTimer(8000);
            seterrortype('error');
          });
    

    }  

return (
<>
<Snackbar autoHideDuration={errorTimer} anchorOrigin={{ vertical: "top", horizontal: "right" }} open={erroropen}  onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={errortype}
          variant="filled"
          sx={{ width: '100%' }}
          >
          {errorMessage}  
        </Alert>
      </Snackbar>
</>
);



}
export default PfPayment;